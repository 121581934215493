/* eslint-disable no-debugger */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { Comparator } from "react-bootstrap-table2-filter";
import { PropTypes } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable, toJS } from "mobx";
import style from "./DataTable.module.scss";
import DataTablePagination from "./DataTablePagination";
import DataTableColumnToggle from "./DataTableColumnToggle";
import planStructure from "../../assets/planstructure.json";

@inject("dataStore")
@observer
export class CustomDataTable extends React.Component {
  @observable filteredData = [];

  @observable page = 1;

  @observable sizePerPage = 10;

  getRevision = (data) => {
    const elemArray = data.map((entry) => entry.name.split("_").splice(0, 5));
    elemArray.map((elem) => elem.splice(2, 2));

    const tempArr = {};
    elemArray.forEach((entry, index) => {
      if (!tempArr[entry]) {
        tempArr[entry] = [];
      }
      tempArr[entry].push(data[index]);
      return tempArr;
    });

    let latestElement = null;
    let highestOrder = null;

    let finalArray = [];

    const phase = Object.keys(planStructure.phase);
    const indexes = Object.keys(planStructure.index2);
    const vorabzug = Object.keys(planStructure.vorabzug);
    const copyTempArr = {};

    Object.values(tempArr).forEach((element, index) => {
      copyTempArr[Object.keys(tempArr)[index]] = [];
      element.map((entry) => {
        if (
          !latestElement ||
          phase.indexOf(Object.values(entry.name.split("_").splice(2, 1)).join("")) >
            phase.indexOf(Object.values(latestElement.name.split("_").splice(2, 1)).join(""))
        ) {
          latestElement = entry;
          highestOrder = phase.indexOf(entry.phase);
        }
      });
      const arrayValues = element.filter((entry) => entry.phase === phase[highestOrder]).map((entry) => entry);
      copyTempArr[Object.keys(tempArr)[index]] = arrayValues;
      latestElement = null;
    });

    const copyTempArr1 = copyTempArr;

    Object.values(copyTempArr).forEach((element, index) => {
      copyTempArr1[Object.keys(copyTempArr)[index]] = [];
      element.map((entry) => {
        if (
          !latestElement ||
          indexes.indexOf(Object.values(entry.name.split("_").splice(6, 1)).join("")) >
            indexes.indexOf(Object.values(latestElement.name.split("_").splice(6, 1)).join(""))
        ) {
          latestElement = entry;
          highestOrder = indexes.indexOf(entry.index);
        }
      });
      const arrayValues = element.filter((entry) => entry.index === indexes[highestOrder]).map((entry) => entry);
      copyTempArr1[Object.keys(copyTempArr)[index]] = arrayValues;
      latestElement = null;
    });

    Object.values(copyTempArr1).forEach((element) => {
      element.map((entry) => {
        if (
          !latestElement ||
          vorabzug.indexOf(Object.values(entry.name.split("_").splice(7, 1)).join("")) >
            vorabzug.indexOf(Object.values(latestElement.name.split("_").splice(7, 1)).join(""))
        ) {
          latestElement = entry;
          highestOrder = vorabzug.indexOf(entry.vorabzug);
        }
      });
      const arrayValues = element.filter((entry) => entry.vorabzug === vorabzug[highestOrder]).map((entry) => entry);
      finalArray = [...finalArray, ...arrayValues];
      latestElement = null;
    });

    return finalArray;
  };

  handleTableChange = (type, { searchText, filters, special }) => {
    const { data, columns } = this.props;
    if (type === "search" || type === "filter") {
      if (
        type === "filter" &&
        searchText === "" &&
        type === "filter" &&
        filters.name === undefined &&
        !filters.filterVal
      ) {
        return (this.filteredData = observable.array(data));
      }

      if (type === "filter" && Object.keys(filters).length === 0) return (this.filteredData = observable.array(data));

      this.filteredData = observable.array(
        data
          .filter((row) => {
            const dataFields = columns.map((e) => e.dataField);
            for (let i = 0; i < dataFields.length; i += 1) {
              const cell = dataFields[i].split(".").reduce((acc, part) => acc && acc[part], row);
              const searchTextModified = searchText || "";
              if (cell && cell.toString().toLowerCase().indexOf(searchTextModified.toLowerCase()) > -1) {
                return true;
              }
            }
            return false;
          })
          .filter((row) => {
            let valid = true;
            if (type === "filter") {
              valid = false;
            }

            const filterKeys = Object.keys(filters);

            for (let i = 0; i < filterKeys.length; i += 1) {
              const filterKey = filterKeys[i];
              const { filterVal, filterType, comparator } = filters[filterKey];
              if (filterType === "TEXT") {
                if (comparator === Comparator.LIKE) {
                  valid = row[filterKey].toString().indexOf(filterVal) > -1;
                } else if (comparator === Comparator.EQ) {
                  valid = row[filterKey].toString() === filterVal;
                } else {
                  console.error(`Unhandled filter comparator: ${comparator}`);
                }
              }
              if (!valid) break;
            }
            if (filters.filterType === "MULTISELECT") {
              valid = true;
              const filterValues = filters.filterVal;

              if (!Object.values(filterValues).some((x) => x !== null && x !== "")) {
                valid = true;
              } else {
                Object.keys(filterValues).map((key) => {
                  const tempArray = filterValues[key];

                  if (tempArray.toJS().length > 0 && !tempArray.toJS().includes(row[key])) {
                    valid = false;
                  }
                });
              }
            }
            return valid;
          })
      );
      this.page = 1;
    }
    if (special) {
      const finalArray = this.getRevision(this.filteredData);

      this.filteredData = observable.array(finalArray);
    }
  };

  constructor(props) {
    super(props);
    const { data } = props;
    this.filteredData = observable.array(data);
  }

  componentDidUpdate(prevProps) {
    const { data, filter, specialFilter, searchText } = this.props;
    if (data !== prevProps.data && data) {
      // this.filteredData = observable.array(data);
    }

    if (filter !== prevProps.filter || searchText !== prevProps.searchText) {
      const filterObject = {};

      const filterArray = {
        comparator: "LIKE",
        filterType: "MULTISELECT",
        filterVal: filter,
      };
      filterObject.searchText = searchText;

      filterObject.filters = filterArray;
      filterObject.special = specialFilter;

      this.handleTableChange("filter", filterObject);
    }
  }

  render() {
    const {
      columns,
      keyField,
      defaultSorted,
      extraFilters,
      onRowClick,
      onRowSelected,
      onSelectAllRows,
      search,
      pagination,
      pageSize,
      bordered,
      headerClasses,
      columnToggle,
      selectedFiles,
      filterWrapper: FilterWrapper,
      filterWrapperProps,
      obj_id,
      companies,
    } = this.props;

    const options = {
      custom: true,
      page: this.page,
      sizePerPage: pagination ? pageSize : this.filteredData.length,
      totalSize: this.filteredData.length,
    };

    if (this.sizePerPage) {
      options.sizePerPage = this.sizePerPage;
    }

    const rowEvents = onRowClick
      ? {
          onClick: onRowClick,
        }
      : null;

    const rowStyle = rowEvents ? { cursor: "pointer" } : null;

    const selectRow = {
      mode: "checkbox",
      onSelect: onRowSelected,
      onSelectAll: onSelectAllRows,
    };

    for (let i = 0; i < columns.length; i += 1) {
      const col = columns[i];
      if (col.attrs) {
        col.attrs["data-header"] = col.text;
      } else {
        col.attrs = { "data-header": col.text };
      }
    }

    return (
      <div>
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField={keyField}
              columns={columns}
              data={toJS(this.filteredData)}
              isKey
              columnToggle
              defaultSorted={defaultSorted}
            >
              {(toolkitprops) => (
                <div>
                  {(search || columnToggle || extraFilters) && (
                    <FilterWrapper {...filterWrapperProps}>
                      <Row className='justify-content-end pb-2'>
                        {extraFilters &&
                          extraFilters.map((v, i) => (
                            <Col xs='6' lg='3' key={i}>
                              {v}
                            </Col>
                          ))}
                        {search && <Col xs='6' lg='3' />}
                        {columnToggle && (
                          <Col lg='auto' className='d-none d-lg-block'>
                            <DataTableColumnToggle {...toolkitprops.columnToggleProps} />
                          </Col>
                        )}
                      </Row>
                    </FilterWrapper>
                  )}
                  <BootstrapTable
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                    hover
                    filter={filterFactory()}
                    rowEvents={rowEvents}
                    rowStyle={rowStyle}
                    {...(onRowSelected ? { selectRow } : null)}
                    bordered={bordered}
                    headerClasses={headerClasses}
                    bootstrap4
                    remote={{ search: true, filter: true }}
                    onTableChange={this.handleTableChange}
                    wrapperClasses={style.dataTable}
                  />
                  {pagination && (
                    <DataTablePagination
                      paginationProps={paginationProps}
                      obj_id={obj_id}
                      companies={companies}
                      selectedFiles={selectedFiles}
                      onPageChange={(page) => (this.page = page)}
                      onSizePerPageChange={(pageSizes) => (this.sizePerPage = pageSizes)}
                    />
                  )}
                </div>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </div>
    );
  }
}

CustomDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  filter: PropTypes.any,
  columns: PropTypes.array.isRequired,
  keyField: PropTypes.string.isRequired,
  defaultSorted: PropTypes.array.isRequired,
  extraFilters: PropTypes.node,
  onRowClick: PropTypes.func,
  onRowSelected: PropTypes.func,
  onSelectAllRows: PropTypes.func,
  search: PropTypes.bool,
  searchText: PropTypes.string,
  pagination: PropTypes.bool,
  bordered: PropTypes.bool,
  headerClasses: PropTypes.string,
  columnToggle: PropTypes.bool,
  filterWrapper: PropTypes.any,
  filterWrapperProps: PropTypes.any,
  pageSize: PropTypes.number,
  selectedFiles: PropTypes.any,
  obj_id: PropTypes.string,
  companies: PropTypes.any,
  specialFilter: PropTypes.any,
  dataStore: PropTypes.any.isRequired,
};

CustomDataTable.defaultProps = {
  extraFilters: null,
  filter: "",
  onRowClick: null,
  onRowSelected: null,
  onSelectAllRows: null,
  search: true,
  searchText: "",
  pagination: true,
  pageSize: 10,
  bordered: true,
  headerClasses: style.header,
  columnToggle: false,
  filterWrapper: "div",
  filterWrapperProps: null,
  selectedFiles: [],
  obj_id: "0",
  companies: [],
  specialFilter: false,
};

export const dateFormatter = (cell) => <span>{new Date(cell).toLocaleDateString("de-DE")}</span>;
