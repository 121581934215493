/* eslint-disable array-callback-return */
/* eslint-disable no-debugger */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { textFilter, Comparator } from "react-bootstrap-table2-filter";
import { Multiselect } from "multiselect-react-dropdown";
import { CSVLink } from "react-csv";
import moment from "moment";
import { InputGroup } from "react-bootstrap";
import Accordion from "./Accordion/Accordion";
import { CustomDataTable } from "./DataTable/CustomDataTable";
import SelectFirmModal from "./Modal/SelectFirmModal";
import ShareLogModal from "./Modal/ShareLogModal";
import { truncateFileName, getFontAwesomeIconFromMIME } from "../helpers/StringHelpers";
import styles from "../app.module.css";
import LoadingModal from "./Modal/LoadingModal";
import Toast from "./Toast/Toast";
import ConfirmationModal from "./Modal/ConfirmationModal";
import planStructure from "../assets/planstructure";

@inject("dataStore")
@inject("authStore")
@observer
class ObjectFilesTable extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    obj_nr: PropTypes.string.isRequired,
    dataStore: PropTypes.any.isRequired,
    authStore: PropTypes.any.isRequired,
    openFilesDialog: PropTypes.func.isRequired,
    openFirmsDialog: PropTypes.func.isRequired,
    openRightsDialog: PropTypes.func.isRequired,
    companies: PropTypes.any,
  };

  static defaultProps = {
    companies: [],
  };

  @observable action = null;

  @observable actionRow = null;

  @observable actionProgress = 0;

  @observable additionalInfoValues = [];

  @observable toastShown = false;

  @observable toastStatus = {};

  @observable generatedFileName = "";

  @observable readUsers = "";

  @observable searchText = "";

  @observable specialFilter = false;

  @observable selectedFiles = [];

  @observable showFirmListModal = false;

  @observable showShareDialog = false;

  @observable selectedRowId = 0;

  @observable csvData = [];

  @observable filteredItem = {
    part: "",
    gewerk: "",
    phase: "",
    type: "",
    geschoss: "",
    info: "",
    index: "",
    vorabzug: "",
    text: "",
    note: "",
  };

  @observable filterByObjectID;

  actionsFormatter = (cell, row, index, branchen) => {
    const isValid = row.deletable || branchen.branchen.includes("Bauherr");
    return (
      <div>
        <FontAwesomeIcon
          className={styles.icon}
          onClick={() => this.sendNotifications(row)}
          style={{ marginRight: "10px" }}
          color='#1f2427'
          icon='envelope'
          title='Senden'
        />
        <FontAwesomeIcon
          className={styles.icon}
          onClick={() => this.downloadFile(row)}
          style={{ marginRight: "10px" }}
          color='#1f2427'
          icon='file-download'
          title='Downloaden'
        />
        {isValid && (
          <FontAwesomeIcon
            className={styles.icon}
            onClick={() => this.openDownloadsDialog(row)}
            style={{ marginRight: "10px" }}
            color='#1f2427'
            icon='eye'
            title='Heruntergeladen von'
          />
        )}
        {isValid && (
          <FontAwesomeIcon
            className={styles.icon}
            onClick={() => this.manageAccess(row)}
            style={{ marginRight: "10px" }}
            color='#1f2427'
            icon='user-slash'
            title='Firmen Verwalten'
          />
        )}
        {/* row.deletable && (
          <FontAwesomeIcon
            className={styles.icon}
            onClick={() => this.deleteFile(row)}
            color="#1f2427"
            icon="trash-alt"
            title="Löschen"
          />
        ) */}
        {row.deletable && (
          <FontAwesomeIcon
            className={styles.icon}
            onClick={() => this.showFileLog(row)}
            color='#1f2427'
            icon='clock'
            title='Berechtigungslog'
          />
        )}
      </div>
    );
  };

  checkSelectedFiles = () => {
    if (this.selectedFiles.length > 0) {
      this.toastShown = true;
      this.toastStatus = {
        type: "error",
        title: "Mehrere Dateien sind selektiert",
        body: "Bitte deselektieren oder die Elemente über der Tabelle nutzen!",
      };
      return false;
    }
    return true;
  };

  sendNotifications = (row) => {
    if (!this.checkSelectedFiles()) return;

    const { openFilesDialog, dataStore, obj_nr } = this.props;

    if (!row.deletable) {
      dataStore.getReadUsers(obj_nr, [row.id]).then((res) => {
        openFilesDialog(row, res);
      });
    } else {
      openFilesDialog(row);
    }
  };

  manageAccess = (row) => {
    if (!this.checkSelectedFiles()) return;

    const { openRightsDialog } = this.props;
    openRightsDialog(row);
  };

  showFileLog = async (row) => {
    this.selectedRowId = row.id;

    this.showShareDialog = true;
  };

  openDownloadsDialog = (row) => {
    if (!this.checkSelectedFiles()) return;

    const { obj_nr, dataStore, openFirmsDialog } = this.props;
    dataStore.getDownloadedFirms(obj_nr, row.id).then((res) => {
      dataStore.getAcknowledgedFirms(obj_nr, row.id).then((res1) => {
        const readData = res1;
        console.log(readData);
        openFirmsDialog(res, res1);
      });
    });
  };

  downloadFile = (row) => {
    if (!this.checkSelectedFiles()) return;

    const { obj_nr, dataStore } = this.props;
    this.action = "download";
    this.actionRow = row;
    this.actionProgress = 0;
    dataStore
      .downloadObjectFile(obj_nr, row.id, row.name, (progress) => (this.actionProgress = progress))
      .then(() => (this.action = null))
      .catch((error) => {
        dataStore.loadObjectFiles(obj_nr).then(() => {
          this.action = null;
          this.toastShown = true;
          this.toastStatus = {
            type: "error",
            title: "Download Fehlgeschlagen!",
            body: error.message,
          };
        });
      });
  };

  downloadSelected = () => {
    const { dataStore, obj_nr } = this.props;

    dataStore.downloadAllFiles(this.selectedFiles, obj_nr);
  };

  exportToCSV = () => {
    const { dataStore, obj_nr, companies } = this.props;

    Promise.all(this.selectedFiles.map((file) => dataStore.getCompaniesWithAccess(obj_nr, file.id)))
      .then((res) => {
        console.log(res);
        const result = res.map((entry) => {
          const arr = [];
          entry.map((id) =>
            companies.map((company) => {
              if (company.firmen_id === id) {
                arr.push(company.firmen_bzg1);
              }
            })
          );
          return arr;
        });

        return result;
      })
      .then((res) => {
        this.csvData = res.map((entry, index) => [
          this.selectedFiles[index].name,
          this.selectedFiles[index].firmName,
          moment(this.selectedFiles[index].downloupdatedAtadTime).format("DD.MM.YYYY, H:mm"),
          entry,
        ]);
        this.csvData.unshift(["Filename", "Uploaddatum", "Firma", "File Berechtigungen"]);
        const t = this.csvExportRef;
        this.csvExportRef.current.handleClick();
        this.csvExportRef.current.handleSyncClick();
      });
  };

  showFileModal = () => {
    const { dataStore, obj_nr } = this.props;
    const fileIds = [];

    const isOwner = this.selectedFiles.map((file) => file.deletable);

    if (isOwner.includes(false)) {
      if (this.selectedFiles) this.selectedFiles.map((file) => fileIds.push(file.id));
      dataStore.getReadUsers(obj_nr, fileIds).then((res) => {
        console.log(res);
        console.log(isOwner);
        this.readUsers = res;
        this.showFirmListModal = true;
      });
    } else {
      this.readUsers = [];
      this.showFirmListModal = true;
    }
  };

  dateFormatter = (row) => moment(row).format("DD.MM.YYYY, H:mm");

  /* deleteFile = (row) => {
    this.action = "delete";
    this.actionRow = row;
  }; */

  confirmDeleteFile = () => {
    const { obj_nr, dataStore } = this.props;
    dataStore
      .deleteObjectFile(obj_nr, this.actionRow.id)
      .then(() => {
        this.action = null;
      })
      .catch((error) => {
        dataStore.loadObjectFiles(obj_nr).then(() => {
          this.action = null;
          this.toastShown = true;
          this.toastStatus = {
            type: "error",
            title: "Löschen Fehlgeschlagen!",
            body: error.message,
          };
        });
      });
  };

  cancelDeleteFile = () => {
    this.action = null;
  };

  fileNameFormatter = (cell) => truncateFileName(cell, 40);

  convertMimeType = (cell) => {
    let mime = getFontAwesomeIconFromMIME(cell);
    if (!mime) {
      mime = "file-alt";
    }
    return <FontAwesomeIcon icon={mime} size='2x' className={mime} title={cell} />;
  };

  componentDidMount() {
    this.generatedFileName = Object.values(this.filteredItem).map((item) => `${item}_`);
  }

  applyFilter = (e, reset) => {
    const form = e.currentTarget;
    this.specialFilter = false;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    e.preventDefault();
    if (reset) {
      this.generatedFileName = "";
      this.partRef.current.resetSelectedValues();
      this.gewerkRef.current.resetSelectedValues();
      this.phaseRef.current.resetSelectedValues();
      this.typeRef.current.resetSelectedValues();
      this.geschossRef.current.resetSelectedValues();
      this.infoRef.current.resetSelectedValues();
      this.indexRef.current.resetSelectedValues();
      this.freigabeRef.current.resetSelectedValues();
      this.searchText = "";

      return this.generatedFileName;
    }

    const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

    const part = this.partRef.current.getSelectedItems().map((elem) => getKeyByValue(planStructure.bauteil, elem.key));
    const gewerk = this.gewerkRef.current
      .getSelectedItems()
      .map((elem) => getKeyByValue(planStructure.planungsgewerk, elem.key));
    const phase = this.phaseRef.current.getSelectedItems().map((elem) => getKeyByValue(planStructure.phase, elem.key));
    const type = this.typeRef.current.getSelectedItems().map((elem) => getKeyByValue(planStructure.type, elem.key));
    const geschoss = this.geschossRef.current
      .getSelectedItems()
      .map((elem) => getKeyByValue(planStructure.geschoss, elem.key));

    const extendedInfoData = planStructure.info;
    const tempObj = {};
    this.additionalInfoValues.map((entry) => {
      tempObj[entry.key] = entry.key;
      return { myObj: tempObj };
    });
    const temp = { ...extendedInfoData, ...tempObj };

    const info = this.infoRef.current.getSelectedItems().map((elem) => getKeyByValue(temp, elem.key));

    const index = this.indexRef.current.getSelectedItems().map((elem) => getKeyByValue(planStructure.index2, elem.key));
    const freigabe = this.freigabeRef.current
      .getSelectedItems()
      .map((elem) => getKeyByValue(planStructure.vorabzug, elem.key));

    const filterArray = {
      part,
      gewerk,
      phase,
      type,
      geschoss,
      info,
      index,
      freigabe,
    };

    this.generatedFileName = Object.values(this.filteredItem).map((item) => {
      if (item !== "") {
        return `${item}_`;
      }
      return item;
    });
    // this.generatedFileName = this.generatedFileName.slice(0, -1);
    this.generatedFileName = filterArray;
  };

  filterLatest = () => {
    const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

    const part = this.partRef.current.getSelectedItems().map((elem) => getKeyByValue(planStructure.bauteil, elem.key));
    const gewerk = this.gewerkRef.current
      .getSelectedItems()
      .map((elem) => getKeyByValue(planStructure.planungsgewerk, elem.key));
    const phase = this.phaseRef.current.getSelectedItems().map((elem) => getKeyByValue(planStructure.phase, elem.key));
    const type = this.typeRef.current.getSelectedItems().map((elem) => getKeyByValue(planStructure.type, elem.key));
    const geschoss = this.geschossRef.current
      .getSelectedItems()
      .map((elem) => getKeyByValue(planStructure.geschoss, elem.key));

    const extendedInfoData = planStructure.info;
    const tempObj = {};
    this.additionalInfoValues.map((entry) => {
      tempObj[entry.key] = entry.key;
      return { myObj: tempObj };
    });
    const temp = { ...extendedInfoData, ...tempObj };

    const info = this.infoRef.current.getSelectedItems().map((elem) => getKeyByValue(temp, elem.key));

    const index = this.indexRef.current.getSelectedItems().map((elem) => getKeyByValue(planStructure.index2, elem.key));
    const freigabe = this.freigabeRef.current
      .getSelectedItems()
      .map((elem) => getKeyByValue(planStructure.vorabzug, elem.key));

    const filterArray = {
      part,
      gewerk,
      phase,
      type,
      geschoss,
      info,
      index,
      freigabe,
    };

    this.generatedFileName = Object.values(this.filteredItem).map((item) => {
      if (item !== "") {
        return `${item}_`;
      }
      return item;
    });
    // this.generatedFileName = this.generatedFileName.slice(0, -1);
    this.generatedFileName = filterArray;
    this.specialFilter = true;
  };

  onRowSelect = (row, isSelect, rowIndex) => {
    if (isSelect) {
      this.selectedFiles.push(row);
    } else {
      this.selectedFiles.splice(
        this.selectedFiles.findIndex((i) => i.id === rowIndex),
        1
      );
    }
  };

  onSelectAllRows = (isSelect, rows, e) => {
    if (this.selectedFiles.length === 0) {
      this.selectedFiles = rows;
    } else {
      this.selectedFiles = [];
    }
  };

  handleClick = (item) => {
    item.current.resetSelectedValues();
  };

  buildDropdownValues = (objectArray, special) => {
    const { data } = this.props;

    const obj = [];
    Object.values(objectArray).map((elem) => obj.push({ key: elem }));

    if (special) {
      const temp = data.map((entry) => {
        const elem = entry.name.split("_")[5];
        return { key: elem };
      });
      const newFilter = temp.filter((elem) => !Object.keys(planStructure.info).includes(elem.key)).map((x) => x);
      const distinctValues = [...new Map(newFilter.map((item) => [item.key, item])).values()];
      this.additionalInfoValues = distinctValues;

      obj.push(...distinctValues);
    }

    return obj;
  };

  hideFirmModal = () => {
    this.showFirmListModal = false;
  };

  openShareDocumentsLog = () => {
    this.showShareDialog = true;
  };

  constructor() {
    super();
    this.partRef = React.createRef();
    this.gewerkRef = React.createRef();
    this.phaseRef = React.createRef();
    this.typeRef = React.createRef();
    this.geschossRef = React.createRef();
    this.infoRef = React.createRef();
    this.indexRef = React.createRef();
    this.freigabeRef = React.createRef();

    this.csvExportRef = React.createRef();
  }

  render() {
    const { data, obj_nr, companies, authStore, dataStore } = this.props;

    let csvDataPreprocessed = [];
    if (this.csvData.length > 0) {
      csvDataPreprocessed = this.csvData.map((entry) => entry.toJS());
    }

    const extendedData = data.map((element) => {
      const newObject = {};
      newObject.archive = element.archived;
      newObject.createdAt = element.createdAt;
      newObject.deletable = element.deletable;
      newObject.firmName = element.firmName;
      newObject.updatedAt = element.updatedAt;
      newObject.id = element.id;
      newObject.name = element.name;
      newObject.mime = element.mime;
      newObject.part = element.name.split("_")[0];
      newObject.gewerk = element.name.split("_")[1];
      newObject.phase = element.name.split("_")[2];
      newObject.type = element.name.split("_")[3];
      newObject.geschoss = element.name.split("_")[4];
      newObject.info = element.name.split("_")[5];
      newObject.index = element.name.split("_")[6];
      newObject.freigabe = element.name.split("_")[7];
      newObject.text = element.name.split("_")[8];
      newObject.note = element.name.split("_")[9];
      return newObject;
    });
    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    const extraFilters = [
      // eslint-disable-next-line react/jsx-key
      <Form.Control as='select' onChange={(e) => this.filterByObjectID(e.target.value)} id='nation' defaultValue=''>
        <option value=''>Filter Typ</option>
        <option value='pdf'>PDF</option>
        <option value='dwg'>DWG</option>
        <option value='jpg'>JPG</option>
        <option value='xls'>Excel</option>
        <option value='ifc'>IFC</option>
        <option value='rvt'>Revit</option>
      </Form.Control>,
    ];

    const columns = [
      {
        dataField: "name",
        text: "Dateiname",
        headerAlign: "center",
        sort: true,
        align: "center",
        formatter: this.fileNameFormatter,
        filter: textFilter({
          comparator: Comparator.LIKE,
          getFilter: (filter) => {
            this.specialFilter = false;
            this.filterByObjectID = filter;
          },
          style: { display: "none" },
        }),
      },
      {
        dataField: "part",
        text: "Bauteil",
        headerAlign: "center",
        sort: true,
        hidden: true,
        align: "center",
        headerStyle: () => ({ width: "10px" }),
      },
      {
        dataField: "gewerk",
        text: "Planungsgewerk",
        headerAlign: "center",
        sort: true,
        hidden: true,
        align: "center",
        headerStyle: () => ({ width: "10px" }),
      },
      {
        dataField: "phase",
        text: "Phase",
        headerAlign: "center",
        sort: true,
        hidden: true,
        align: "center",
      },
      {
        dataField: "type",
        text: "Kurzzeichen/Typ",
        headerAlign: "center",
        sort: true,
        hidden: true,
        align: "center",
        headerStyle: () => ({ width: "10px" }),
      },
      {
        dataField: "geschoss",
        text: "Geschoss",
        headerAlign: "center",
        sort: true,
        hidden: true,
        align: "center",
        headerStyle: () => ({ maxWidth: "10px", width: "10px" }),
      },
      {
        dataField: "info",
        text: "Zusatzinfo",
        headerAlign: "center",
        sort: true,
        hidden: true,
        align: "center",
        headerStyle: () => ({ width: "10px" }),
      },
      {
        dataField: "mime",
        text: "Typ",
        headerAlign: "center",
        sort: true,
        align: "center",
        formatter: this.convertMimeType,
      },
      {
        dataField: "firmName",
        text: "Hochgeladen von",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "updatedAt",
        text: "Uploaddatum",
        formatter: this.dateFormatter,
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "actions",
        text: "Aktionen",
        formatter: this.actionsFormatter,
        formatExtraData: {
          branchen: authStore.companyBranchen,
          selected: this.selectedFiles,
        },
        headerAlign: "center",
        align: "center",
      },
    ];

    const style = {
      chips: {
        background: "red",
      },
      searchBox: {},
      highlight: { background: "white" },
      multiselectContainer: {},
    };

    return (
      <div>
        <Row>
          <Container>
            <Accordion title='Filter Dateistruktur' initialExpanded>
              <Form className='m-3' onSubmit={(e) => this.applyFilter(e)}>
                <Row>
                  <Col>
                    <Form.Group style={{ position: "relative", width: "90%" }}>
                      <Form.Label>Bauteil</Form.Label>
                      <Multiselect
                        id='bauteil'
                        options={this.buildDropdownValues(planStructure.bauteil)}
                        displayValue='key'
                        showCheckbox
                        style={style}
                        ref={this.partRef}
                      />
                    </Form.Group>
                    <FontAwesomeIcon
                      className={styles.iconreset}
                      onClick={() => this.handleClick(this.partRef)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                      }}
                      color='#1f2427'
                      icon='undo'
                      title='Senden'
                    />
                  </Col>
                  <Col>
                    <Form.Group style={{ position: "relative", width: "90%" }}>
                      <Form.Label>Planungsgewerk</Form.Label>
                      <Multiselect
                        id='planungsgewerk'
                        options={this.buildDropdownValues(planStructure.planungsgewerk)}
                        displayValue='key'
                        showCheckbox
                        style={style}
                        ref={this.gewerkRef}
                      />
                    </Form.Group>
                    <FontAwesomeIcon
                      className={styles.iconreset}
                      onClick={() => this.handleClick(this.gewerkRef)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                      }}
                      color='#1f2427'
                      icon='undo'
                      title='Senden'
                    />
                  </Col>
                  <Col>
                    <Form.Group style={{ position: "relative", width: "90%" }}>
                      <Form.Label>Phase</Form.Label>
                      <Multiselect
                        id='phase'
                        options={this.buildDropdownValues(planStructure.phase)}
                        displayValue='key'
                        showCheckbox
                        style={style}
                        ref={this.phaseRef}
                      />
                    </Form.Group>
                    <FontAwesomeIcon
                      className={styles.iconreset}
                      onClick={() => this.handleClick(this.phaseRef)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                      }}
                      color='#1f2427'
                      icon='undo'
                      title='Senden'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group style={{ position: "relative", width: "90%" }}>
                      <Form.Label>Kurzzeichen/Typ</Form.Label>
                      <Multiselect
                        id='type'
                        options={this.buildDropdownValues(planStructure.type)}
                        displayValue='key'
                        showCheckbox
                        style={style}
                        ref={this.typeRef}
                      />
                    </Form.Group>
                    <FontAwesomeIcon
                      className={styles.iconreset}
                      onClick={() => this.handleClick(this.typeRef)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                      }}
                      color='#1f2427'
                      icon='undo'
                      title='Senden'
                    />
                  </Col>
                  <Col>
                    <Form.Group style={{ position: "relative", width: "90%" }}>
                      <Form.Label>Geschoss/Zuweisung</Form.Label>
                      <Multiselect
                        id='geschoss'
                        options={this.buildDropdownValues(planStructure.geschoss)}
                        displayValue='key'
                        showCheckbox
                        style={style}
                        ref={this.geschossRef}
                      />
                    </Form.Group>
                    <FontAwesomeIcon
                      className={styles.iconreset}
                      onClick={() => this.handleClick(this.geschossRef)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                      }}
                      color='#1f2427'
                      icon='undo'
                      title='Senden'
                    />
                  </Col>
                  <Col>
                    <Form.Group style={{ position: "relative", width: "90%" }}>
                      <Form.Label>Zusatzinfo</Form.Label>
                      <Multiselect
                        id='info'
                        options={this.buildDropdownValues(planStructure.info, true)}
                        displayValue='key'
                        showCheckbox
                        style={style}
                        ref={this.infoRef}
                      />
                    </Form.Group>
                    <FontAwesomeIcon
                      className={styles.iconreset}
                      onClick={() => this.handleClick(this.infoRef)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                      }}
                      color='#1f2427'
                      icon='undo'
                      title='Senden'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group style={{ position: "relative", width: "90%" }}>
                      <Form.Label>Index</Form.Label>
                      <Multiselect
                        id='index'
                        options={this.buildDropdownValues(planStructure.index)}
                        displayValue='key'
                        showCheckbox
                        style={style}
                        ref={this.indexRef}
                      />
                    </Form.Group>
                    <FontAwesomeIcon
                      className={styles.iconreset}
                      onClick={() => this.handleClick(this.indexRef)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                      }}
                      color='#1f2427'
                      icon='undo'
                      title='Senden'
                    />
                  </Col>
                  <Col>
                    <Form.Group style={{ position: "relative", width: "90%" }}>
                      <Form.Label>Vorabzug/Freigabe</Form.Label>
                      <Multiselect
                        id='vorabzug'
                        options={this.buildDropdownValues(planStructure.vorabzug)}
                        displayValue='key'
                        showCheckbox
                        style={style}
                        ref={this.freigabeRef}
                      />
                    </Form.Group>
                    <FontAwesomeIcon
                      className={styles.iconreset}
                      onClick={() => this.handleClick(this.freigabeRef)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                      }}
                      color='#1f2427'
                      icon='undo'
                      title='Senden'
                    />
                  </Col>
                </Row>
                <Row>
                  <Button className='m-2' type='submit' variant='secondary'>
                    Filter anwenden
                  </Button>
                  <Button className='m-2' variant='secondary' onClick={(e) => this.filterLatest(e, true)}>
                    Filter Letztstand
                  </Button>
                  <Button className='m-2' onClick={(e) => this.applyFilter(e, true)} variant='secondary'>
                    Filter zurücksetzen
                  </Button>
                  <a
                    download
                    href='https://auftrag.wohnbaugruppe.at/api/dokument/austausch/firma/files/eec77cdc-b350-4fae-aa8d-df8d18331b6e'
                    className='m-2'
                  >
                    Download Planliste
                  </a>
                  <Button className='m-2' onClick={this.openShareDocumentsLog} variant='secondary'>
                    Berechtigungslog
                  </Button>
                </Row>
              </Form>
            </Accordion>
          </Container>
        </Row>
        {this.action && (
          <div>
            <LoadingModal
              show={this.action === "download"}
              title={`Download '${this.actionRow.name}'...`}
              progress={this.actionProgress}
            />
            <ConfirmationModal
              show={this.action === "delete"}
              title={`Datei '${this.actionRow.name}' löschen?`}
              onYes={this.confirmDeleteFile}
              onNo={this.cancelDeleteFile}
              onHide={this.cancelDeleteFile}
              yesText='Ja'
              noText='Nein'
            />
          </div>
        )}
        <Col lg='6' style={{ paddingLeft: 10 }}>
          {this.selectedFiles && this.selectedFiles.length > 0 && (
            <div>
              <Button onClick={this.showFileModal} style={{ marginRight: "5px" }}>
                Senden
              </Button>
              <Button onClick={this.downloadSelected} style={{ margin: "5px" }}>
                Download
              </Button>
              <Button onClick={this.exportToCSV} style={{ margin: "5px" }}>
                Berechtigungsliste erstellen
              </Button>
              <CSVLink
                filename='CSV_Export.csv'
                data={csvDataPreprocessed}
                ref={this.csvExportRef}
                className='btn btn-primary'
                asyncOnClick
                onClick={(event, done) => console.log(event)}
                style={
                  this.csvData.length === 0
                    ? { pointerEvents: "none", opacity: "0.2" }
                    : { pointerEvents: "all", opacity: "1" }
                }
              >
                Export CSV
              </CSVLink>
            </div>
          )}
        </Col>
        <Form.Group controlId='formBasicEmail' style={{ float: "right" }}>
          <Form.Control onChange={(e) => (this.searchText = e.target.value)} type='text' placeholder='Suche' />
        </Form.Group>
        <CustomDataTable
          obj_id={obj_nr}
          filter={this.generatedFileName}
          specialFilter={this.specialFilter}
          data={extendedData}
          columns={columns}
          keyField='id'
          defaultSorted={defaultSorted}
          onRowSelected={this.onRowSelect}
          onSelectAllRows={this.onSelectAllRows}
          extraFilters={extraFilters}
          pageSize={25}
          selectedFiles={this.selectedFiles}
          companies={companies}
          searchText={this.searchText}
        />
        <Toast show={this.toastShown} onHide={() => (this.toastShown = false)} status={this.toastStatus} />
        {companies && this.readUsers && (
          <SelectFirmModal
            show={this.showFirmListModal}
            title='Mehrere Firmen Benachrichtigungen'
            companies={companies}
            readCompanies={this.readUsers}
            objId={obj_nr}
            files={this.selectedFiles}
            onHide={() => this.hideFirmModal({ sendData: false })}
            onSuccess={() => this.hideFirmModal({ sendData: true })}
          />
        )}
        {this.showShareDialog && data && (
          <ShareLogModal
            show={this.showShareDialog}
            title='Berechtigungslog'
            companies={companies}
            objId={obj_nr}
            files={data}
            onHide={() => (this.showShareDialog = false)}
            fileFilterId={this.selectedRowId}
          />
        )}
      </div>
    );
  }
}

export default ObjectFilesTable;
