/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-template,jsx-a11y/no-noninteractive-element-interactions,react/prop-types,no-debugger */
import React from "react";
import { Col, Container, Row, Modal, Form, InputGroup, FormControl, Jumbotron } from "react-bootstrap";
import { observable, toJS } from "mobx";
import { inject, observer } from "mobx-react/index";
import DatePicker from "react-datepicker";
import { PropTypes } from "prop-types";
import Button from "react-bootstrap/Button";
import FaultsTable from "../components/FaultsTable/FaultsTable";
import Header from "../components/Header/Header";
import OrderCommentEditor from "../components/OrderComments/OrderCommentEditor";
import OrderComments from "../components/OrderComments/OrderComments";
import styles from "../app.module.css";
import KeyDataCard from "../components/DataCard/KeyDataCard";
import Toast from "../components/Toast/Toast";
import StatusDataCard from "../components/DataCard/StatusDataCard";
import EditStatusModal from "../components/Modal/EditStatusModal";
import InvoiceDataModal from "../components/Modal/UploadInvoiceModal";
import AcceptModal from "../components/Modal/AcceptModal";
import LoadingSpinnerContainer from "../components/LoadingSpinner/LoadingSpinnerContainer";
import FileDropzone from "../components/FileDropzone/FileDropzone";
import FileList from "../components/FileList/FileList";
import LinkList from "../components/LinkList/LinkList";
import Accordion from "../components/Accordion/Accordion";
import withBreakpoint from "../components/withBreakpoint/withBreakpoint";
import StatusTimeline from "../components/StatusTimeline/StatusTimeline";
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import OrderRepairComponent from "../components/OrderRepair/OrderRepairComponent";
import theme from "../theme";

@inject("dataStore")
@observer
class OrderDetailsPage extends React.Component {
  @observable isShowModal = false;

  @observable statusModalVisible = false;

  @observable startModalVisible = false;

  @observable invoiceUploadedModalVisible = false;

  @observable completeModalVisible = false;

  @observable toastStatus = {};

  @observable fixDate = new Date();

  @observable showToastMessage = false;

  @observable invoicesToProcess = [];

  @observable invoiceIsPdf = false;

  @observable accepted = false;

  @observable isLoadingError = false;

  @observable responsiblePerson = null;

  static propTypes = {
    match: PropTypes.any.isRequired,
    dataStore: PropTypes.any.isRequired,
    breakpoint: PropTypes.string.isRequired,
  };

  showStatusModal = () => {
    const { dataStore } = this.props;

    if (!dataStore.orderDetails.status.fertig_real) {
      this.statusModalVisible = true;
    }
  };

  acceptOrder = () => {
    this.accepted = true;
    this.statusModalVisible = true;
  };

  startOrder = (date) => {
    const { dataStore } = this.props;
    // Set start date to 1min ago to make sure we get "In Progress" as the next status
    dataStore.setOrderStatus(dataStore.orderDetails.auftr_id, {
      beginn_real: date.toISOString(),
    });

    this.startModalVisible = false;
  };

  refuseOrder = () => {
    this.accepted = false;
    this.statusModalVisible = true;
  };

  completeOrder = (date) => {
    const { dataStore } = this.props;
    // Set finish date to 1min ago to make sure we get "Completed" as the next status
    dataStore.setOrderStatus(dataStore.orderDetails.auftr_id, {
      fertig_real: date,
    });

    this.startModalVisible = false;
  };

  updateOrder = () => {
    const { dataStore } = this.props;
    dataStore.loadOrderDetails(dataStore.orderDetails.auftr_id).catch(() => (this.isLoadingError = true));
  };

  hideStatusModal = (isToast) => {
    if (isToast) {
      this.showToastMessage = true;
      this.toastStatus = {
        body: "Das Statusupdate konnte nicht gespeichert werden! Bitte Änderungsgrund angeben.",
        title: "Fehler!",
        type: "error",
      };
    } else {
      this.statusModalVisible = false;
    }
  };

  hideInvoiceModal = (value) => {
    if (value) {
      this.invoiceUploadedModalVisible = true;
    } else {
      location.reload();
    }
    this.invoicesToProcess.shift();
  };

  handleChange = (date) => {
    this.fixDate = date;
  };

  processInvoices = (files) => {
    files.forEach((file) => {
      if (file.name.split(".").pop().toUpperCase() === "PDF") {
        this.invoicesToProcess.push(file);
        this.invoiceIsPdf = true;
      } else {
        this.invoicesToProcess.push(file);
        this.invoiceIsPdf = false;
      }
    });
  };

  showResolveModal = () => {
    this.isShowModal = true;
  };

  hideModal = () => {
    this.isShowModal = false;
  };

  addResponsiblePerson = async () => {
    const {
      dataStore,
      match: {
        params: { orderId },
      },
    } = this.props;

    await dataStore.uploadResponsiblePerson(this.responsiblePerson, orderId);
    const orderData = toJS(dataStore.orderDetails);

    if (orderData.status && orderData.status.statuscode === 0) {
      const status = {
        statuscode: 1,
        annahmedatum: new Date().toISOString(),
        beginn_plan: new Date().toISOString(),
        beginn_plan_grund: new Date().toISOString(),
      };
      await dataStore.setOrderStatus(orderId, status);
    }
  };

  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  componentDidMount() {
    const {
      dataStore,
      match: {
        params: { orderId },
      },
    } = this.props;
    this.isLoadingError = false;
    const auftr_id = orderId;
    dataStore.loadFaults(auftr_id).catch(() => (this.isLoadingError = true));
    dataStore.loadOrderDetails(auftr_id).catch(() => (this.isLoadingError = true));
  }

  componentDidUpdate() {
    const { dataStore } = this.props;
    this.responsiblePerson =
      dataStore.orderDetails && dataStore.orderDetails.zustaendig ? dataStore.orderDetails.zustaendig.text : "";
  }

  componentWillUnmount() {
    const { dataStore } = this.props;
    dataStore.clearOrderDetails();
  }

  render() {
    const {
      match: {
        params: { orderId },
      },
      breakpoint,
      dataStore,
    } = this.props;

    const config = theme[process.env.REACT_APP_CLIENT];

    const orderData = toJS(dataStore.orderDetails);
    const faultsData = toJS(dataStore.faults);

    return (
      <div>
        <Header />
        {this.isLoadingError ? (
          <Container>
            <Row className='py-4'>
              <Col>
                <h1 className='text-center'>{`Auftrag #${orderId} kann nicht geladen werden!`}</h1>
              </Col>
            </Row>
          </Container>
        ) : (
          <LoadingSpinnerContainer loading={!orderData || !dataStore.faults}>
            {() => (
              <Container className='mb-5'>
                <Row className='py-4'>
                  <Col>
                    <Jumbotron>
                      <h1 className='text-center'>
                        Auftrag
                        <br />
                        <div className='text-break'>{`${orderData.auftr_nr}`}</div>
                      </h1>
                      {this.getWindowDimensions().width > 1200 && <hr className='mt-5' />}
                      {orderData.status.statuscode !== 2 ? (
                        <StatusTimeline
                          activeStatus={orderData.status.statuscode}
                          {...(orderData.status.statuscode !== 0 && {
                            onActiveStatusClick: this.showStatusModal,
                          })}
                        />
                      ) : (
                        <div className='text-center mt-5'>
                          <h3>Abgelehnt</h3>
                          <span>{`Grund: ${orderData.status.ablehngrund}`}</span>
                        </div>
                      )}
                      {orderData.status.statuscode === 0 ? (
                        <Row className='mt-3 justify-content-center'>
                          <Col xs={6} md={5} lg={4}>
                            <Button variant='primary' size='lg' block onClick={this.acceptOrder}>
                              Auftrag Annehmen
                            </Button>
                          </Col>
                          <Col xs={6} md={5} lg={4}>
                            <Button variant='dark' size='lg' block onClick={this.refuseOrder}>
                              Auftrag Ablehnen
                            </Button>
                          </Col>
                        </Row>
                      ) : orderData.status.statuscode === 1 ? (
                        <Row className='mt-3 justify-content-center'>
                          <Col xs={6} md={5} lg={4}>
                            <Button variant='primary' size='lg' block onClick={this.showStatusModal}>
                              Details Ändern
                            </Button>
                          </Col>

                          <Col xs={6} md={5} lg={4}>
                            {!orderData.status.beginn_real ? (
                              <Button variant='primary' size='lg' block onClick={() => (this.startModalVisible = true)}>
                                Auftrag Beginnen
                              </Button>
                            ) : (
                              <div className='alert alert-danger text-center font-weight-bold'>
                                Auftragsstart:
                                {new Date(orderData.status.beginn_real).toLocaleString().replace(/:\d+ /, " ")}
                              </div>
                            )}
                            <ConfirmationModal
                              show={this.startModalVisible}
                              onYes={(date) => this.startOrder(date)}
                              onNo={() => (this.startModalVisible = false)}
                              onHide={() => (this.startModalVisible = false)}
                              title='Wollen Sie den Auftrag jetzt beginnen?'
                              header='Tatsächlicher Beginn'
                              yesText='Ja'
                              noText='Nein'
                              showDate
                              beginDate={orderData.status.beginn_plan}
                              beginRealDate={orderData.status.beginn_real}
                            />
                          </Col>
                        </Row>
                      ) : orderData.status.statuscode === 3 &&
                        orderData.status.statuscode !== 4 &&
                        !orderData.status.fertig_real ? (
                        // eslint-disable-next-line react/jsx-indent
                        <Row className='mt-3 justify-content-center'>
                          <Col xs={6} md={5} lg={4}>
                            <Button variant='primary' size='lg' block onClick={this.showStatusModal}>
                              Details Ändern
                            </Button>
                          </Col>
                          <Col xs={6} md={5} lg={4}>
                            <Button
                              variant='primary'
                              size='lg'
                              block
                              onClick={() => (this.completeModalVisible = true)}
                            >
                              Auftrag Abschließen
                            </Button>
                            <ConfirmationModal
                              show={this.completeModalVisible}
                              onYes={(date) => this.completeOrder(date)}
                              onNo={() => (this.completeModalVisible = false)}
                              onHide={() => (this.completeModalVisible = false)}
                              title='Wollen Sie den Auftrag jetzt abschließen?'
                              header='Tatsächliche Fertigstellung'
                              yesText='Ja'
                              noText='Nein'
                              showDate
                              beginDate={orderData.status.fertig_plan}
                              beginRealDate={orderData.status.beginn_real}
                            />
                          </Col>
                        </Row>
                      ) : orderData.status.statuscode === 3 && orderData.status.fertig_real ? (
                        <Row className='justify-content-center'>
                          <Col lg={7}>
                            <h5 className='alert alert-danger text-center font-weight-bold'>
                              Auftrag wird am
                              {new Date(orderData.status.fertig_real).toLocaleString().replace(/:\d{2}\s/, " ")}
                              fertiggestellt.
                            </h5>
                          </Col>
                        </Row>
                      ) : null}
                    </Jumbotron>
                  </Col>
                </Row>
                <Row className='justify-content-md-center'>
                  <Col xs={12} md={12} lg={3}>
                    {orderData.status.statuscode !== 0 && (
                      <Row className='pb-2'>
                        <Col>
                          <StatusDataCard
                            orderDetails={orderData}
                            collapsable={breakpoint === "sm" || breakpoint === "xs"}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row className='pb-2'>
                      <Col>
                        <KeyDataCard
                          orderDetails={orderData}
                          collapsable={breakpoint === "sm" || breakpoint === "xs"}
                        />
                      </Col>
                    </Row>
                    <EditStatusModal
                      show={this.statusModalVisible}
                      onHide={(value) => this.hideStatusModal(value)}
                      orderDetails={dataStore.orderDetails}
                      accepted={this.accepted}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={9}>
                    {(orderData.beschr || orderData.auftragsschreiben) && (
                      <Row className='pb-2'>
                        <Col>
                          <Accordion title='Auftrag' initialExpanded>
                            <Row>
                              <Col xs={12} sm={6}>
                                <h5 className='text-center'>Auftragsbeschreibung</h5>
                                <hr />
                                <p className={`${styles["text-center"]} pt-2`}>{orderData.beschr}</p>
                              </Col>
                              <Col xs={12} sm={6}>
                                <h5 className='text-center'>Auftragsschreiben/Beilagen</h5>
                                <hr />
                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                  {orderData.auftragsschreiben && (
                                    <FileList
                                      files={orderData.auftragsschreiben.map((v) => ({
                                        name: v.name,
                                        link: process.env.PUBLIC_URL + v.linkToArchive,
                                      }))}
                                      iconSize='3x'
                                    />
                                  )}
                                  {orderData.auftragsschreiben && orderData.auftragsschreiben[0].unterschrift_url && (
                                    <LinkList
                                      files={orderData.auftragsschreiben.map((v) => ({
                                        name: "Digitale Signatur",
                                        link: v.unterschrift_url,
                                      }))}
                                      icon='fa-file-signature'
                                      iconSize='3x'
                                      updateOrder={this.updateOrder}
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row />
                            <Col style={{ width: "50%" }}>
                              <Form.Group as={Row} className='mb-3' controlId='formBasicEmail'>
                                <Col xs='3'>
                                  <Form.Label column sm='2'>
                                    Zuständig:
                                  </Form.Label>
                                </Col>
                                <Col xs='9'>
                                  <Form.Control
                                    type='name'
                                    value={this.responsiblePerson}
                                    onChange={(e) => (this.responsiblePerson = e.target.value)}
                                    placeholder='Max Mustermann...'
                                    disabled={orderData && orderData.zustaendig}
                                  />
                                  <Form.Text className='text-muted'>Kann nur einmal gesetzt werden.</Form.Text>
                                </Col>
                              </Form.Group>

                              <Button
                                disabled={orderData && orderData.zustaendig}
                                size='xs'
                                variant='danger'
                                type='button'
                                onClick={this.addResponsiblePerson}
                                style={{ float: "right" }}
                              >
                                Setzen
                              </Button>
                            </Col>
                          </Accordion>
                        </Col>
                      </Row>
                    )}
                    {orderData.reparaturauftrag && config.modules.repauftrag && (
                      <OrderRepairComponent
                        openDetailsModal={(row) => this.showDetailsModal(row)}
                        orderData={orderData}
                        orderId={orderId}
                      />
                    )}
                    {orderData.status.statuscode !== 0 && (
                      <Row className='pb-2'>
                        <Col>
                          <Accordion title='Rechnung' titleBackgroundColor='#1f2427' initialExpanded>
                            <p className={styles.invoiceText}>
                              Bitte eventuelle Beilagen (zB Lieferscheine, Nachweise) mit der Rechnung gemeinsam in
                              einer PDF-Datei hochladen (Rechnung an erster Stelle)
                            </p>
                            <Container className='pt-2'>
                              <Row style={{ minHeight: "150px" }}>
                                <Col>
                                  <FileDropzone
                                    onDrop={this.processInvoices}
                                    iconSize='3x'
                                    allowedExtensions={["pdf", "xml"]}
                                    text='Rechnungen uploaden'
                                  />
                                </Col>
                                {dataStore.orderDetails.rechnungen && dataStore.orderDetails.rechnungen.length > 0 && (
                                  <Col xs={8}>
                                    <FileList
                                      files={dataStore.orderDetails.rechnungen.map((v) => ({
                                        name: v.doc.name ? v.doc.name : "unnamed",
                                        link: process.env.PUBLIC_URL + v.doc.linkToArchive,
                                      }))}
                                      iconSize='3x'
                                    />
                                  </Col>
                                )}
                              </Row>
                            </Container>
                          </Accordion>
                        </Col>
                      </Row>
                    )}
                    {/* faults are currently disabled */}
                    {false && faultsData.length > 0 && (
                      <Row className='pb-2'>
                        <Col>
                          <Accordion title='Mängel' initialExpanded>
                            <Container>
                              <FaultsTable data={faultsData} />
                              <div className='d-flex'>
                                <div className='ml-auto'>
                                  <Button
                                    size='sm'
                                    variant='danger'
                                    type='button'
                                    onClick={this.showResolveModal}
                                    className={styles["save-button"]}
                                  >
                                    Beheben
                                  </Button>
                                </div>
                              </div>
                            </Container>
                          </Accordion>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col>
                        <Accordion title='Kommentare' initialExpanded>
                          <Row className='pt-2'>
                            <Col>
                              <OrderCommentEditor />
                            </Col>
                          </Row>
                          <Row className='pt-3'>
                            <Col>
                              <OrderComments />
                            </Col>
                          </Row>
                        </Accordion>
                      </Col>
                    </Row>
                    <InvoiceDataModal
                      show={this.invoicesToProcess.length > 0}
                      onHide={() => this.hideInvoiceModal(false)}
                      onSuccess={() => this.hideInvoiceModal(true)}
                      rechnungsType={dataStore.orderDetails.rechnungstypen}
                      data={this.invoicesToProcess}
                      invoicedata={dataStore.orderDetails.rechnungen}
                      orderId={orderId}
                      invoiceIsPdf={this.invoiceIsPdf}
                    />
                    <AcceptModal
                      show={this.invoiceUploadedModalVisible}
                      onHide={() => {
                        this.invoiceUploadedModalVisible = false;
                        location.reload();
                      }}
                    />

                    <Modal className='text-center' show={this.isShowModal} onHide={this.hideModal} centered>
                      <Modal.Header className={styles["card-header"]} closeButton>
                        <Modal.Title id='contained-modal-title-vcenter'>Mängeldetails</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className={styles.modal}>
                        <Form.Group>
                          <Form.Label>Behebungsdatum</Form.Label>
                          <div>
                            <DatePicker
                              weekLabel='KW'
                              showWeekNumbers
                              selected={this.fixDate}
                              onChange={this.handleChange}
                              dateFormat='d. MMMM yyyy'
                              className='form-control text-center'
                            />
                          </div>
                        </Form.Group>
                        <label htmlFor='anm'>Anmerkung</label>
                        <InputGroup className='form-group'>
                          <FormControl id='anm' as='textarea' aria-label='With textarea' />
                        </InputGroup>
                        <div className={styles["image-upload-file"]}>
                          <label htmlFor='file-input'>
                            <img alt='fileUpload' width='40px' src='http://placekitten.com/64/64' />
                          </label>
                          <input id='file-input' type='file' />
                          <div className={styles.caption}>Dateien hinzufügen</div>
                        </div>
                        <Button variant='danger' onClick={this.hideModal} className={styles["card-header"]}>
                          Mängel Beheben
                        </Button>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
                <Toast
                  status={this.toastStatus}
                  onHide={() => (this.showToastMessage = false)}
                  show={this.showToastMessage}
                />
              </Container>
            )}
          </LoadingSpinnerContainer>
        )}
      </div>
    );
  }
}

export default withBreakpoint(OrderDetailsPage);
