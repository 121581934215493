import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import LoadingSpinner from './LoadingSpinner';

@observer
class LoadingSpinnerContainer extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.func.isRequired,
  };

  render() {
    const { loading, children } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    }
    return children() || null;
  }
}

export default LoadingSpinnerContainer;
