import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Modal from './Modal';

@observer
class LoadingModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    progress: PropTypes.number,
  };

  static defaultProps = {
    title: 'Loading...',
    progress: undefined,
  };

  render() {
    const { show, title, progress } = this.props;

    return (
      <Modal
        show={show}
        title={title}
        body={(
          <Container>
            {progress ? (
              <ProgressBar now={progress} animated label={`${progress}%`} />
            ) : (
              <LoadingSpinner />
            )}
          </Container>
)}
      />
    );
  }
}

export default LoadingModal;
