import React from "react";
import PropTypes from "prop-types";
import BSToast from "react-bootstrap/Toast";
import { observer } from "mobx-react";
import style from "./Toast.module.scss";

@observer
class Toast extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
    status: PropTypes.object.isRequired,
    delay: PropTypes.number,
    right: PropTypes.bool,
  };

  static defaultProps = {
    onHide: null,
    delay: 3000,
    right: false,
  };

  render() {
    const { show, onHide, status, delay, right } = this.props;

    return (
      <BSToast
        className={[style.toast, style[status.type]]}
        onClose={onHide}
        show={show}
        delay={delay}
        autohide
        style={{ left: right ? 40 : "unset" }}
      >
        <BSToast.Header>
          <strong className='mr-auto'>{status.title}</strong>
        </BSToast.Header>
        <BSToast.Body>{status.body}</BSToast.Body>
      </BSToast>
    );
  }
}

export default Toast;
