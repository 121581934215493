/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this,no-debugger,no-restricted-globals */
import { observable, action, runInAction } from "mobx";
import { saveAs } from "file-saver";
import ApiService from "../services/ApiService";
import authStore from "./AuthStore";

class DataStore {
  @observable orders = null;

  @observable orderDetails = null;

  @observable faults = null;

  @observable objectFiles = new Map();

  @observable objects;

  @observable companies;

  @action clear() {
    this.orders = null;
    this.orderDetails = null;
    this.faults = null;
    this.objects = null;
    this.objectFiles = new Map();
  }

  getObject = (objectId) => {
    if (!this.objects) return null;
    return this.objects.find((v) => parseInt(v.obj_id, 10) === parseInt(objectId, 10));
  };

  @action async addObjectFile(objectId, filename, file, username, date, man_id, obj_id, onProgress) {
    if (!this.objectFiles.get(objectId)) {
      this.objectFiles.set(objectId, []);
    }
    return ApiService.uploadObjectFile(objectId, filename, file, onProgress);
  }

  @action async loadObjectFiles(obj_id) {
    try {
      const res = await ApiService.getObjectFiles(obj_id);
      runInAction(() => {
        this.objectFiles.set(obj_id, res.data);
      });
      return res;
    } catch (e) {
      // throw new Error(e);
    }
  }

  @action async loadObjectFilesById(obj_id) {
    const res = await ApiService.getObjectFiles(obj_id);
    return res;
  }

  @action async loadUploadFiles() {
    try {
      const res = await ApiService.getUploadFilesPerCompany();
      runInAction(() => {
        this.filesToShare = res.data;
      });
    } catch (e) {
      // throw new Error(e);
    }
  }

  @action async loadOrders() {
    try {
      const res = await ApiService.getOrders();
      runInAction(() => {
        this.orders = res.data;
      });
    } catch (e) {
      console.log(e);
    }
  }

  @action async loadOrderDetails(id) {
    try {
      const res = await ApiService.getOrderDetails(id);
      runInAction(() => {
        this.orderDetails = res.data;
      });
    } catch (e) {
      throw new Error(e);
    }
  }

  @action async loadFaults(id) {
    try {
      this.faults = null;
      const res = await ApiService.getFaults(id);
      runInAction(() => {
        this.faults = res.data;
      });
    } catch (e) {
      // throw new Error(e);
    }
  }

  @action async loadObjects() {
    try {
      const res = await ApiService.getObjects(authStore.firmId);
      runInAction(() => {
        this.objects = res.data;
      });
    } catch (e) {
      console.log(e);
    }
  }

  @action async setOrderStatus(id, status) {
    try {
      const res = await ApiService.setOrderStatus(id, status);
      await this.loadOrderDetails(id);
      this.orders = null;
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  @action async uploadInvoice(file, data, orderId) {
    const document = await ApiService.uploadDocument(file);
    const res = await ApiService.uploadInvoice(file.name, document.data, data, orderId);
    await this.loadOrderDetails(orderId);
    return res.data;
  }

  @action async uploadDocument(file) {
    const document = await ApiService.uploadDocument(file);
    return document.data;
  }

  @action async sendFilePerEmail(obj_id, file_ids) {
    try {
      const res = await ApiService.sendFilePerEmail(obj_id, file_ids);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  @action async uploadResponsiblePerson(nameToUpload, orderId) {
    try {
      const res = await ApiService.uploadResponsiblePerson(nameToUpload, orderId);
      await this.loadOrderDetails(orderId);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  @action async downloadAllFiles(selectedFiles, obj_id) {
    try {
      const fileList = [];
      await Promise.all(
        selectedFiles.map(async (file) => {
          fileList.push(file.id);
        })
      );

      const res = await ApiService.downloadAllFiles(fileList, obj_id);

      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      saveAs(blob, "files.zip");
      // return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  @action async downloadAllFilesById(fileIds, obj_id) {
    try {
      const res = await ApiService.downloadAllFiles(fileIds, obj_id);

      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      saveAs(blob, "files.zip");
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  @action async addOrderComment(id, commentDatetime, commentText, commentDocuments) {
    try {
      const documents = [];

      await Promise.all(
        commentDocuments.map(async (value) => {
          const res = await ApiService.uploadDocument(value);
          documents.push(res.data);
          return res.data;
        })
      );
      await ApiService.addOrderComment(id, commentDatetime, commentText, documents);
      await this.loadOrderDetails(id);
    } catch (e) {
      console.log(e);
    }
  }

  srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], fileName, { type: mimeType }));
  }

  @action async uploadRepairJobs(orderId, repairJobData) {
    try {
      let signatureData;
      if (typeof repairJobData.signature === "string") {
        const sigImage = await this.srcToFile(repairJobData.signature, `${orderId}-sig.png`, "image/png");

        const res = await ApiService.uploadDocument(sigImage);
        signatureData = res.data;
      } else {
        signatureData = repairJobData.signature;
      }
      const attachments = [];
      await Promise.all(
        repairJobData.attachments.map(async (value) => {
          if (value.linkToArchive === undefined) {
            const res = await ApiService.uploadDocument(value);
            attachments.push(res.data);
            return res.data;
          }

          attachments.push(value);
        })
      );

      const modifiedRepairItems = await Promise.all(
        repairJobData.repairItems.map(async (jobs) => ({
          ...jobs,
          anhang:
            jobs && jobs.anhang && jobs.anhang.length > 0
              ? await Promise.all(
                  jobs.anhang &&
                    jobs.anhang.map(async (attachment) => {
                      if (attachment.linkToArchive !== undefined) {
                        return attachment;
                      }
                      const res = await ApiService.uploadDocument(attachment);
                      return res.data;
                    })
                )
              : [],
        }))
      );

      const modifiedRepairedJobData = { ...repairJobData, repairItems: modifiedRepairItems };

      const res = await ApiService.uploadRepairJobs(orderId, modifiedRepairedJobData, signatureData, attachments);
      await this.loadOrderDetails(orderId);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  @action async sendEmailNotification(companies, man_id, obj_nr, obj_id, message, subject, file_id, attachments) {
    try {
      const res = await ApiService.sendEmailNotification(
        companies,
        man_id,
        obj_nr,
        obj_id,
        message,
        subject,
        file_id,
        attachments
      );
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  downloadZip(res, name) {
    const elm = document.createElement("a");
    elm.href = URL.createObjectURL(res.data);
    elm.setAttribute("download", name);
    elm.click();
  }

  downloadDocument(res, name) {
    const a = document.createElement("a");

    const blob = new Blob([res.data], {
      type: res.headers["content-type"],
    });

    if (window.navigator && window.navigator.msSaveBlob) {
      // Internet Explorer workaround
      window.navigator.msSaveBlob(blob, name);
    } else {
      a.href = window.URL.createObjectURL(blob);
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(a.href);
    }
    return res;
  }

  @action async downloadObjectFile(obj_id, file_id, name, onProgress) {
    const res = await ApiService.downloadObjectFile(obj_id, file_id, onProgress).catch(async (error) => {
      if (error.response) {
        // const text = await new Response(error.response.data).text();
        // throw new Error(text);
      } else {
        // throw new Error(error);
      }
    });

    return this.downloadDocument(res, name);
  }

  @action async getCompaniesWithAccess(obj_id, file_id) {
    try {
      const res = await ApiService.getCompaniesWithAccess(obj_id, file_id);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  @action async saveCompaniesWithAccess(obj_id, file_id, idArray) {
    try {
      const res = await ApiService.saveCompanieswithAccess(obj_id, file_id, idArray);
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  @action async getCompaniesPerObject(man_id, obj_id) {
    try {
      const res = await ApiService.getCompaniesPerObject(man_id, obj_id);
      runInAction(() => {
        this.companies = res.data;
      });
    } catch (e) {
      console.log(e);
    }
  }

  @action async getCompanies(man_id, obj_id) {
    try {
      const res = await ApiService.getCompaniesPerObject(man_id, obj_id);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  @action async getDownloadedFirms(obj_id, file_id) {
    const res = await ApiService.getDownloadedFirms(obj_id, file_id);
    return res.data;
  }

  @action async deleteObjectFile(obj_id, file_id) {
    await ApiService.deleteObjectFile(obj_id, file_id).catch((error) => {
      throw new Error((error.response && error.response.data) || error);
    });
    const res = await ApiService.getObjectFiles(obj_id);
    runInAction(() => {
      this.objectFiles.set(obj_id, res.data);
    });
  }

  @action clearOrderDetails() {
    this.orderDetails = null;
  }

  @action async getFileNotifications() {
    const res = await ApiService.getFileNotifications();
    return res.data;
  }

  @action async getReadUsers(objId, fileIds) {
    const res = await ApiService.getReadUsers(objId, fileIds);
    return res.data;
  }

  @action async acknowledgeFileNotification(notificationId) {
    const res = await ApiService.acknowledgeFileNotification(notificationId);
    return res.data;
  }

  @action async getAcknowledgedFirms(obj_id, file_id) {
    const res = await ApiService.getAcknowledgedFirms(obj_id, file_id);
    return res.data;
  }

  @action async addFavoriteGroup(groupName, obj_id) {
    const res = await ApiService.addFavoriteGroup(groupName, obj_id);
    return res.data;
  }

  @action async getFavoriteGroups(obj_id) {
    try {
      const res = await ApiService.getFavoriteGroups(obj_id);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  @action async deleteFavoriteGroup(groupId, obj_id) {
    const res = await ApiService.deleteFavoriteGroup(groupId, obj_id);
    return res.data;
  }

  @action async addFirmToFavoriteGroup(groupId, obj_id, firmId) {
    const res = await ApiService.addFirmToFavoriteGroup(groupId, obj_id, firmId);
    return res.data;
  }

  @action async deleteFirmPerFavoriteGroup(groupId, obj_id, firmId) {
    const res = await ApiService.deleteFirmPerFavoriteGroup(groupId, obj_id, firmId);
    return res.data;
  }

  @action async getLogData(from, to, objId) {
    try {
      const res = await ApiService.getLogData(from, to, objId);
      console.log(res);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
}

export default new DataStore();
