import React from "react";
import { Collapse } from "react-bootstrap";
import { observer } from "mobx-react";
import { observable, computed } from "mobx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import theme from "../../theme";
import style from "./Accordion.module.scss";

library.add(faChevronDown, faChevronUp);

@observer
class Accordion extends React.Component {
  @observable expanded = false;

  @computed get icon() {
    return this.expanded ? "chevron-up" : "chevron-down";
  }

  componentWillMount() {
    const { initialExpanded } = this.props;
    this.expanded = initialExpanded;
  }

  componentWillReceiveProps(newProps) {
    if (newProps.expanded !== undefined) {
      this.expanded = newProps.expanded;
    }
  }

  toggleExpanded = () => {
    this.expanded = !this.expanded;
  };

  render() {
    const { title, titleBackgroundColor, titleColor, titleBorderRadius, children, disabled } = this.props;

    const config = theme[process.env.REACT_APP_CLIENT];

    const titleStyle = {
      backgroundColor: titleBackgroundColor === "#ea2210" && config.color ? config.color : titleBackgroundColor,
      color: titleColor,
      borderRadius: titleBorderRadius,
    };

    return (
      <div className={style.fullWidth}>
        <button
          type='button'
          aria-expanded={this.expanded}
          aria-controls={`${title.replace(/\s/g, "")}-collapse`}
          onClick={this.toggleExpanded}
          disabled={disabled}
          className={`${style.header} ${style.fullWidth}`}
          style={titleStyle}
        >
          {title}
          {!disabled && (
            <span className='float-right'>
              <FontAwesomeIcon color='white' icon={this.icon} />
            </span>
          )}
        </button>
        <Collapse in={this.expanded}>
          <div id={`${title.replace(/\s/g, "")}-collapse`}>{children}</div>
        </Collapse>
      </div>
    );
  }
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  titleBackgroundColor: PropTypes.string,
  titleColor: PropTypes.string,
  children: PropTypes.any.isRequired,
  initialExpanded: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  expanded: PropTypes.any,
  titleBorderRadius: PropTypes.string,
  disabled: PropTypes.bool,
};

Accordion.defaultProps = {
  titleBackgroundColor: "#ea2210",
  titleColor: "#fff",
  initialExpanded: false,
  expanded: undefined,
  titleBorderRadius: "4px",
  disabled: false,
};

export default Accordion;
