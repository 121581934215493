/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import moment from "moment";

import React from "react";
import PropTypes from "prop-types";
import { Button, Row, Col, Form, Container } from "react-bootstrap";
import { observable } from "mobx";
import { inject, observer } from "mobx-react/index";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "../Accordion/Accordion";
import OrderRepairTable from "../OrderRepairTable";
import RepairDetailModal from "../Modal/RepairDetailModal";
import "react-datepicker/dist/react-datepicker.css";
import Toast from "../Toast/Toast";
import FileList from "../FileList/FileList";
import SignatureModal from "../Modal/SignatureModal";
import { currencyFormatDE } from "../../utils";

@inject("dataStore")
@observer
class OrderRepairComponent extends React.Component {
  static propTypes = {
    orderData: PropTypes.any.isRequired,
    dataStore: PropTypes.any.isRequired,
    orderId: PropTypes.string.isRequired,
  };

  @observable showDetailModal = false;

  @observable showSignatureModal = false;

  @observable selectedRepairEntry = {};

  @observable repairItems = [];

  @observable beginDate = new Date();

  @observable showToast = false;

  @observable toastStatus = {};

  @observable signatureImage = null;

  @observable signatureText = null;

  @observable attachments = [];

  constructor(props) {
    super();
    const { orderData } = props;
    this.repairItems = orderData.reparaturauftrag.arbeit;

    this.repairItems = this.repairItems.map((entry, index) => ({
      ...entry,
      id: index + 1,
    }));
    this.beginDate = orderData.reparaturauftrag.beginn || new Date();
    this.signatureData = orderData.reparaturauftrag.unterschrift;
    this.signatureText = orderData.reparaturauftrag.name;
    this.attachments = orderData.reparaturauftrag.anhang || [];
  }

  componentDidUpdate(prevProps) {
    const { orderData } = this.props;
    if (prevProps.orderData !== orderData) {
      this.repairItems = orderData.reparaturauftrag.arbeit;

      this.repairItems = this.repairItems.map((entry, index) => ({
        ...entry,
        id: index + 1,
      }));
    }
    this.signatureData = orderData.reparaturauftrag.unterschrift;
  }

  updateRow = (item) => {
    const rowId = item.id;

    const exists = this.repairItems.some((el) => el.id === rowId);

    if (exists) {
      const rowIndex = this.repairItems.findIndex((el) => el.id === rowId);
      this.repairItems[rowIndex] = item;
    } else {
      this.repairItems.push(item);
    }
    this.updateRepairJobs();

    this.showDetailModal = false;
  };

  deleteRow = (id) => {
    const rowId = id;

    const exists = this.repairItems.some((el) => el.id === rowId);

    if (exists) {
      const rowIndex = this.repairItems.findIndex((el) => el.id === rowId);
      this.repairItems.splice(rowIndex, 1);
    }

    this.updateRepairJobs();

    this.showDetailModal = false;
  };

  addRepairEntry = () => {
    this.showDetailModal = true;
    this.selectedRepairEntry = {};
  };

  openFileClick = (row) => {
    this.showDetailModal = true;
    this.selectedRepairEntry = { row };
  };

  addAttachment = (e) => {
    const { files } = e.target;
    const filesArr = Array.prototype.slice.call(files);
    this.attachments = [...this.attachments, ...filesArr];
    this.updateRepairJobs();
  };

  updateRepairJobs = () => {
    const { dataStore, orderId, orderData } = this.props;

    if (!this.beginDate) {
      this.toastStatus = {
        body: "Beginndatum setzen!",
        title: "Fehler!",
        type: "error",
      };
      this.showToast = true;
      return;
    }

    const repairJobData = {
      date: new Date(this.beginDate).toISOString().slice(0, 10),
      repairItems: this.repairItems,
      attachments: this.attachments,
      signature: this.signatureImage ? this.signatureImage : this.signatureData,
      signatureText: this.signatureText,
    };

    dataStore
      .uploadRepairJobs(orderId, repairJobData)
      .then((res) => {
        this.showToast = true;
        this.toastStatus = {
          body: "Autrag hochgeladen",
          title: "Erfolg!",
          type: "success",
        };
      })
      .then(() => {
        if (orderData.status && orderData.status.statuscode === 0) {
          const status = {
            statuscode: 1,
            annahmedatum: new Date().toISOString(),
            beginn_plan: new Date().toISOString(),
            beginn_plan_grund: new Date().toISOString(),
          };
          dataStore.setOrderStatus(orderId, status);
        }
      });
  };

  getSignatureData = (image, name) => {
    this.signatureImage = image;
    this.signatureText = name;
    this.showSignatureModal = false;
    this.updateRepairJobs();
  };

  render() {
    const { orderData } = this.props;
    const totalCosts = this.repairItems.reduce((total, cost) => total + Number(cost.kosten), 0);

    return (
      <div className='p-2'>
        <Accordion title='Reparaturauftrag' titleBackgroundColor='#1f2427' initialExpanded>
          <Container style={{ padding: "1.5rem" }} className='pt-2'>
            <Row style={{ marginBottom: 20, background: " #e9ecef", padding: "1rem 2rem" }}>
              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Beginndatum</Form.Label>
                  <div>
                    <DatePicker
                      weekLabel='KW'
                      showWeekNumbers
                      locale='de'
                      selected={this.beginDate ? new Date(this.beginDate) : new Date()}
                      onChange={(date) => (this.beginDate = date)}
                      dateFormat='d. MMMM yyyy'
                      className='form-control text-center'
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                  <Form.Label>Fertigstellungsdatum</Form.Label>
                  <div>
                    <DatePicker
                      weekLabel='KW'
                      showWeekNumbers
                      locale='de'
                      dateFormat='d. MMMM yyyy'
                      className='form-control text-center'
                      disabled
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col xs={4} style={{ alignSelf: "center" }}>
                <div>
                  <Form.Check
                    readOnly
                    type='checkbox'
                    checked={orderData.reparaturauftrag.abgeschlossen}
                    label='Auftrag abgeschlossen?'
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Button
                disabled={this.signatureData || orderData.status.statuscode === 0 || !orderData.zustaendig}
                style={{ marginBottom: 10 }}
                variant='primary'
                type='submit'
                onClick={this.addRepairEntry}
              >
                {`Neuer Eintrag  `}
                <span>
                  <FontAwesomeIcon color='white' icon='plus' />
                </span>
              </Button>
              {(orderData.status.statuscode === 0 || !orderData.zustaendig) && (
                <Form.Text style={{ margin: 10 }} className='text-muted'>
                  Auftrag annehmen und Zuständigen eintragen für Bearbeitung
                </Form.Text>
              )}
              {this.repairItems && this.repairItems.length > 0 && (
                <OrderRepairTable data={this.repairItems} openRepairDetailrow={(row) => this.openFileClick(row)} />
              )}
              <h6 style={{ marginBottom: 10, textDecoration: "none" }}>
                Gesamte Kosten: <span style={{ textDecoration: "underline" }}>{currencyFormatDE(totalCosts)}</span>
              </h6>
            </Row>
            {(orderData.status.statuscode !== 0 || orderData.zustaendig) && (
              <Row>
                <Col xs={12} sm={6}>
                  <h5 className='text-center'>
                    {`Anhänge `}

                    <label className='custom-file-upload'>
                      <input type='file' multiple onChange={this.addAttachment} />
                      <i
                        className='fa fa-plus-circle'
                        style={{
                          color: "#007bff",
                          textDecoration: "none",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                      />
                    </label>
                  </h5>
                  <hr />
                  {this.attachments.length > 0 && (
                    <FileList
                      files={this.attachments.map((v) => ({
                        name: v.name,
                        link: process.env.PUBLIC_URL + v.linkToArchive,
                      }))}
                      iconSize='2x'
                    />
                  )}
                </Col>
                <Col xs={12} sm={6}>
                  <h5 className='text-center'>Unterschrift</h5>
                  <hr />
                  <Form.Group>
                    {this.signatureText && (
                      <Form.Control disabled type='text' placeholder='Name' value={this.signatureText} />
                    )}
                  </Form.Group>
                  {this.signatureData && this.signatureData.linkToArchive && (
                    <img
                      width='200'
                      height='100'
                      src={process.env.PUBLIC_URL + this.signatureData.linkToArchive}
                      alt={this.signatureData.name}
                    />
                  )}
                  <Button
                    disabled={this.signatureData && this.signatureData.linkToArchive}
                    variant='primary'
                    style={{ float: "right" }}
                    onClick={() => (this.showSignatureModal = true)}
                  >
                    Unterschreiben
                  </Button>
                </Col>
              </Row>
            )}
            <Row>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/* this.repairItems && this.repairItems.length > 0 && (
                  <Button
                    disabled={this.signatureData}
                    variant="primary"
                    style={{ marginTop: 10 }}
                    onClick={this.updateRepairJobs}
                  >
                    Reparaturauftrag speichern
                  </Button>
                ) */}
              </Col>
            </Row>
          </Container>
        </Accordion>
        <SignatureModal
          title='Unterschrift'
          signatureData={this.signatureData}
          signatureImage={this.signatureImage}
          signatureText={this.signatureText}
          show={this.showSignatureModal}
          onHide={() => (this.showSignatureModal = false)}
          onSuccess={(image, name) => this.getSignatureData(image, name)}
        />
        {this.repairItems && this.showDetailModal && (
          <RepairDetailModal
            show={this.showDetailModal}
            title='Arbeitseintrag'
            item={this.selectedRepairEntry}
            onSuccess={(selectedItem) => this.updateRow(selectedItem)}
            onDelete={(rowId) => this.deleteRow(rowId)}
            reparaturauftrag={orderData.reparaturauftrag}
            onHide={() => (this.showDetailModal = false)}
            currentItemsCount={this.repairItems.length}
          />
        )}
        <Toast show={this.showToast} onHide={() => (this.showToast = false)} status={this.toastStatus} />
      </div>
    );
  }
}
export default OrderRepairComponent;
