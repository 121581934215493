/* eslint-disable no-debugger */
import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { observable } from "mobx";
import Modal from "./Modal";
import style from "./Modal.module.scss";
import LoadingSpinnerContainer from "../LoadingSpinner/LoadingSpinnerContainer";

@inject("fileStore")
@inject("authStore")
@observer
class SendFileModal extends React.Component {
  static propTypes = {
    authStore: PropTypes.any.isRequired,
    fileStore: PropTypes.any.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    files: PropTypes.any.isRequired,
  };

  static defaultProps = {
    title: "Loading...",
  };

  @observable emailAddresses = [""];

  @observable notificationText = "";

  @observable loading = false;

  hide = () => {
    const { onHide } = this.props;
    onHide();
  };

  sendEmailNotification = () => {
    const { fileStore, files, onSuccess } = this.props;
    this.loading = true;
    fileStore.sendUploadLink(files.fileid, this.emailAddresses, this.notificationText).then(() => {
      this.loading = false;
      fileStore.loadUploadFiles();
      onSuccess();
    });
  };

  componentDidUpdate(nextProps) {
    const { files, authStore } = this.props;
    if (nextProps.files !== files) {
      this.notificationText = `${authStore.companyDetails.firmen_bzg1} will mit Ihnen teilen: \n ${
        files.filename
      }: ${files.downloadLink.replace(/ /g, "")}`;
    }
  }

  handleInputChange = (index, event) => {
    this.emailAddresses[index] = event.target.value;
  };

  addEmailAddress = () => {
    this.emailAddresses.push("");
  };

  removeEmailAddress = () => {
    this.emailAddresses.pop();
  };

  render() {
    const { show, title, files } = this.props;

    const body = (
      <LoadingSpinnerContainer loading={this.loading}>
        {() => (
          <div>
            {this.emailAddresses.map((address, index) => (
              <Form.Group key={index} controlId='Email'>
                <Form.Control
                  type='email'
                  placeholder='Email Addresse angeben'
                  value={address}
                  onChange={(event) => this.handleInputChange(index, event)}
                />
              </Form.Group>
            ))}
            <Button className='m-2' style={{ width: "40px" }} onClick={this.addEmailAddress}>
              <strong>+</strong>
            </Button>
            <Button className='' style={{ width: "40px" }} onClick={this.removeEmailAddress}>
              <strong>-</strong>
            </Button>

            {files.sharedWithEmails && <p className='text-left'>Bereits gesendet an: </p>}
            <ListGroup>
              {files.sharedWithEmails &&
                files.sharedWithEmails.toJS().map((file, index) => (
                  <ListGroupItem key={index} className={style.emailList}>
                    {file}
                  </ListGroupItem>
                ))}
            </ListGroup>
            <Form.Group className='pt-2' controlId='msg-text'>
              <Form.Control
                as='textarea'
                rows='6'
                value={this.notificationText}
                onChange={(e) => (this.notificationText = e.target.value)}
              />
            </Form.Group>
          </div>
        )}
      </LoadingSpinnerContainer>
    );
    const footer = (
      <Button disabled={this.loading} variant='success' block onClick={this.sendEmailNotification}>
        Link versenden
      </Button>
    );
    return (
      <div>
        <Modal show={show} onHide={this.hide} title={title} body={body} footer={footer} />
      </div>
    );
  }
}

export default SendFileModal;
