/* eslint-disable no-debugger */
import React from "react";
import { PropTypes } from "prop-types";
import { inject, observer } from "mobx-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { observable } from "mobx";
import FileDropzone from "../components/FileDropzone/FileDropzone";
import Header from "../components/Header/Header";
import LoadingModal from "../components/Modal/LoadingModal";
import Toast from "../components/Toast/Toast";
import LoadingSpinnerContainer from "../components/LoadingSpinner/LoadingSpinnerContainer";
import FileUploadTable from "../components/FileUploadTable";
import SendFileModal from "../components/Modal/SendFileModal";
import UploadFileModal from "../components/Modal/UploadFileModal";

@inject("fileStore")
@inject("authStore")
@observer
class FileUploadPage extends React.Component {
  static propTypes = {
    fileStore: PropTypes.any.isRequired,
  };

  @observable uploading = false;

  @observable uploadProgress = 0;

  @observable showFirmListModal = false;

  @observable showToast = false;

  @observable toastStatus = {};

  @observable isLoadingError = false;

  @observable files = [];

  @observable currentFiles = [];

  @observable fileNames = [];

  @observable showUploadFileModal = false;

  constructor(props) {
    super(props);
    const { fileStore } = props;
    this.isLoadingError = false;
    fileStore.loadUploadFiles();

    if (!fileStore.filesToShare) fileStore.loadUploadFiles();
  }

  sendNotificationsDialog = (files) => {
    this.files = files;
    this.showFirmListModal = true;
  };

  hideFirmModal = (value) => {
    const { fileStore } = this.props;
    this.fileNames = [];
    if (value.sendData) {
      this.showToast = true;
      this.toastStatus = {
        body: "Datei wurde gesendet!",
        title: "Erfolg",
        type: "success",
      };
    }
    this.showFirmListModal = false;
    this.showUploadFileModal = false;
    fileStore.loadUploadFiles();
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  hideToast = () => {
    this.showToast = false;
  };

  uploadFiles = (files) => {
    if (files.length > 0) {
      const { fileStore } = this.props;

      this.uploading = true;
      this.uploadProgress = 0;

      files.map((file) => this.fileNames.push(file.name));
      Promise.all(
        files.map((file) => fileStore.uploadFileWithoutObject(file, (progress) => (this.uploadProgress = progress)))
      )
        .then((res) => {
          const date = new Date().setMonth(new Date().getMonth() + 1);
          this.currentFiles =
            res &&
            res.map((id) => ({
              fileid: id,
              expiry: new Date(date).toDateString(),
            }));
          fileStore.loadUploadFiles().then(() => {
            this.uploading = false;
            this.showUploadFileModal = true;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  render() {
    const { fileStore } = this.props;

    const files = fileStore.filesToShare;

    return (
      <div>
        <Header />
        <Container>
          <Row className='pt-4'>
            <Col>
              <h1 className='text-center'>File Upload</h1>
            </Col>
          </Row>
        </Container>
        <LoadingSpinnerContainer loading={this.isLoadingError}>
          {() => (
            <Container>
              <Row className=''>
                <Col>
                  <h3 className='text-center text-break'>Dateien hochladen und versenden</h3>
                </Col>
              </Row>
              <Row>
                <Col className='pb-3' style={{ height: "650px", paddingTop: "46px" }}>
                  <FileDropzone onDrop={this.uploadFiles} iconSize='5x' />
                  <LoadingModal show={this.uploading} title='Uploading...' progress={this.uploadProgress} />
                </Col>
                {files && files.length > 0 && (
                  <Col md='8'>
                    <FileUploadTable data={files.toJS()} openFilesDialog={this.sendNotificationsDialog} />
                  </Col>
                )}
              </Row>
              <Row>
                <SendFileModal
                  show={this.showFirmListModal}
                  title='File Transfer'
                  onHide={() => this.hideFirmModal({ sendData: false })}
                  onSuccess={() => this.hideFirmModal({ sendData: true })}
                  files={this.files}
                />
              </Row>
              <Row>
                {this.showUploadFileModal === true && (
                  <UploadFileModal
                    show={this.showUploadFileModal === true}
                    title='File Transfer'
                    onHide={() => this.hideFirmModal({ sendData: false })}
                    onSuccess={() => this.hideFirmModal({ sendData: true })}
                    files={this.currentFiles.toJS()}
                  />
                )}
              </Row>
              <Toast show={this.showToast} onHide={this.hideToast} status={this.toastStatus} />
            </Container>
          )}
        </LoadingSpinnerContainer>
      </div>
    );
  }
}

export default FileUploadPage;
