/* eslint-disable react/destructuring-assignment */
import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Card, Form, Button } from "react-bootstrap";
import { observable } from "mobx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

@inject("authStore")
@observer
class ResetPasswordPage extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    authStore: PropTypes.any.isRequired,
    match: PropTypes.any.isRequired,
  };

  @observable email = "";

  @observable newPassword = "";

  @observable confirmNewPassword = "";

  @observable error = null;

  @observable success = false;

  redirectTimeout = 5000;

  onSubmit = (e) => {
    e.preventDefault();
    const { authStore, history, match } = this.props;

    if (!this.email) {
      this.error = "Bitte geben Sie ihren Benutzernamen an!";
      return;
    }

    if (this.newPassword !== this.confirmNewPassword) {
      this.error = "Passwörter stimmen nicht überein!";
      return;
    }
    const token = match.params.token ? match.params.token : null;

    authStore
      .resetPassword(this.email.trim(), this.newPassword, token)
      .then(() => {
        this.success = true;
        setTimeout(() => {
          history.replace("/login");
        }, this.redirectTimeout);
      })
      .catch(() => (this.error = "Passwortlink abgelaufen"));
  };

  handleEmailChange = (e) => (this.email = e.target.value);

  handleNewPasswordChange = (e) => (this.newPassword = e.target.value);

  handleConfirmNewPasswordChange = (e) => (this.confirmNewPassword = e.target.value);

  render() {
    return (
      <div style={{ paddingTop: "200px" }}>
        <Container>
          <Row className='py-3 d-flex justify-content-center'>
            <Col lg={6} md={6} xs={12}>
              <Card>
                <Card.Body>
                  <div className='text-center'>
                    <h3>
                      <i className={`fa fa-${!this.success ? "lock" : "check"} fa-4x`} />
                    </h3>
                    <h2 className='text-center'>
                      {!this.success ? "Passwort neu festlegen" : "Passwort wurde geändert!"}
                    </h2>
                    {this.success && (
                      <p>
                        {`Sie werden in 
                        ${this.redirectTimeout / 1000} 
                        Sekunden zum Login weitergeleitet!`}
                      </p>
                    )}
                  </div>
                  {!this.success && (
                    <div>
                      <Form onSubmit={this.onSubmit}>
                        <Form.Group>
                          <Form.Control
                            type='text'
                            placeholder='Username'
                            name='email'
                            value={this.email}
                            onChange={this.handleEmailChange}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            type='password'
                            placeholder='Neues Passwort'
                            name='email'
                            value={this.newPassword}
                            onChange={this.handleNewPasswordChange}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Control
                            type='password'
                            placeholder='Neues Passwort bestätigen'
                            name='email'
                            value={this.confirmNewPassword}
                            onChange={this.handleConfirmNewPasswordChange}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Button type='submit' variant='primary' block>
                            Neues Passwort festlegen
                          </Button>
                        </Form.Group>
                      </Form>
                      {this.error && <small className='text-danger'>{this.error}</small>}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(ResetPasswordPage);
