import { Configuration, AuthenticationApiFactory } from "wowis-rest-typescript-client";
import authStore from "../stores/AuthStore";

export const useAuthApi = () => {
  const config = new Configuration();
  config.basePath = process.env.REACT_APP_WOWIS_API_URL;

  if (authStore.accessToken) {
    config.apiKey = `Bearer ${authStore.accessToken}`;
  }
  return AuthenticationApiFactory(config);
};
