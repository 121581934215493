import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Link from "react-router-dom/Link";
import Navbar from "./Navbar";
import styles from "./Header.module.scss";
import { getLogoForClient } from "../../utils";
import theme from "../../theme";

function Header() {
  const config = theme[process.env.REACT_APP_CLIENT] ? theme[process.env.REACT_APP_CLIENT] : "wbg";

  const logo = getLogoForClient()[process.env.REACT_APP_CLIENT];

  return (
    <>
      <Container>
        <Row className={`${styles.titleRow} align-items-center`}>
          <Col lg='auto' className='h-75'>
            <Link to='/orders'>
              <img src={logo} className='h-100' alt='Wohnbaugruppe' />
            </Link>
          </Col>
          <Col className='d-none d-lg-block'>
            <div className='float-right'>{config.title}</div>
          </Col>
        </Row>
      </Container>
      <Navbar />
    </>
  );
}

export default Header;
