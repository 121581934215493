/* eslint-disable no-debugger */
const truncateEnd = (str, maxLength) => {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`;
  }
  return str;
};

const truncateMiddle = (str, maxLength) => {
  if (str.length > maxLength) {
    return `${str.substring(0, Math.floor(maxLength / 2))}...${str.substring(
      str.length - Math.floor(maxLength / 2),
      str.length,
    )}`;
  }
  return str;
};

const truncateFileName = (str, maxLength) => {
  const ext = str.split('.').pop();
  if (str.length - ext.length > maxLength) {
    return `${str.substring(0, maxLength)}....${ext}`;
  }
  return str;
};

const getFontAwesomeIconFromMIME = (mimeType) => {
  const icon_classes = {
    // Media
    image: 'file-image',
    audio: 'file-audio',
    video: 'file-video',
    // Documents
    'application/pdf': 'file-pdf',
    'application/msword': 'file-word',
    'application/vnd.ms-word': 'file-word',
    'application/vnd.oasis.opendocument.text': 'file-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml':
      'file-word',
    'application/vnd.ms-excel': 'file-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml': 'file-excel',
    'application/vnd.oasis.opendocument.spreadsheet': 'file-excel',
    'application/vnd.ms-powerpoint': 'file-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml':
      'file-powerpoint',
    'application/vnd.oasis.opendocument.presentation': 'file-powerpoint',
    'text/plain': 'file-alt',
    'text/html': 'file-code',
    'application/json': 'file-code',
    // Archives
    'application/gzip': 'file-archive',
    'application/zip': 'file-archive',
    'application/acad': 'file-archive',
    'application/x-step	': 'file-archive',
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const key in icon_classes) {
    // eslint-disable-next-line no-prototype-builtins
    if (icon_classes.hasOwnProperty(key)) {
      if (mimeType.search(key) === 0) {
        // Found it
        return icon_classes[key];
      }
    } else {
      return 'fa-file';
    }
  }
};

export {
  truncateEnd,
  truncateMiddle,
  truncateFileName,
  getFontAwesomeIconFromMIME,
};
