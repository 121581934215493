import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import styles from "./DataTable.module.scss";

class DataTableColumnToggle extends React.Component {
  saveDropdown = (col) => {
    const { onColumnToggle } = this.props;
    let items = localStorage.getItem("dropDownFields");

    if (!items) items = [];
    else {
      items = JSON.parse(items);
    }

    const exists = items.includes(col.dataField);
    if (!exists) {
      items.push(col.dataField);
    } else {
      items = items.filter((item) => item !== col.dataField);
    }

    localStorage.setItem("dropDownFields", JSON.stringify(items));
    onColumnToggle(col.dataField);
  };

  render() {
    const { columns, toggles } = this.props;

    Object.keys(toggles).forEach((v) => (toggles[v] = true));

    let items = localStorage.getItem("dropDownFields");
    if (items) {
      items = JSON.parse(items);
      items.forEach((item) => {
        toggles[item] = false;
      });
    }

    return (
      <Dropdown className={styles.hideColumns} alignRight id='dataTableColumnToggle'>
        <Dropdown.Toggle>Spalten</Dropdown.Toggle>
        <Dropdown.Menu>
          {columns.map((col) => (
            <Dropdown.Item key={col.text} as='button' onClick={() => this.saveDropdown(col)}>
              <Form.Check readOnly type='checkbox' label={col.text} checked={toggles[col.dataField]} />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

DataTableColumnToggle.propTypes = {
  columns: PropTypes.array.isRequired,
  onColumnToggle: PropTypes.func.isRequired,
  toggles: PropTypes.object.isRequired,
};

export default DataTableColumnToggle;
