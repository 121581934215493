import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Modal from "./Modal";
import style from "./Modal.module.scss";
import "moment/locale/de";

@inject("dataStore")
@inject("authStore")
@observer
class DownloadFirmModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    authStore: PropTypes.any.isRequired,
    title: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    firms: PropTypes.any,
    companies: PropTypes.any.isRequired,
    readFirms: PropTypes.any,
  };

  static defaultProps = {
    title: "Loading...",
    firms: [],
    readFirms: null,
  };

  hide = () => {
    const { onHide } = this.props;
    onHide();
  };

  render() {
    const { show, title, firms, companies, readFirms, authStore } = this.props;

    const companiesArray = [];

    moment.locale("de");

    const combinedFirms = firms.map((t1) => ({
      ...t1,
      ...readFirms.find((t2) => t2.firmInfo.firmen_id === t1.firmId),
    }));

    if (companies && combinedFirms) {
      const cArray = companies.filter((company) => authStore.companyDetails.firmen_bzg1 !== company.firmen_bzg1);

      cArray.forEach((company) => {
        combinedFirms.forEach((firm) => {
          if (company.firmen_id === firm.firmId) {
            companiesArray.push({
              company: company.firmen_bzg1,
              downloadTime: firm.downloadTime,
              id: company.firmen_id,
              acknowledgedTime: firm.acknowledgedTime,
            });
          }
        });
      });
    }

    const body = (
      <div>
        <Row>
          <Col>
            <div className='scroller'>
              {companiesArray.length > 0 ? (
                <Table className={style.listScroll}>
                  <thead style={{ background: "#c6c8ca" }}>
                    <tr>
                      <th>Firma</th>
                      <th>Heruntergeladen</th>
                      <th>Gelesen</th>
                    </tr>
                  </thead>
                  <tbody style={{ background: "#c6c8ca" }}>
                    {companiesArray.map((obj, key) => (
                      <tr key={key}>
                        <td>{obj.company}</td>
                        <td>{obj.downloadTime ? moment(obj.downloadTime).format("DD.MM.YYYY, H:mm") : ""}</td>
                        <td>
                          {obj.acknowledgedTime
                            ? moment(obj.acknowledgedTime).format("DD.MM.YYYY, H:mm")
                            : obj.downloadTime
                            ? moment(obj.downloadTime).format("DD.MM.YYYY, H:mm")
                            : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div>Keine Downloads Bisher</div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
    const footer = <div />;

    return (
      <div>
        <Modal widthClass='big' show={show} onHide={this.hide} title={title} body={body} footer={footer} />
      </div>
    );
  }
}

export default DownloadFirmModal;
