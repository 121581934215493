import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react/index";
import { DataTable } from "./DataTable/DataTable";

@observer
class ObjectsTable extends Component {
  handleRowClick = (e, row) => {
    const { history } = this.props;
    history.push(`/objects/${row.obj_id}/files`);
  };

  render() {
    const { data } = this.props;

    const defaultSorted = [
      {
        dataField: "adr.city",
        order: "desc",
      },
    ];

    const columns = [
      {
        dataField: "obj_nr",
        text: "Nr.",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "obj_bzg",
        text: "Bezeichnung",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "adr.street",
        text: "Straße",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "adr.city",
        text: "Stadt",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "adr.plz",
        text: "PLZ",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
    ];

    return (
      <DataTable
        data={data}
        columns={columns}
        keyField='obj_id'
        defaultSorted={defaultSorted}
        onRowClick={this.handleRowClick}
      />
    );
  }
}
ObjectsTable.propTypes = {
  data: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};
export default withRouter(ObjectsTable);
