import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker/es';
import { observable } from 'mobx';
import Modal from './Modal';


@observer
class ConfirmationModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onYes: PropTypes.func.isRequired,
    onNo: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string,
    yesText: PropTypes.string,
    noText: PropTypes.string,
    header: PropTypes.string,
    showDate: PropTypes.bool,
    beginDate: PropTypes.any,
    beginRealDate: PropTypes.any,
  };

  static defaultProps = {
    title: 'Are you sure?',
    yesText: 'Yes',
    noText: 'No',
    header: 'Tasächlicher Beginn',
    showDate: false,
    beginDate: new Date(Date.now()),
    beginRealDate: new Date(Date.now()),
  };

  @observable beginDate = new Date(Date.now() - 60000);

  render() {
    const {
      show, onYes, onNo, onHide, title, yesText, noText, header, showDate, beginDate,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        title={title}
        body={(
          <Container>
            <Row>
              {showDate && (
                <Col xs={12} lg={12} md={12}>
                  <Form.Group>
                    <Form.Label>{header}</Form.Label>
                    <div>
                      <DatePicker
                        showWeekNumbers
                        weekLabel="KW"
                        locale="de"
                        selected={this.beginDate}
                        onChange={date => (this.beginDate = date)}
                        {...beginDate && (header === 'Tatsächliche Fertigstellung' ? {} : {}
                        )}
                        showTimeSelect
                        dateFormat="d. MMMM yyyy HH:mm"
                        timeFormat="HH:mm"
                        className="form-control text-center"
                      />
                    </div>
                  </Form.Group>
                </Col>
              )}
              <Col xs={6}>
                <Button variant="primary" size="lg" block onClick={() => onYes(this.beginDate)}>
                  {yesText}
                </Button>
              </Col>
              <Col xs={6}>
                <Button variant="secondary" size="lg" block onClick={onNo}>
                  {noText}
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      />
    );
  }
}

export default ConfirmationModal;
