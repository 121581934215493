import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react/index";
import { observable } from "mobx";
import { Table } from "react-bootstrap";
import moment from "moment";
import FileList from "./FileList/FileList";
import { currencyFormatDE } from "../utils";

@observer
class OrderRepairTable extends Component {
  static propTypes = {
    data: PropTypes.any.isRequired,
    openRepairDetailrow: PropTypes.func.isRequired,
  };

  handleRowClick = (e) => {
    if (!e.target.className.includes("text-muted")) {
      const { data, openRepairDetailrow } = this.props;

      openRepairDetailrow(data[e.target.id]);
    }
  };

  currencyFormatDE = (num) =>
    `€ ${num
      .toFixed(2) // always two decimal digits
      .replace(".", ",") // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`; // use . as a separator

  render() {
    const { data } = this.props;
    const nanValue = NaN;
    const totalCosts = data.reduce((total, cost) => total + Number(cost.kosten) - Number(cost.materialaufwand ?? 0), 0);
    const totalMaterialCosts = data.reduce((total, cost) => total + parseInt(cost.materialaufwand ?? 0, 10), 0);
    return (
      <Table striped bordered hover>
        <thead>
          <tr style={{ color: "white", background: "#ea2210" }}>
            <th>#</th>
            <th>geleistete Arbeit</th>
            <th>MA</th>
            <th>Stunden</th>
            <th>Mitarbeiter</th>
            <th>Kosten Arbeit</th>
            <th>Datum</th>
            <th>Dateien</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr style={{ cursor: "pointer" }} key={item.id} id={index} onClick={this.handleRowClick}>
                <td id={index}>{item.id ? item.id : ""}</td>
                <td style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: 110 }} id={index}>
                  {item.leistung ? `${item.leistung}` : ""}
                </td>
                <td id={index}>{item.materialaufwand ? this.currencyFormatDE(item.materialaufwand) : ""}</td>
                <td id={index}>
                  {item.repstunden ? `${item.repstunden.reduce((a, b) => +a + +b.stunden, 0)} Stunden` : ""}
                </td>
                <td id={index}>{item.repstunden ? `${item.repstunden.map((a) => a.name).join("\n")}` : ""}</td>
                <td id={index}>{item.kosten ? this.currencyFormatDE(item.kosten - item.materialaufwand ?? 0) : ""}</td>
                <td id={index}>{item.ausfuehrungsdatum ? moment(item.ausfuehrungsdatum).format("DD.MM.YYYY") : ""}</td>
                <td id={index}>
                  <FileList
                    files={
                      item &&
                      item.anhang &&
                      item.anhang.map((v) => ({
                        name: v.name,
                        link: process.env.PUBLIC_URL + v.linkToArchive,
                      }))
                    }
                    iconSize='1x'
                    fontSize='11px'
                  />
                </td>
              </tr>
            ))}
          {totalCosts && totalCosts !== nanValue && data.length > 1 && (
            <tr>
              <td>∑ </td>
              <td />
              <td>{this.currencyFormatDE(totalMaterialCosts)}</td>
              <td />
              <td />
              <td>{this.currencyFormatDE(totalCosts)}</td>
              <td />
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}

export default OrderRepairTable;
