import React from 'react';
import PropTypes from 'prop-types';
import styles from './Timeline.module.scss';

class Timeline extends React.Component {
  static propTypes = {
    markers: PropTypes.array.isRequired,
    activeMarker: PropTypes.number,
    onActiveMarkerClick: PropTypes.func,
    spacing: PropTypes.number,
    paddingX: PropTypes.number,
    paddingY: PropTypes.number,
    markerRadius: PropTypes.number,
    fontSize: PropTypes.number,
    strokeWidth: PropTypes.number,
    title: PropTypes.string,
  };

  static defaultProps = {
    activeMarker: 0,
    onActiveMarkerClick: null,
    spacing: 100,
    paddingX: 100,
    paddingY: 50,
    markerRadius: 6,
    fontSize: 16,
    strokeWidth: 2,
    title: 'Timeline',
  };

  render() {
    const {
      markers,
      activeMarker,
      onActiveMarkerClick,
      spacing,
      paddingX,
      paddingY,
      markerRadius,
      fontSize,
      strokeWidth,
      title,
    } = this.props;

    const w = (markers.length - 1) * spacing + 2 * paddingX;
    const h = markerRadius * 2 + fontSize * 2 + paddingY * 2;

    return (
      <svg
        style={{ width: '100%', height: h }}
        aria-labelledby="timeline-title"
        viewBox={`0 0 ${w} ${h}`}
      >
        <title id="timeline-title">{title}</title>
        <line
          x1={paddingX}
          y1={h - paddingY}
          x2={paddingX + (markers.length - 1) * spacing}
          y2={h - paddingY}
          stroke="grey"
          strokeWidth={strokeWidth}
        />

        <line
          x1={paddingX}
          y1={h - paddingY}
          x2={
            paddingX
            + Math.min(markers.length - 1, Math.max(0, activeMarker)) * spacing
          }
          y2={h - paddingY}
          stroke="green"
          strokeWidth={strokeWidth}
        />

        {markers.map((e, i) => (
          <g key={i}>
            <circle
              cx={paddingX + i * spacing}
              cy={h - paddingY}
              r={i === activeMarker ? markerRadius + strokeWidth * 2 : 0}
              stroke="grey"
              strokeWidth={strokeWidth}
              fill="white"
              onClick={
                i === activeMarker && onActiveMarkerClick
                  ? onActiveMarkerClick
                  : () => {}
              }
              className={`${styles.marker} ${
                i === activeMarker && onActiveMarkerClick ? styles.active : ''
              }`}
            />
            <circle
              cx={paddingX + i * spacing}
              cy={h - paddingY}
              r={markerRadius}
              stroke={i <= activeMarker ? 'green' : 'grey'}
              strokeWidth={strokeWidth}
              fill={i <= activeMarker ? 'green' : 'white'}
              onClick={
                i === activeMarker && onActiveMarkerClick
                  ? onActiveMarkerClick
                  : () => {}
              }
              className={`${styles.marker} ${
                i === activeMarker && onActiveMarkerClick ? styles.active : ''
              }`}
            />
            <text
              x={paddingX + i * spacing}
              y={h - paddingY - fontSize}
              fontSize={fontSize}
              fontWeight={i === activeMarker ? 'bold' : 'normal'}
              textAnchor="middle"
            >
              {e}
            </text>
          </g>
        ))}
      </svg>
    );
  }
}

export default Timeline;
