/* eslint-disable react/display-name */
import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";

// WARNING: The breakpoint variable will only be correct AFTER the first
// rendering. This is an implication of the chosen implementation of this
// HOC.

const withBreakpoint = (WrappedComponent) =>
  (
    @observer
    class extends React.Component {
      @observable breakpoint = "md";

      constructor(props) {
        super(props);
        window.addEventListener("resize", this.handleResize);
      }

      isBreakpoint = (size) => {
        const e = document.getElementsByClassName(`d-${size}-none`)[0];
        if (e) {
          const computedStyle = window.getComputedStyle(e).getPropertyValue("display");
          return computedStyle === "none";
        }
        return false;
      };

      handleResize = () => {
        const breakpoints = ["xs", "sm", "md", "lg", "xl"];
        for (let i = 0; i < breakpoints.length; i += 1) {
          if (this.isBreakpoint(breakpoints[i])) {
            this.breakpoint = breakpoints[i];
            return;
          }
        }
      };

      componentDidMount() {
        this.handleResize();
      }

      componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
      }

      render() {
        return (
          <div>
            <div className='d-none d-xs-none d-sm-block' />
            <div className='d-sm-none d-md-block' />
            <div className='d-md-none d-lg-block' />
            <div className='d-lg-none d-xl-block' />
            <div className='d-xl-none' />
            <WrappedComponent breakpoint={this.breakpoint} {...this.props} />
          </div>
        );
      }
    }
  );

export default withBreakpoint;
