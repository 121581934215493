/* eslint-disable react/no-unused-prop-types */
import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { observable } from "mobx";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker/es";

import LoadingSpinnerContainer from "../LoadingSpinner/LoadingSpinnerContainer";
import Modal from "./Modal";
import LogTable from "../LogTable";

@inject("dataStore")
@inject("authStore")
@observer
class ShareLogModal extends React.Component {
  static propTypes = {
    dataStore: PropTypes.any.isRequired,
    authStore: PropTypes.any.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    files: PropTypes.any.isRequired,
    companies: PropTypes.any.isRequired,
    onHide: PropTypes.func.isRequired,
    objId: PropTypes.string.isRequired,
    fileFilterId: PropTypes.number,
  };

  static defaultProps = {
    title: "Loading...",
    fileFilterId: 0,
  };

  @observable fromDate = new Date("June 6, 2022 03:24:00");

  @observable toDate = new Date();

  @observable loading = false;

  @observable combinedLog = null;

  hide = () => {
    const { onHide } = this.props;
    onHide();
  };

  getCompanyNameById = (companyId) => {
    const { companies } = this.props;
    const company = companies.filter((comp) => companyId === comp.firmen_id)[0];
    if (company) {
      return company.firmen_bzg1;
    }
  };

  getAllLogData = async () => {
    const { files, fileFilterId } = this.props;

    this.loading = true;
    const { dataStore, objId } = this.props;
    let res = await dataStore.getLogData(this.fromDate.toISOString(), this.toDate.toISOString(), objId);

    if (fileFilterId !== 0) {
      res = res.filter((entry) => entry.fileId === fileFilterId);
    }

    this.combinedLog =
      res &&
      res.map((entry) => {
        let companies = [];
        if (entry.firmIds.length > 0) {
          companies = entry.firmIds.map((id) => this.getCompanyNameById(id));
        }

        const { fileId } = entry;
        const file = files.find((file_) => file_.id === fileId);

        let sender = 0;
        if (entry.granterFirmIds.length > 0) sender = this.getCompanyNameById(entry.granterFirmIds[0]);

        const text = companies.length === 0 ? `alle Firmen die Berechtigung entzogen` : ` freigegeben`;
        this.loading = false;

        return {
          timestamp: entry.timestamp,
          sender: `${sender}`,
          companies: companies
            .map((company) => company)
            .join(",\n ")
            .replace(/,\s*$/, ""),
          filename: file.name,
          text: `${text}.`,
        };
      });
  };

  render() {
    const body = (
      <div>
        <h5 style={{ paddingBottom: 20 }}>
          Es kann einige Minuten dauern, bis kürzlich verteilte Dateien im Log aufscheinen.
        </h5>

        <Row>
          <Col xs={4}>
            <Form.Group>
              <Form.Label style={{ margin: 10 }}>Von</Form.Label>
              <DatePicker
                onChange={(date) => (this.fromDate = date)}
                weekLabel='KW'
                showWeekNumbers
                selected={this.fromDate}
                locale='de'
                defaultValue={new Date(2021)}
                dateFormat='d. MMMM yyyy'
                timeFormat='HH:mm'
                className='form-control text-center'
              />
              <div />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <Form.Label style={{ margin: 10 }}>Bis</Form.Label>
              <DatePicker
                onChange={(date) => (this.toDate = date)}
                weekLabel='KW'
                selected={this.toDate}
                showWeekNumbers
                locale='de'
                defaultValue={new Date()}
                dateFormat='d. MMMM yyyy'
                timeFormat='HH:mm'
                className='form-control text-center'
              />
              <div />
            </Form.Group>
          </Col>
          <Col xs={2}>
            <Button onClick={this.getAllLogData}>Ausführen</Button>
          </Col>
        </Row>
        <hr />
        <Col>
          {this.combinedLog && (
            <div className='parentTable'>
              <LogTable data={this.combinedLog} />
            </div>
          )}
        </Col>
      </div>
    );
    const footer = (
      <LoadingSpinnerContainer loading={this.loading}>
        {() => (
          <Button variant='success' block onClick={this.hide}>
            Schließen
          </Button>
        )}
      </LoadingSpinnerContainer>
    );
    const { show, title } = this.props;

    return (
      <div>
        <Modal show={show} onHide={this.hide} title={title} body={body} footer={footer} widthClass='full' />
      </div>
    );
  }
}

export default ShareLogModal;
