/* eslint-disable react/no-danger,react/require-default-props,no-debugger */
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import {
  Button, Container, Row, Col, Form,
} from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import styles from './OrderComments.module.css';
import FileDropzone from '../FileDropzone/FileDropzone';
import FileList from '../FileList/FileList';
import LoadingSpinnerContainer from '../LoadingSpinner/LoadingSpinnerContainer';

@inject('dataStore')
@observer
class OrderCommentEditor extends Component {
  static propTypes = {
    dataStore: PropTypes.any.isRequired,
  };

  @observable editorState = '';

  @observable loading = false;

  @observable attachments = [];

  @observable characters = 0;

  onSaveComment = () => {
    this.loading = true;
    const { dataStore } = this.props;
    const res = dataStore.addOrderComment(
      dataStore.orderDetails.auftr_id,
      new Date(),
      this.editorState,
      this.attachments,
    );
    res.then(() => {
      this.editorState = '';
      this.attachments = [];
      this.loading = false;
    });
  };

  addFileToComment = (files) => {
    files.forEach((file) => {
      this.attachments.push(file);
    });
  };

  onChangeText = (event) => {
    this.editorState = event.target.value;
    this.characters = event.target.value.length;
  };

  render() {
    return (
      <Container fluid>
        <LoadingSpinnerContainer loading={this.loading}>
          {() => (
            <div>
              <Row>
                <Col xs={12} sm={9} className="pr-sm-1">
                  <Form.Control
                    onChange={this.onChangeText}
                    value={this.editorState}
                    as="textarea"
                    maxLength="250"
                    rows="6"
                    placeholder="Neues Kommentar posten"
                  />
                  <span className="form-text text-muted mt-1 float-right mb-0">
                    {this.characters}
                    /250
                  </span>
                </Col>
                <Col xs={12} sm={3} className="pl-sm-1 pt-2 pt-sm-0">
                  <FileDropzone onDrop={this.addFileToComment} iconSize="3x" />
                </Col>
              </Row>
              <Row className="pt-3">
                <Col xs="auto">
                  <Button
                    variant="danger"
                    size="sm"
                    className={`${styles.saveButton}`}
                    onClick={this.onSaveComment}
                    disabled={
                      this.editorState === '' && this.attachments.length === 0
                    }
                  >
                    Kommentar speichern
                  </Button>
                </Col>
                <Col>
                  {this.attachments && (
                    <FileList
                      files={this.attachments.map(v => ({
                        name: v.name,
                      }))}
                    />
                  )}
                </Col>
              </Row>
            </div>
          )}
        </LoadingSpinnerContainer>
      </Container>
    );
  }
}
export default OrderCommentEditor;
