import React from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FileList extends React.Component {
  static propTypes = {
    files: PropTypes.array,
    iconSize: PropTypes.string,
    fontSize: PropTypes.string,
  };

  static defaultProps = {
    files: null,
    iconSize: "1x",
    fontSize: "12x",
  };

  getIconType = (v) => {
    if (
      v.name.endsWith(".jpg") ||
      v.name.endsWith(".jpeg") ||
      v.name.endsWith(".png") ||
      v.name.endsWith(".PNG") ||
      v.name.endsWith(".tif")
    ) {
      return "file-image";
    }
    if (v.name.endsWith(".pdf")) {
      return "file-pdf";
    }
    return "file-pdf";
  };

  render() {
    const { files, iconSize, fontSize } = this.props;
    if (!files || files.length === 0) {
      return null;
    }
    return (
      <Row className='justify-content-center'>
        {files.map((v, i) => (
          <Col xs='auto' key={i} className='text-center p-1'>
            <OverlayTrigger placement='top' overlay={<Tooltip>{v.name}</Tooltip>}>
              <a href={v.link} target='_blank' rel='noopener noreferrer' download={v.name} className='text-reset'>
                <FontAwesomeIcon icon={this.getIconType(v)} size={iconSize} />
                <div className='text-truncate text-muted' style={{ fontSize, maxInlineSize: "50px" }}>
                  {v.name}
                </div>
              </a>
            </OverlayTrigger>
          </Col>
        ))}
      </Row>
    );
  }
}

export default FileList;
