/* eslint-disable class-methods-use-this,no-debugger,no-restricted-globals */
import { observable, action, runInAction } from "mobx";
import ApiService from "../services/ApiService";

class FileStore {
  @observable filesToShare = [];

  @action async loadUploadFiles() {
    try {
      const res = await ApiService.getUploadFilesPerCompany();
      runInAction(() => {
        this.filesToShare = res.data;
      });
    } catch (e) {
      throw new Error(e);
    }
  }

  @action async deleteUploadFile(file_id) {
    await ApiService.deleteUploadFile(file_id).catch((error) => {
      throw new Error((error.response && error.response.data) || error);
    });
    const res = await ApiService.getUploadFilesPerCompany();
    runInAction(() => {
      this.filesToShare = res.data;
    });
  }

  @action async uploadFileWithoutObject(file, onProgress) {
    const res = await ApiService.uploadFileWithoutObject(file, onProgress);
    return res.data.fileid;
  }

  @action async setFileProperties(file_id, expiryDate) {
    try {
      const res = await ApiService.setFileProperties(file_id, expiryDate);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  @action async sendUploadLink(file_id, emailAdresses, body) {
    try {
      const res = await ApiService.sendCompanyEmailNotification(file_id, emailAdresses, body);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  @action async sendEmailNotification(companies, man_id, obj_nr, obj_id, message) {
    try {
      const res = await ApiService.sendEmailNotification(companies, man_id, obj_nr, obj_id, message);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  downloadDocument(res) {
    const a = document.createElement("a");

    const blob = new Blob([res.data], {
      type: res.headers["content-type"],
    });

    if (window.navigator && window.navigator.msSaveBlob) {
      // Internet Explorer workaround
      window.navigator.msSaveBlob(blob, name);
    } else {
      a.href = window.URL.createObjectURL(blob);
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(a.href);
    }
    return res;
  }

  @action async downloadUploadFile(file_id, onProgress) {
    const res = await ApiService.downloadUploadFile(file_id, onProgress).catch(async (error) => {
      if (error.response) {
        const text = await new Response(error.response.data).text();
        throw new Error(text);
      } else {
        throw new Error(error);
      }
    });
    return this.downloadDocument(res);
  }
}

export default new FileStore();
