import ReactDOM from "react-dom";
import React from "react";
import { Provider } from "mobx-react";
import "./custom-bootstrap.scss";
import App from "./pages/App";
import * as serviceWorker from "./serviceWorker";

import authStore from "./stores/AuthStore";
import fileStore from "./stores/FileStore";
import dataStore from "./stores/DataStore";

// import { configureFakeBackend } from "./helpers/fake-backend";

const stores = {
  authStore,
  dataStore,
  fileStore,
};

// configureFakeBackend();

ReactDOM.render(
  <Provider {...stores}>
    <App />
  </Provider>,
  document.getElementById("root")
);
