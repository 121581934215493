import React from "react";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function IconButton({ icon, color, onClick, className }) {
  return (
    <Button variant='link' size='sm' className={className} style={{ lineHeight: "1" }} onClick={onClick}>
      <FontAwesomeIcon color={color} icon={icon} />
    </Button>
  );
}

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  color: "white",
  className: "",
};

export default IconButton;
