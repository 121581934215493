const toISODate = (date) => {
  if (date) {
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${date.getFullYear()}-${month}-${day}`;
  }
  return null;
};

const toDateString = (date) => {
  if (date) {
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${day}.${month}.${date.getFullYear()}`;
  }
  return null;
};

const toTimeString = (date, withSeconds) => {
  if (date) {
    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const seconds = `0${date.getSeconds()}`.slice(-2);
    if (withSeconds) {
      return `${hours}:${minutes}:${seconds}`;
    }
    return `${hours}:${minutes}`;
  }
  return null;
};

const toString = (date, withSeconds) => {
  if (date) {
    return `${toDateString(date)} ${toTimeString(date, withSeconds)}`;
  }
  return null;
};

export {
  toDateString, toTimeString, toString, toISODate,
};
