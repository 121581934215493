/* eslint-disable react/destructuring-assignment */
import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import { observable } from "mobx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

@inject("authStore")
@observer
class RequestPasswordResetPage extends React.Component {
  static propTypes = {
    authStore: PropTypes.any.isRequired,
  };

  @observable email = "";

  @observable emailInput = "";

  @observable loading = false;

  @observable requested = false;

  onSubmit = (e) => {
    e.preventDefault();
    this.loading = true;
    this.email = this.emailInput;
    const { authStore } = this.props;

    const baseUrl = window.location.origin;

    authStore.sendPasswordResetLink(this.email, baseUrl).then(() => {
      this.requested = true;
      this.loading = false;
    });
  };

  handleEmailChange = (e) => (this.emailInput = e.target.value);

  render() {
    return (
      <div style={{ paddingTop: "200px" }}>
        <Container>
          <Row className='py-3 d-flex justify-content-center'>
            <Col lg={4} md={6} xs={12}>
              <Card>
                <Card.Body>
                  <div className='text-center'>
                    <h3>
                      <i className={`fa ${!this.requested ? "fa-lock" : "fa-envelope"} fa-4x`} />
                    </h3>
                    <h2 className='text-center'>
                      {!this.requested ? "Passwort vergessen?" : "Prüfen Sie Ihre Email!"}
                    </h2>
                    <p>
                      {!this.requested ? (
                        "Hier können Sie Ihr Passwort zurücksetzen"
                      ) : (
                        <div>
                          Eine Email, mit der Sie Ihr Passwort zurücksetzen können, wurde soeben an{" "}
                          <code>{this.email}</code> versandt! Der Link in dieser Mail ist für 15 Minuten gültig.
                        </div>
                      )}
                    </p>
                  </div>

                  <Form onSubmit={this.onSubmit}>
                    <Form.Group>
                      <Form.Control
                        type='text'
                        placeholder='Email'
                        name='email'
                        value={this.emailInput}
                        onChange={this.handleEmailChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Button type='submit' variant='primary' block disabled={this.loading}>
                        {`Rücksetz-Email ${this.requested ? "erneut " : " "}
                        anfordern `}
                        {this.loading && (
                          <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        )}
                      </Button>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(RequestPasswordResetPage);
