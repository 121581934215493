/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "./Modal";
import styles from "./Modal.module.scss";
import LoadingSpinnerContainer from "../LoadingSpinner/LoadingSpinnerContainer";
import theme from "../../theme";

@inject("dataStore")
@observer
class UploadInvoiceModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    data: PropTypes.any.isRequired,
    invoicedata: PropTypes.any.isRequired,
    dataStore: PropTypes.any.isRequired,
    orderId: PropTypes.any.isRequired,
    rechnungsType: PropTypes.any.isRequired,
    invoiceIsPdf: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  @observable metaData = {};

  @observable fileUploaded = false;

  @observable loading = false;

  @observable error = "";

  componentWillMount() {
    this.reset();
  }

  reset = () => {
    this.metaData = observable.object({
      group: 0,
      re_nr: "",
      dok_eingangsdat: new Date(),
      umsatz: 0,
      steuer: 0,
    });
  };

  upload = () => {
    const {
      // eslint-disable-next-line no-unused-vars
      dataStore,
      data,
      orderId,
      invoicedata,
    } = this.props;
    if (!this.metaData.group || this.metaData.group === 0 || this.metaData.group === "0") {
      this.metaData.group = 0;
    }
    this.error = "";

    if (invoicedata) {
      Promise.all(
        invoicedata.map((invoice) => {
          if (invoice.re_nr === this.metaData.re_nr) {
            this.error = "Fehler: Rechnungsnummer wurde schon verwendet";
          }
          return this.error;
        })
      ).then((res) => {
        console.log(res);
      });
    }
    if (!moment(this.metaData.dok_eingangsdat).isValid()) {
      this.error = "Fehler: Datum muss ausgefüllt werden";
    }

    const year = moment(this.metaData.dok_eingangsdat).year();
    if (year < 2020) {
      this.error = "Fehler: Aktuelles Jahr verwenden";
    }

    if (this.error === "") {
      // eslint-disable-next-line no-restricted-globals
      this.metaData.umsatz = isNaN(this.metaData.umsatz) || !this.metaData.umsatz ? 0 : this.metaData.umsatz;
      // eslint-disable-next-line no-restricted-globals
      this.metaData.steuer = isNaN(this.metaData.steuer) || !this.metaData.steuer ? 0 : this.metaData.steuer;

      this.loading = true;
      dataStore
        .uploadInvoice(data.toJS()[0], this.metaData, orderId)
        .then(() => {
          this.fileUploaded = true;
          this.hide();
          this.error = "";
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  hide = () => {
    const { onHide, onSuccess } = this.props;
    this.reset();
    if (this.fileUploaded) {
      this.fileUploaded = false;
      onSuccess();
      this.loading = false;
    } else {
      onHide();
      this.loading = false;
    }
  };

  handleChange = (e) => {
    this.metaData.group = e.target.value;
  };

  render() {
    const { show, rechnungsType, data, invoiceIsPdf } = this.props;

    const validated = false;

    const body = (
      <LoadingSpinnerContainer loading={this.loading}>
        {() => (
          <Form validated={validated}>
            <Form.Group className={styles.invoiceText}>
              <p>
                Bitte eventuelle Beilagen (zB Lieferscheine, Nachweise) mit der Rechnung gemeinsam in einer PDF-Datei
                hochladen (Rechnung an erster Stelle)
              </p>
              {show && (
                <Form.Label>
                  Rechnungs-Datei: <div className={styles.invoice}>{data[0].name}</div>
                </Form.Label>
              )}
            </Form.Group>
            {process.env.REACT_APP_CLIENT !== "ebg" && (
              <Form.Group>
                <Form.Label>Gruppe</Form.Label>

                <Form.Control required as='select' onChange={this.handleChange}>
                  <option value={0}>Bitte Gruppe auswählen</option>
                  {rechnungsType &&
                    rechnungsType.map((d) => (
                      <option key={d.dokgr_bzg} value={d.dokgr_id}>
                        {d.dokgr_bzg}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}
            {invoiceIsPdf && process.env.REACT_APP_CLIENT !== "ebg" && (
              <div>
                <Form.Group>
                  <Form.Label>Rechnungsnummer</Form.Label>
                  <Form.Control
                    as='input'
                    value={this.metaData.re_nr}
                    onChange={(e) => (this.metaData.re_nr = e.target.value)}
                    maxLength='35'
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Rechnungsdatum*</Form.Label>
                  <div>
                    <DatePicker
                      locale='de'
                      selected={this.metaData.dok_eingangsdat}
                      onChange={(date) => (this.metaData.dok_eingangsdat = date)}
                      dateFormat='d. MMMM yyyy'
                      className='form-control text-center'
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Umsatz (brutto)</Form.Label>
                  <Form.Control
                    as='input'
                    type='number'
                    value={this.metaData.umsatz}
                    onChange={(e) => (this.metaData.umsatz = e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Steuerbetrag</Form.Label>
                  <Form.Control
                    as='input'
                    type='number'
                    value={this.metaData.steuer}
                    onChange={(e) => (this.metaData.steuer = e.target.value)}
                  />
                </Form.Group>
              </div>
            )}
            {this.error !== "" && <p className='text-danger'>{this.error}</p>}
          </Form>
        )}
      </LoadingSpinnerContainer>
    );

    const footer = (
      <Button disabled={this.loading} variant='success' block onClick={this.upload}>
        Hochladen
      </Button>
    );

    return <Modal show={show} onHide={this.hide} title='Rechnungsdetails' body={body} footer={footer} />;
  }
}

export default UploadInvoiceModal;
