import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Accordion from "../Accordion/Accordion";
import style from "./DataCard.module.css";

@observer
class DataCard extends React.Component {
  render() {
    const { title, data, footer, collapsable } = this.props;
    return (
      <div className='text-center'>
        <Accordion
          title={title}
          titleBorderRadius='4px 4px 0 0'
          initialExpanded
          {...(!collapsable && { expanded: true })}
          disabled={!collapsable}
        >
          <Container fluid className={`${style.content} py-3`}>
            <Row>
              <Col>
                {Object.keys(data).map((key, index) => {
                  if (data[key]) {
                    return (
                      <div key={index}>
                        <h5 className={style.headerText}>{key}</h5>
                        <p className={style.text}>{data[key]}</p>
                      </div>
                    );
                  }
                  return null;
                })}
              </Col>
            </Row>
            {footer && (
              <Row>
                <Col>{footer}</Col>
              </Row>
            )}
          </Container>
        </Accordion>
      </div>
    );
  }
}

DataCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  footer: PropTypes.any,
  collapsable: PropTypes.bool,
};

DataCard.defaultProps = {
  footer: null,
  collapsable: false,
};

export default DataCard;
