import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import { observable } from "mobx";
import styles from "./DataTable.module.scss";

class StatusColumnToggle extends React.Component {
  @observable statusToggles = null;

  saveDropdown = (col) => {
    const { onChangeStatusFilter } = this.props;
    let items = localStorage.getItem("statusFields");

    if (!items) items = [];
    else {
      items = JSON.parse(items);
    }

    const exists = items.includes(col);
    if (!exists) {
      items.push(col);
    } else {
      items = items.filter((item) => item !== col);
    }

    localStorage.setItem("statusFields", JSON.stringify(items));
    onChangeStatusFilter(col);
  };

  componentDidUpdate() {
    const { toggles } = this.props;

    Object.keys(toggles).forEach((v) => (toggles[v] = true));

    let items = localStorage.getItem("statusFields");
    if (items) {
      items = JSON.parse(items);
      items.forEach((item) => {
        if (item) {
          toggles[item] = false;
        }
      });
    }
    this.statusToggles = toggles;
  }

  componentDidMount() {
    const { toggles } = this.props;

    Object.keys(toggles).forEach((v) => (toggles[v] = true));

    let items = localStorage.getItem("statusFields");
    if (items) {
      items = JSON.parse(items);
      items.forEach((item) => {
        if (item) {
          toggles[item] = false;
        }
      });
    }
    this.statusToggles = toggles;
  }

  render() {
    return (
      <Dropdown className={styles.hideColumns} alignRight id='dataTableColumnToggle'>
        <Dropdown.Toggle>Status</Dropdown.Toggle>
        <Dropdown.Menu>
          {this.statusToggles &&
            Object.entries(this.statusToggles).map((obj) => (
              <Dropdown.Item key={obj[0]} as='button' onClick={() => this.saveDropdown(obj[0])}>
                <Form.Check readOnly type='checkbox' label={obj[0]} checked={obj[1]} />
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
StatusColumnToggle.propTypes = {
  onChangeStatusFilter: PropTypes.func.isRequired,
  toggles: PropTypes.object.isRequired,
};

export default StatusColumnToggle;
