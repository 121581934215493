import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import withBreakpoint from "../withBreakpoint/withBreakpoint";
import SelectFirmModal from "../Modal/SelectFirmModal";

@inject("dataStore")
@observer
class DataTablePagination extends React.Component {
  static propTypes = {
    dataStore: PropTypes.any.isRequired,
    paginationProps: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSizePerPageChange: PropTypes.func.isRequired,
    breakpoint: PropTypes.string.isRequired,
    selectedFiles: PropTypes.any,
    obj_id: PropTypes.string,
    companies: PropTypes.any,
  };

  static defaultProps = {
    selectedFiles: [],
    obj_id: "0",
    companies: [],
  };

  @observable cursor = 0;

  @observable showFirmListModal = false;

  componentWillMount() {
    const {
      paginationProps: { page },
    } = this.props;
    this.cursor = page;
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate() {
    const {
      paginationProps: { page },
    } = this.props;
    this.cursor = page;
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  hideFirmModal = (value) => {
    this.showFirmListModal = false;
  };

  handleKeyDown = (e) => {
    const { paginationProps } = this.props;
    if (e.key === "ArrowLeft") {
      this.showPrevPage(paginationProps);
    } else if (e.key === "ArrowRight") {
      this.showNextPage(paginationProps);
    }
  };

  changePage = (page) => {
    const {
      onPageChange: onPageChangeProp,
      paginationProps: { onPageChange },
    } = this.props;
    this.cursor = page;
    onPageChangeProp(page);
    onPageChange(page);
  };

  changeSizePerPage = (sizePerPage, page) => {
    const {
      onSizePerPageChange: onSizePerPageChangeProp,
      paginationProps: { onSizePerPageChange },
    } = this.props;
    onSizePerPageChangeProp(sizePerPage);
    onSizePerPageChange(sizePerPage);
    this.changePage(page);
    localStorage.setItem("paginationSize", sizePerPage);
  };

  showNextPage = ({ page, totalSize, sizePerPage, pageStartIndex }) => {
    this.changePage(Math.min(Math.floor(pageStartIndex + (totalSize - 1) / sizePerPage), page + 1));
  };

  showPrevPage = ({ page, pageStartIndex }) => {
    this.changePage(Math.max(pageStartIndex, page - 1));
  };

  downloadSelected = () => {
    const { selectedFiles, dataStore, obj_id } = this.props;
    dataStore.downloadAllFiles(selectedFiles, obj_id);
  };

  showFileModal = () => {
    this.showFirmListModal = true;
  };

  renderPaginationItem = (page, active) => (
    <Pagination.Item key={page} active={active} as='button' onClick={() => this.changePage(page)}>
      {page}
    </Pagination.Item>
  );

  renderPaginationPages = (neighbourCount, { page, pageStartIndex, totalSize, sizePerPage }) => {
    const pageEndIndex = Math.floor(pageStartIndex + (totalSize - 1) / sizePerPage);
    const pageCountBeforeCurrentPage = Math.max(0, this.cursor - pageStartIndex);
    const pageCountAfterCurrentPage = Math.min(pageEndIndex - this.cursor);
    const pageRenderStartIndex = Math.max(
      pageStartIndex,
      this.cursor - neighbourCount - Math.max(0, neighbourCount - pageCountAfterCurrentPage)
    );
    const pageRenderEndIndex = Math.min(
      pageEndIndex,
      this.cursor + neighbourCount + Math.max(0, neighbourCount - pageCountBeforeCurrentPage)
    );
    const pages = [];
    if (pageRenderStartIndex > pageStartIndex) {
      pages.push(this.renderPaginationItem(pageStartIndex, false));
      if (pageRenderStartIndex > pageStartIndex + 2) {
        pages.push(
          <Pagination.Ellipsis
            key={-1}
            onClick={() => (this.cursor = Math.max(pageStartIndex, pageRenderStartIndex - neighbourCount - 1))}
          />
        );
      } else if (pageRenderStartIndex > pageStartIndex + 1) {
        pages.push(this.renderPaginationItem(pageRenderStartIndex - 1, false));
      }
    }
    for (let i = pageRenderStartIndex; i <= pageRenderEndIndex; i += 1) {
      pages.push(this.renderPaginationItem(i, i === page));
    }

    if (pageRenderEndIndex < pageEndIndex) {
      if (pageRenderEndIndex < pageEndIndex - 2) {
        pages.push(
          <Pagination.Ellipsis
            key={-2}
            onClick={() => (this.cursor = Math.min(pageEndIndex, pageRenderEndIndex + neighbourCount + 1))}
          />
        );
      } else if (pageRenderEndIndex < pageEndIndex - 1) {
        pages.push(this.renderPaginationItem(pageEndIndex - 1, false));
      }
      pages.push(this.renderPaginationItem(pageEndIndex, false));
    }

    return pages;
  };

  renderPaginationSizeDropdown = ({ page, sizePerPage, totalSize, pageStartIndex }) => {
    const sizes = [5, 10, 25, 50, 100, totalSize];

    const currentSize = sizes.includes(sizePerPage) ? sizePerPage : totalSize;

    const items = [];
    for (let i = 0; i < sizes.length; i += 1) {
      const lastPageIndex = Math.floor(pageStartIndex + (totalSize - 1) / sizes[i]);
      const clampedPage = Math.min(lastPageIndex, Math.max(pageStartIndex, page));
      if (sizes[i] !== sizePerPage) {
        items.push(
          <Dropdown.Item key={i} as='button' onClick={() => this.changeSizePerPage(sizes[i], clampedPage)}>
            {i !== 5 ? sizes[i] : `Alle (${sizes[i]})`}
          </Dropdown.Item>
        );
      }
    }

    return (
      <Dropdown>
        <Dropdown.Toggle variant='secondary'>{currentSize}</Dropdown.Toggle>
        <Dropdown.Menu>{items}</Dropdown.Menu>
      </Dropdown>
    );
  };

  render() {
    const { paginationProps, breakpoint, selectedFiles, obj_id, companies } = this.props;

    return (
      <Row className='mb-6'>
        <Col xs='auto'>{this.renderPaginationSizeDropdown(paginationProps)}</Col>
        <Col className='d-flex justify-content-end justify-content-sm-center'>
          <Pagination>
            <Pagination.Prev onClick={() => this.showPrevPage(paginationProps)} />
            {breakpoint === "xs" || breakpoint === "sm"
              ? this.renderPaginationItem(paginationProps.page, true)
              : this.renderPaginationPages(1, paginationProps)}
            <Pagination.Next onClick={() => this.showNextPage(paginationProps)} />
          </Pagination>
        </Col>
        {selectedFiles && selectedFiles.length > 0 && (
          <div>
            <Col className='d-none d-sm-block m-2'>
              <Button onClick={this.showFileModal} style={{ margin: "5px" }}>
                Senden
              </Button>
              <Button onClick={this.downloadSelected}>Download</Button>
            </Col>
          </div>
        )}
        {companies && (
          <SelectFirmModal
            show={this.showFirmListModal}
            title='Mehrere Firmen Benachrichtigungen'
            companies={companies}
            objId={obj_id}
            files={selectedFiles}
            onHide={() => this.hideFirmModal({ sendData: false })}
            onSuccess={() => this.hideFirmModal({ sendData: true })}
          />
        )}
      </Row>
    );
  }
}

export default withBreakpoint(DataTablePagination);
