import React from "react";
import { PropTypes } from "prop-types";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import style from "./FileDropzone.module.css";

library.add(faFileUpload);

class FileDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.textWrapper = React.createRef();
  }

  handleDrop = (files) => {
    const { onDrop, allowedExtensions } = this.props;
    const filteredFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toUpperCase();
      return (
        (allowedExtensions && allowedExtensions.map((v) => v.toUpperCase()).includes(extension)) ||
        !allowedExtensions ||
        allowedExtensions.length === 0
      );
    });
    onDrop(filteredFiles);
  };

  render() {
    const { text, iconSize, allowedExtensions } = this.props;

    const acceptedExtensions =
      allowedExtensions && allowedExtensions.length > 0 ? allowedExtensions.map((ext) => `.${ext}`).join(",") : "*";

    return (
      <Dropzone onDrop={this.handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: `${style.root}`,
            })}
          >
            <input
              {...getInputProps({
                accept: acceptedExtensions,
              })}
            />
            <Container className={style.inner}>
              <Row>
                <Col className='text-center'>
                  <FontAwesomeIcon icon='file-upload' size={iconSize} />
                </Col>
              </Row>
              <Row className='pt-1'>
                <Col className='text-center' ref={this.textWrapper}>
                  {text}
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Dropzone>
    );
  }
}

FileDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  text: PropTypes.string,
  iconSize: PropTypes.string,
  allowedExtensions: PropTypes.array,
};

FileDropzone.defaultProps = {
  text: "Dateien uploaden",
  iconSize: "3x",
  allowedExtensions: null,
};

export default FileDropzone;
