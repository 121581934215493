/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroup, Dropdown, Form, ListGroup, Tab, Tabs } from "react-bootstrap";
import { observable } from "mobx";
import Modal from "./Modal";
import style from "./Modal.module.scss";

@inject("dataStore")
@observer
class FavoriteModal extends React.Component {
  @observable favoriteGroups = [];

  componentDidMount() {
    const { dataStore, objId } = this.props;
    if (objId) {
      dataStore.getFavoriteGroups(objId).then((res) => {
        this.favoriteGroups = res;
      });
    }
  }

  hide = () => {
    const { onHide } = this.props;
    onHide();
  };

  getCompanyNameById = (companyId) => {
    const { companies } = this.props;
    const company = companies.filter((comp) => companyId === comp.firmen_id)[0];
    if (company) {
      return company.firmen_bzg1;
    }
  };

  addToFavorites = async (groupId, obj) => {
    const { dataStore, objId } = this.props;
    await dataStore.addFirmToFavoriteGroup(groupId, objId, obj.firmen_id);
    const entry = this.favoriteGroups.filter((item) => item.id === groupId)[0];
    entry.firmIds.push(obj.firmen_id);
  };

  addToGroups = async () => {
    const { dataStore, objId } = this.props;
    const newGroup = await dataStore.addFavoriteGroup(this.newGroupEntry, objId);
    newGroup.firmIds = [];
    if (newGroup) this.favoriteGroups.push(newGroup);
    this.newGroupEntry = "";
  };

  removeFromGroup = (groupId, firmId) => {
    const { dataStore, objId } = this.props;
    dataStore.deleteFirmPerFavoriteGroup(groupId, objId, firmId);

    const entry = this.favoriteGroups.filter((item) => item.id === groupId)[0];
    if (entry) {
      entry.firmIds.splice(
        entry.firmIds.findIndex((i) => i.firmen_id === firmId),
        1
      );
    }
  };

  removeGroup = (groupId) => {
    const { dataStore, objId } = this.props;
    dataStore.deleteFavoriteGroup(groupId, objId);

    this.favoriteGroups.splice(
      this.favoriteGroups.findIndex((i) => i.id === groupId),
      1
    );
  };

  render() {
    const { show, title, companies } = this.props;

    const body = (
      <div className='scroller'>
        <Tabs defaultActiveKey='all' id='company-tab' className='mb-3'>
          <Tab eventKey='all' title='Alle Firmen'>
            <ListGroup className={style.listScroll}>
              {companies &&
                companies.map((obj, key) => (
                  <div className={`${style.listview} list-group-item list-group-item-action`} key={key}>
                    <Form.Label
                      id={`check-${key}`}
                      label={obj.firmen_bzg1}
                      className={style.background}
                      style={{ width: "90%", display: "inline-flex", userSelect: "none", justifyContent: "center" }}
                    >
                      {obj.firmen_bzg1}
                    </Form.Label>
                    <Dropdown align='end' as={ButtonGroup}>
                      <Dropdown.Toggle id='dropdown-custom-1' />
                      <Dropdown.Menu>
                        {this.favoriteGroups &&
                          this.favoriteGroups.map((group) => (
                            <Dropdown.Item key={group.id} onClick={() => this.addToFavorites(group.id, obj)}>
                              {group.groupName}
                            </Dropdown.Item>
                          ))}
                        {this.favoriteGroups && this.favoriteGroups.length === 0 ? (
                          <Dropdown.Item>Keine Favoriten bisher. Erstelle Liste!</Dropdown.Item>
                        ) : (
                          ""
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ))}
            </ListGroup>
          </Tab>
          <Tab eventKey='favorites' title='Favoriten'>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Neue Favoritengruppe hinzufügen</Form.Label>
              <Form.Control
                type='text'
                value={this.newGroupEntry}
                onChange={(e) => (this.newGroupEntry = e.target.value)}
                style={{ width: "75%", marginRight: "15px", display: "inline-flex", userSelect: "none" }}
              />
              <Button variant='success' style={{ width: "10%", marginBottom: "5px" }} onClick={this.addToGroups}>
                +
              </Button>
            </Form.Group>

            <ListGroup className={style.listScroll}>
              {this.favoriteGroups &&
                this.favoriteGroups.map((group) => (
                  <div key={group.id}>
                    <h5
                      style={{
                        textAlign: "left",
                        padding: "10px",
                        background: "#ea2210",
                      }}
                    >
                      {group.groupName}
                      <span>
                        <FontAwesomeIcon
                          onClick={() => this.removeGroup(group.id)}
                          style={{ width: "10%", float: "right", cursor: "pointer" }}
                          icon='minus'
                          size='1x'
                        />
                      </span>
                    </h5>
                    {group.firmIds.map((firmId) => (
                      <div className={`${style.listview} list-group-item list-group-item-action`} key={firmId}>
                        <Form.Label
                          id={`check-${firmId}`}
                          className={style.background}
                          style={{ width: "90%", display: "inline-flex", userSelect: "none", justifyContent: "center" }}
                        >
                          {this.getCompanyNameById(firmId)}
                        </Form.Label>
                        <FontAwesomeIcon
                          onClick={() => this.removeFromGroup(group.id, firmId)}
                          style={{ width: "10%" }}
                          icon='trash-alt'
                          color='red'
                          size='1x'
                        />
                      </div>
                    ))}
                  </div>
                ))}
            </ListGroup>
          </Tab>
        </Tabs>
      </div>
    );
    const footer = (
      <Button variant='success' block onClick={this.hide}>
        Speichern
      </Button>
    );

    return (
      <div>
        <Modal show={show} onHide={this.hide} title={title} body={body} footer={footer} />
      </div>
    );
  }
}

FavoriteModal.propTypes = {
  dataStore: PropTypes.any.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  companies: PropTypes.any,
  objId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

FavoriteModal.defaultProps = {
  title: "Loading...",
  companies: [],
};

export default FavoriteModal;
