import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { observable } from "mobx";
import Toast from "../Toast/Toast";
import Modal from "./Modal";
import SignaturePad from "../SignaturePad/SignaturePad";

@observer
class SignatureModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    signatureText: PropTypes.string.isRequired,
    signatureData: PropTypes.object.isRequired,
    signatureImage: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
  };

  @observable showToast = false;

  @observable toastStatus = {};

  constructor(props) {
    super(props);
    const { signatureText } = props;
    this.sigText = signatureText;
  }

  componentDidUpdate(prevProps) {
    const { signatureText } = this.props;
    if (prevProps.signatureText !== signatureText) {
      this.sigText = signatureText;
    }
  }

  @observable sigText = "";

  getSignatureData = (image) => {
    const { onSuccess } = this.props;

    if (!this.sigText) {
      this.toastStatus = {
        body: "Text darf nicht leer sein!",
        title: "Fehler!",
        type: "error",
      };
      this.showToast = true;
    } else {
      onSuccess(image, this.sigText);
    }
  };

  save = () => {
    const { onHide } = this.props;
    onHide();
  };

  hide = () => {
    const { onHide } = this.props;
    onHide();
  };

  render() {
    const { show, title, signatureData, signatureImage } = this.props;
    const body = (
      <>
        <h5>Unterschrift</h5>
        <Form.Group>
          <Form.Control
            type='text'
            placeholder='Name'
            value={this.sigText}
            contentEditable
            onChange={(e) => (this.sigText = e.target.value)}
          />
        </Form.Group>
        <SignaturePad
          signatureImage={signatureImage}
          signatureData={signatureData}
          onSave={(image, name) => this.getSignatureData(image, name)}
        />
      </>
    );
    const footer = <div />;

    return (
      <div>
        <Modal show={show} onSuccess={this.save} onHide={this.hide} title={title} body={body} footer={footer} />
        <Toast show={this.showToast} onHide={() => (this.showToast = false)} status={this.toastStatus} />
      </div>
    );
  }
}

export default SignatureModal;
