/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/interactive-supports-focus,no-debugger */
import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import PropTypes from "prop-types";
import { observable } from "mobx";
import { observer } from "mobx-react/index";
import FaultDetailsModal from "../FaultDetailsModal";
import { DataTable } from "../DataTable/DataTable";
import IconButton from "../IconButton/IconButton";
import defaultStyle from "../DataTable/DataTable.module.scss";
import style from "./FaultsTable.module.css";

@observer
class FaultsTable extends Component {
  @observable detailsModalShown = false;

  actionsFormatter = (cell, row) => {
    console.log(`${cell} ${row}`);
    return <IconButton onClick={this.showDetailsModal} icon='eye' color='#1f2427' />;
  };

  handleRowSelected = (row, isSelected) => {
    console.log(row);
    console.log(isSelected);
  };

  showDetailsModal = () => {
    this.detailsModalShown = true;
  };

  hideDetailsModal = () => {
    this.detailsModalShown = false;
  };

  render() {
    const { data } = this.props;

    const defaultSorted = [
      {
        dataField: "frist",
        order: "asc",
      },
    ];

    const columns = [
      {
        dataField: "mangelart_kbz",
        text: "Mangelart",
        headerAlign: "center",
        classes: "col-md-auto",
        sort: true,
        align: "center",
      },
      {
        dataField: "feststellung",
        text: "Feststellung",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "behebung",
        text: "Behebung",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "frist",
        text: "Frist",
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      {
        dataField: "text1",
        text: "Text",
        align: "center",
      },
      {
        dataField: "actions",
        text: "Aktionen",
        formatter: this.actionsFormatter,
        align: "center",
      },
    ];

    return (
      <div>
        <DataTable
          data={data}
          columns={columns}
          keyField='mang_id'
          defaultSorted={defaultSorted}
          onRowSelected={this.handleRowSelected}
          search={false}
          pagination={false}
          bordered={false}
          headerClasses={`${defaultStyle.header} ${style.header}`}
        />
        <FaultDetailsModal show={this.detailsModalShown} onHide={this.hideDetailsModal} />
      </div>
    );
  }
}
FaultsTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default FaultsTable;
