/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { observable } from "mobx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toast from "../Toast/Toast";
import planStructure from "../../assets/planstructure";

import LoadingSpinnerContainer from "../LoadingSpinner/LoadingSpinnerContainer";
import style from "./Modal.module.scss";
import Modal from "./Modal";

@inject("dataStore")
@inject("authStore")
@observer
class SelectFirmModal extends React.Component {
  static propTypes = {
    dataStore: PropTypes.any.isRequired,
    authStore: PropTypes.any.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    companies: PropTypes.any.isRequired,
    objId: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    files: PropTypes.any.isRequired,
    readCompanies: PropTypes.any,
    isUploadFiles: PropTypes.any,
  };

  static defaultProps = {
    title: "Loading...",
    readCompanies: [],
    isUploadFiles: false,
  };

  @observable showToast = false;

  @observable notificationText = "";

  @observable notificationSubject = "";

  @observable attachments = [];

  @observable loading = false;

  @observable notificationItems = [];

  @observable notificationGroups = [];

  @observable favoriteGroups = [];

  componentDidMount() {
    const { dataStore, objId } = this.props;
    if (objId && objId !== "0") {
      dataStore.getFavoriteGroups(objId).then((res) => {
        this.favoriteGroups = res;
      });
    }
  }

  hide = () => {
    const { onHide } = this.props;
    onHide();
  };

  getGewerke = (files) => {
    const gewerke = files.map((file) => file.name.split("_").splice(1, 1).join(""));
    const { pgw } = planStructure;
    const allGewerke = Object.keys(pgw)
      .filter((item) => gewerke.includes(item))
      .map((key) => pgw[key]);

    return [...new Set(allGewerke)];
  };

  sendNotifications = (obj, newFiles) => {
    const { dataStore, onSuccess, objId, authStore } = this.props;

    // send Email
    const fileIds = [];
    newFiles.map((file) => fileIds.push(file.id));
    const URL = `${`https://${window.location.hostname}`}/objects/${objId}/zip/${fileIds.join(",")}`;

    const gewerke = this.getGewerke(newFiles);

    const addresses = this.notificationGroups.reduce((acc, current) => [...acc, ...current.firmIds], []);

    const uniqueAdresses = [...new Set([...addresses, ...this.notificationItems])];

    if (uniqueAdresses.length === 0) {
      this.showToast = true;
      return;
    }
    this.loading = true;

    const fileString = newFiles.map((file) => file.name);
    const emailText = ` Neue Datei(en) von : ${authStore.companyDetails.firmen_bzg1}
    \n Planungsgewerk: ${gewerke.map((gew) => `${gew} `)}
    \n ${fileString.join("\n")}\n Hier der Link zu den Plänen : ${URL} \n \n  ${this.notificationText} `;

    dataStore
      .sendEmailNotification(
        uniqueAdresses,
        obj.man_id,
        obj.obj_nr,
        objId,
        emailText,
        this.notificationSubject,
        fileIds,
        this.attachments
      )
      .then(() => {
        Promise.all(
          newFiles.map(async (file) => {
            const companiesWithAccess = await dataStore.getCompaniesWithAccess(objId, file.id);
            const uniqueAdressesMerged = [...new Set([...uniqueAdresses, ...companiesWithAccess])];
            dataStore.saveCompaniesWithAccess(objId, file.id, uniqueAdressesMerged);
          })
        ).then(() => {
          this.loading = false;
          onSuccess();
        });
      });
  };

  sendEmailNotification = () => {
    const { dataStore, objId, files, authStore, isUploadFiles } = this.props;

    const obj = dataStore.getObject(objId);
    if (isUploadFiles) {
      const uniqueAdresses = this.notificationItems.filter((item, pos, self) => self.indexOf(item) === pos);

      if (uniqueAdresses.length === 0) {
        this.showToast = true;
        return;
      }
      this.loading = true;

      this.uploadProgress = 0;
      const date = new Date(Date.now()).toISOString();
      const username = authStore.companyDetails.firmen_bzg1;

      Promise.all(
        files.map((file) =>
          dataStore.addObjectFile(
            objId,
            file.name,
            file,
            username,
            date,
            obj.man_id,
            obj.obj_nr,
            (progress) => (this.uploadProgress = progress)
          )
        )
      )
        .then(() => {
          dataStore.loadObjectFilesById(objId).then((res) => {
            const newFiles = res.data;

            const filteredFiles = [];

            if (newFiles) {
              newFiles.filter((file) => {
                if (new Date(file.updatedAt) > new Date("April 01, 2021")) {
                  filteredFiles.push(file);
                }
                return true;
              });
            }

            const oneIDs = files.map((a) => a.name);
            const result = filteredFiles.filter((a) => oneIDs.indexOf(a.name) !== -1);
            this.sendNotifications(obj, result);
          });
        })
        .catch((error) => {
          this.loading = false;
          this.showToast = true;
          this.toastStatus = {
            body: error.message,
            title: "Upload Fehlgeschlagen!",
            type: "error",
          };
        });
    } else {
      this.loading = true;
      this.sendNotifications(obj, files);
    }
  };

  selectItem = (obj) => {
    const exists = this.notificationItems.includes(obj.firmen_id);

    if (!exists) {
      this.notificationItems.push(obj.firmen_id);
    } else {
      this.notificationItems = this.notificationItems.filter((item) => item !== obj.firmen_id);
    }
  };

  selectGroup = (group) => {
    if (this.notificationGroups.some((e) => e.id === group.id)) {
      this.notificationGroups = this.notificationGroups.filter((item) => item.id !== group.id);
    } else {
      this.notificationGroups.push(group);
    }
  };

  addAttachment = (e) => {
    const { files } = e.target;
    const filesArr = Array.prototype.slice.call(files);
    this.attachments = [...filesArr];
  };

  removeAttachment = (file) => {
    this.attachments = this.attachments.filter((x) => x !== file);
  };

  render() {
    const { show, title, companies, files, readCompanies, authStore } = this.props;
    let companiesArray = companies.filter((company) => authStore.companyDetails.firmen_bzg1 !== company.firmen_bzg1);
    if (readCompanies.length > 0) {
      if (companies && readCompanies) {
        companiesArray = [];
        companies.toJS().forEach((company) => {
          readCompanies.forEach((firm) => {
            if (company.firmen_id === firm) {
              companiesArray.push({
                firmen_bzg1: company.firmen_bzg1,
                firmen_id: company.firmen_id,
              });
            }
          });
        });
      }
    }

    const fileIds = [];
    if (files) files.map((file) => fileIds.push(file.id));
    const body = (
      <div>
        <Container>
          <Row>
            <Col style={{ borderRight: "1px solid white" }}>
              <h5>Ausgewählte Dateien:</h5>
              <ListGroup>
                {files &&
                  files.map((file, id) => (
                    <ListGroup.Item className={style.listview} key={id}>
                      {file.name}
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Col>
            <Col>
              <div style={{ textAlign: "left" }}>
                <h5 style={{ textAlign: "center" }}>Versenden an</h5>
                <Form.Label style={{ fontSize: "16px", fontWeight: "bold" }}>Favoritengruppen</Form.Label>

                <ListGroup className={style.listScroll}>
                  {this.favoriteGroups &&
                    this.favoriteGroups.map((group) => (
                      <div className={`${style.listview} list-group-item list-group-item-action`} key={group.id}>
                        <Form.Check
                          custom
                          type='checkbox'
                          id={`check-${group.id}`}
                          onClick={() => this.selectGroup(group)}
                          label={`${group.groupName}`}
                          className={style.background}
                          style={{ width: "90%", display: "inline-flex", userSelect: "none" }}
                        />
                      </div>
                    ))}
                  <hr style={{ borderTop: "1px solid white" }} />
                  <Form.Label style={{ fontSize: "16px", fontWeight: "bold", textAlign: "left" }}>Firmen</Form.Label>

                  {companiesArray &&
                    companiesArray.map((obj, key) => (
                      <div className={`${style.listview} list-group-item list-group-item-action`} key={obj.firmen_bzg1}>
                        <Form.Check
                          custom
                          type='checkbox'
                          id={`check-${obj.firmen_bzg1}`}
                          onClick={() => this.selectItem(obj)}
                          label={obj.firmen_bzg1}
                          className={style.background}
                          style={{ width: "90%", display: "inline-flex", userSelect: "none" }}
                        />
                      </div>
                    ))}
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Container>
        <Form.Group>
          <Form.Label>Betreff</Form.Label>
          <Form.Control
            type='text'
            placeholder='Betreff'
            value={this.notificationSubject}
            onChange={(e) => (this.notificationSubject = e.target.value)}
          />
        </Form.Group>

        <Form.Group className='pt-2' controlId='msg-text'>
          <Form.Control
            as='textarea'
            placeholder='Nachricht'
            rows='6'
            maxLength='1000'
            value={this.notificationText}
            onChange={(e) => (this.notificationText = e.target.value)}
          />
        </Form.Group>
        <div className={style.attachments}>
          <label className='custom-file-upload'>
            <input type='file' multiple onChange={this.addAttachment} />
            <i className='fa fa-cloud-upload' />
            {` Anhang wählen`}
          </label>
          {this.attachments.map((a, index) => (
            <p key={index} className='file-preview'>
              {` ${a.name} `}
              <FontAwesomeIcon
                onClick={() => this.removeAttachment(a)}
                color='white'
                icon='trash-alt'
                title='Löschen'
              />
            </p>
          ))}
        </div>
      </div>
    );
    const footer = (
      <LoadingSpinnerContainer loading={this.loading}>
        {() => (
          <Button variant='success' block onClick={this.sendEmailNotification}>
            Firmen benachrichtigen
          </Button>
        )}
      </LoadingSpinnerContainer>
    );

    return (
      <div>
        <Modal show={show} onHide={this.hide} title={title} body={body} footer={footer} widthClass='big' />
        <Toast
          show={this.showToast}
          onHide={() => (this.showToast = false)}
          status={{
            body: "Keine Firmen ausgewählt",
            title: "Fehler!",
            type: "error",
          }}
        />
      </div>
    );
  }
}

export default SelectFirmModal;
