/* eslint-disable no-debugger,no-unused-vars */
import React from "react";
import { PropTypes } from "prop-types";
import { inject, observer } from "mobx-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { observable } from "mobx";
import ObjectFilesTable from "../components/ObjectFilesTable";
import Header from "../components/Header/Header";

import LoadingSpinnerContainer from "../components/LoadingSpinner/LoadingSpinnerContainer";

@inject("dataStore")
@inject("authStore")
@observer
class HistoryObjectsPage extends React.Component {
  @observable isLoadingError = false;

  @observable fileNames = [];

  constructor(props) {
    super(props);
    const {
      match: {
        params: { objId },
      },
      dataStore,
    } = props;
    this.isLoadingError = false;
    if (!dataStore.objects) dataStore.loadObjects();
    dataStore
      .loadObjectFiles(objId)
      .then(() => {
        const obj = dataStore.getObject(objId);
        dataStore.getCompaniesPerObject(obj.man_id, obj.obj_nr).then(() => dataStore.loadObjectFiles(objId));
      })
      .catch(() => (this.isLoadingError = true));
  }

  sendNotificationsDialog = (files, readCompanies) => {
    this.fileNames = [files];
  };

  showDownloadedFilesDialog = (items, readItems) => {};

  showCompanyRightsDialogs = (files) => {
    this.fileNames = [files];
    const {
      match: {
        params: { objId },
      },
      dataStore,
    } = this.props;
    dataStore.getCompaniesWithAccess(objId, this.fileNames[0].id).then((res) => {});
  };

  render() {
    const {
      match: {
        params: { objId },
      },
      dataStore,
    } = this.props;

    const obj = dataStore.getObject(objId);
    const files = dataStore.objectFiles.get(objId);
    const newFiles = [];

    if (files) {
      files.filter((file) => {
        if (new Date(file.updatedAt) < new Date("April 01, 2021")) {
          newFiles.push(file);
        }
        return true;
      });
    }

    const companiesPerOrder = dataStore.companies;

    return (
      <div>
        <Header />
        {this.isLoadingError ? (
          <Container>
            <Row className='py-4'>
              <Col>
                <h1 className='text-center'>{`Fileupload Objekt #${objId} kann nicht geladen werden!`}</h1>
              </Col>
            </Row>
          </Container>
        ) : (
          <LoadingSpinnerContainer loading={!obj}>
            {() => (
              <Container>
                <Row className='py-3'>
                  <Col>
                    <h1 className='text-center text-break'>
                      <span className='text-muted'>{` #${obj ? obj.obj_nr : objId}`}</span>
                      <br />
                      <small>{obj && obj.obj_bzg}</small>
                    </h1>
                  </Col>
                </Row>
                <Row>
                  {newFiles && newFiles.length > 0 && companiesPerOrder && companiesPerOrder.length > 0 && (
                    <Col style={{ maxWidth: "100%" }}>
                      <ObjectFilesTable
                        data={newFiles}
                        obj_nr={objId}
                        companies={companiesPerOrder}
                        openFilesDialog={this.sendNotificationsDialog}
                        openFirmsDialog={this.showDownloadedFilesDialog}
                        openRightsDialog={this.showCompanyRightsDialogs}
                      />
                    </Col>
                  )}
                </Row>
              </Container>
            )}
          </LoadingSpinnerContainer>
        )}
      </div>
    );
  }
}

HistoryObjectsPage.propTypes = {
  dataStore: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
};

export default HistoryObjectsPage;
