import React from 'react';
import PropTypes from 'prop-types';
import Timeline from '../Timeline/Timeline';

class StatusTimeline extends React.Component {
  static propTypes = {
    activeStatus: PropTypes.number.isRequired,
    onActiveStatusClick: PropTypes.func,
  };

  static defaultProps = {
    onActiveStatusClick: null,
  };

  render() {
    const { activeStatus, onActiveStatusClick } = this.props;

    const activeMarker = activeStatus === 0 || activeStatus === 1
      ? activeStatus
      : activeStatus > 2
        ? activeStatus - 1
        : 0;

    return (
      <Timeline
        markers={['Offen', 'Angenommen', 'In Arbeit', 'Abgeschlossen']}
        activeMarker={activeMarker}
        onActiveMarkerClick={onActiveStatusClick}
        spacing={200}
        fontSize={22}
        markerRadius={8}
        strokeWidth={3}
        title="Status Timeline"
      />
    );
  }
}

export default StatusTimeline;
