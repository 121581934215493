/* eslint-disable react/destructuring-assignment */
import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Card, Form, Button } from "react-bootstrap";
import { observable } from "mobx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../components/Header/Header";

@inject("authStore")
@observer
class ChangePasswordPage extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    authStore: PropTypes.any.isRequired,
  };

  @observable oldPassword = "";

  @observable newPassword = "";

  @observable confirmNewPassword = "";

  @observable error = null;

  @observable success = false;

  redirectTimeout = 5000;

  handleSubmit = (e) => {
    e.preventDefault();
    const { authStore, history } = this.props;

    this.error = null;

    if (this.newPassword !== this.confirmNewPassword) {
      this.error = "Passwörter stimmen nicht überein!";
      return;
    }

    authStore
      .setNewPassword(this.oldPassword, this.newPassword)
      .then((token) => {
        authStore.setAccessToken(token);
        this.success = true;
        setTimeout(() => history.replace("/"), this.redirectTimeout);
      })
      .catch((error) => (this.error = error.message));
  };

  handleNewPasswordChange = (e) => (this.newPassword = e.target.value);

  handleConfirmNewPasswordChange = (e) => (this.confirmNewPassword = e.target.value);

  render() {
    return (
      <div>
        <Header />
        <div style={{ paddingTop: "100px" }}>
          <Container>
            <Row className='py-3 d-flex justify-content-center'>
              <Col lg={6} md={6} xs={12}>
                <Card>
                  <Card.Body>
                    <div className='text-center'>
                      <h3>
                        <i className={`fa fa-${!this.success ? "lock" : "check"} fa-4x`} />
                      </h3>
                      <h2 className='text-center'>{!this.success ? "Passwort ändern" : "Passwort wurde geändert!"}</h2>
                      {this.success && (
                        <p>
                          {`Sie werden in ${this.redirectTimeout / 1000} Sekunden auf die Homepage weitergeleitet!`}
                        </p>
                      )}
                    </div>
                    {!this.success && (
                      <div>
                        <Form onSubmit={this.handleSubmit}>
                          {/* <Form.Group>
                            <Form.Control
                              type="password"
                              placeholder="Altes Passwort"
                              name="email"
                              value={this.oldPassword}
                              onChange={this.handleOldPasswordChange}
                            />
                          </Form.Group> */}
                          <Form.Group>
                            <Form.Control
                              type='password'
                              placeholder='Neues Passwort'
                              name='email'
                              value={this.newPassword}
                              onChange={this.handleNewPasswordChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Control
                              type='password'
                              placeholder='Neues Passwort bestätigen'
                              name='email'
                              value={this.confirmNewPassword}
                              onChange={this.handleConfirmNewPasswordChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Button type='submit' variant='primary' block>
                              Neues Passwort festlegen
                            </Button>
                          </Form.Group>
                        </Form>
                        {this.error && <small className='text-danger'>{this.error}</small>}
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(ChangePasswordPage);
