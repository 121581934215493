import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { PropTypes } from "prop-types";
import "url-search-params-polyfill";

@inject("authStore")
@observer
class PrivateRoute extends React.Component {
  constructor(props) {
    super();
    const { authStore, location } = props;

    if (location.search !== "") {
      const query = new URLSearchParams(location.search);
      const token = query.get("token");
      authStore.setFirmId(undefined);
      authStore.setAccessToken(undefined);
      authStore.setRefreshToken(token);
      authStore.tokenLogin(token);
    }
  }

  componentDidMount() {}

  render() {
    const { authStore, history, location, ...restProps } = this.props;

    if (authStore.companyBranche !== "undefined" && authStore.companyBranche === "FileSharing") {
      if (location.pathname !== "/fileupload") {
        return <Redirect to='/fileupload' />;
      }
    } else if (authStore.companyBranche !== "undefined" && authStore.companyBranche === "Noshare") {
      if (location.pathname === "/fileupload") {
        return <Redirect to='/error' />;
      }
    }

    if (authStore.refreshToken) {
      return <Route {...restProps} />;
    }

    authStore.setRedirectRouteAfterAuth(history.location.pathname);
    return <Redirect to='/login' />;
  }
}

PrivateRoute.propTypes = {
  authStore: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.any,
};

PrivateRoute.defaultProps = {
  location: null,
};

export default withRouter(PrivateRoute);
