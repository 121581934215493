import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from './Modal';

@observer
class ConfirmationModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
  };

  close = () => {
    const { onHide } = this.props;
    onHide();
  };

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        title='Rechnungsupload'
        body={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Container>
            <Row>
              <Col>Rechnung wurde hochgeladen und wird bearbeitet!</Col>
            </Row>
          </Container>
        }
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Button variant='success' block onClick={this.close}>
            OK
          </Button>
        }
      />
    );
  }
}

export default ConfirmationModal;
