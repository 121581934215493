/* eslint-disable arrow-body-style */
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react/index";
import moment from "moment";
import { DataTable } from "./DataTable/DataTable";
import withBreakpoint from "./withBreakpoint/withBreakpoint";

@observer
class LogTable extends Component {
  dateFormatter = (row) => moment(row).format("DD.MM.YYYY, H:mm");

  render() {
    const { data } = this.props;

    const defaultSorted = [
      {
        dataField: "timestamp",
        order: "desc",
      },
    ];
    const columns = [
      {
        dataField: "timestamp",
        text: "Datum",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: "10px" };
        },
        align: "center",
        formatter: this.dateFormatter,
      },
      {
        dataField: "sender",
        text: "Absender",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: "10px" };
        },
        align: "center",
      },
      {
        dataField: "filename",
        text: "Datei",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: "10px" };
        },
        align: "center",
      },
      {
        dataField: "companies",
        text: "Firmen",
        headerAlign: "center",
        sort: true,
        headerStyle: () => {
          return { width: "10px" };
        },
        align: "center",
      },
    ];

    return (
      <div>
        {data.length > 0 ? (
          <DataTable
            data={data}
            columns={columns}
            keyField='timestamp'
            defaultSorted={defaultSorted}
            onRowClick={this.handleRowClick}
            filterWrapperProps={{
              top: 60,
            }}
          />
        ) : (
          <p>Keine Logs bisher.</p>
        )}
      </div>
    );
  }
}

LogTable.propTypes = {
  data: PropTypes.array.isRequired,
};
export default withBreakpoint(withRouter(LogTable));
