/* eslint-disable react/destructuring-assignment */
import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Card, Form, Button, Jumbotron } from "react-bootstrap";
import { observable } from "mobx";
import Link from "react-router-dom/Link";
import Modal from "react-bootstrap/Modal";
import styles from "../app.module.css";
import theme from "../theme";
import { getLogoForClient } from "../utils";

@inject("authStore")
@observer
class LoginPage extends React.Component {
  @observable username = "";

  @observable password = "";

  @observable isIE = false;

  constructor(props) {
    super(props);

    const { authStore } = this.props;
    if (authStore.accessToken) {
      authStore.setAccessToken(undefined);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { authStore, history } = this.props;
    authStore.login(this.username, this.password).then(() => {
      authStore.fetchCompanyDetails().then((res) => {
        if (res.data.branche && res.data.branche === "FileSharing") {
          history.replace("/fileupload");
        } else {
          history.replace(authStore.redirectRouteAfterAuth);
        }
      });
    });
  };

  componentDidMount() {
    const isInternetExplorer = false || !!document.documentMode;

    this.isIE = isInternetExplorer;
  }

  handleUserChange = (e) => (this.username = e.target.value);

  handlePasswordChange = (e) => (this.password = e.target.value);

  render() {
    const { error, isLoading } = this.props.authStore;

    const config = theme[process.env.REACT_APP_CLIENT];
    const logo = getLogoForClient()[process.env.REACT_APP_CLIENT];

    return (
      <div style={{ width: "470px", paddingTop: "200px" }} className='mx-auto'>
        <img className='w-75 mx-auto d-block' src={logo} alt='Wohnbaugruppe' />
        <p className='text-center'>{config.title}</p>
        <Card className='shadow'>
          <div className={styles.element}>
            <h3>Login</h3>
          </div>
          <Card.Body>
            <Form onSubmit={this.onSubmit} className={`${styles["form-signin"]} pb-3`}>
              <Form.Group>
                <Form.Control
                  id='username'
                  type='text'
                  placeholder='Username'
                  name='username'
                  value={this.username}
                  onChange={this.handleUserChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  id='password'
                  placeholder='Passwort'
                  type='password'
                  name='password'
                  value={this.password}
                  onChange={this.handlePasswordChange}
                />
                <Link className={styles["forgot-password"]} to='/login/reset'>
                  Passwort vergessen?
                </Link>
                {error && <small className='text-danger'>Username oder Passwort falsch!</small>}
              </Form.Group>

              <Form.Group>
                <Button
                  type='submit'
                  variant='primary'
                  style={{ background: config.color }}
                  disabled={isLoading || this.isIE}
                  block
                >
                  Anmelden
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        <Modal show={this.isIE}>
          <Modal.Body>
            <h4>Achtung</h4>
            <Jumbotron>
              <p>Internet Explorer wird nicht mehr unterstüzt.</p>
              <p>Bitte wechseln Sie zu einem neueren Browser wie Google Chrome oder Microsoft Edge</p>
              Ihre Wohnbaugruppe
            </Jumbotron>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  authStore: PropTypes.any.isRequired,
};

export default withRouter(LoginPage);
