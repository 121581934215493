import React from "react";
import { inject, observer } from "mobx-react/index";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../components/Header/Header";

@inject("dataStore")
@observer
class HomePage extends React.Component {
  static propTypes = {};

  render() {
    return (
      <div>
        <Header />
        <Container>
          <Row className="py-3">
            <Col>
              <h1 className="text-center">Archive</h1>
            </Col>
          </Row>
          <Row>
            <Col>TO BE IMPLEMENTED</Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HomePage;
