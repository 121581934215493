/* eslint-disable no-debugger,no-unused-vars */
import React from "react";
import { PropTypes } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import Header from "../components/Header/Header";
import Toast from "../components/Toast/Toast";

@inject("dataStore")
@inject("authStore")
@observer
class ObjectZipDownloadPage extends React.Component {
  static propTypes = {
    dataStore: PropTypes.any.isRequired,
    match: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
  };

  @observable toastShown = false;

  render() {
    const {
      match: {
        params: { files, objId },
      },
      history,
      dataStore,
    } = this.props;

    const selectedFiles = files.split(",");
    if (files) {
      dataStore.downloadAllFilesById(selectedFiles, objId).then((res) => {
        if (!res) {
          this.toastShown = true;
        }
      });
    }

    return (
      <div>
        <Header />
        Download File...
        <Toast
          show={this.toastShown}
          onHide={() => (this.toastShown = false)}
          status={{
            type: "error",
            title: "Download Fehlgeschlagen!",
            body: "Fehlende Rechte - Bitte kontaktieren Sie den Absender",
          }}
        />
      </div>
    );
  }
}

export default ObjectZipDownloadPage;
