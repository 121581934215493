/* eslint-disable no-debugger */
import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { observable } from "mobx";
import Modal from "./Modal";
import style from "./Modal.module.scss";

@inject("dataStore")
@inject("authStore")
@observer
class ManageCompanyDialog extends React.Component {
  static propTypes = {
    dataStore: PropTypes.any.isRequired,
    authStore: PropTypes.any.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    companies: PropTypes.any,
    objId: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    files: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    companiesWithAccess: PropTypes.object,
  };

  static defaultProps = {
    title: "Loading...",
    companiesWithAccess: null,
    companies: null,
  };

  @observable notificationText = "";

  @observable idArray = [];

  @observable newCompaniesWithAccess = [];

  hide = () => {
    const { onHide } = this.props;
    onHide();
  };

  saveAccessRights = () => {
    const { dataStore, objId, files, onSuccess } = this.props;
    const uniqueEntries = this.idArray.filter((item, pos, self) => self.indexOf(item) === pos);

    dataStore.saveCompaniesWithAccess(objId, files[0].id, uniqueEntries).then(() => {
      if (this.newCompaniesWithAccess.length > 0) {
        const obj = dataStore.getObject(objId);

        const URL = `${process.env.PUBLIC_URL}objects/${objId}/zip/${files[0].id}`;

        const emailText = `${files[0].name} \n Hier der Link zu den Plänen : ${URL} \n Per Rechteverwaltung hinzugefügt \n  ${this.notificationText} `;

        dataStore
          .sendEmailNotification(
            this.newCompaniesWithAccess,
            obj.man_id,
            obj.obj_nr,
            objId,
            emailText,
            "Neue Datei per Rechteverwaltung",
            [files[0].id],
            []
          )
          .then(() => {
            this.hide();
          });
      }
    });
    onSuccess();
  };

  selectItem = (e, obj) => {
    if (!this.idArray.includes(obj.firmen_id)) {
      this.idArray.push(obj.firmen_id);
    } else {
      const index = this.idArray.indexOf(obj.firmen_id);
      if (index !== -1) {
        this.idArray.splice(index, 1);
      }
    }

    if (e.target.checked) {
      if (!this.newCompaniesWithAccess.includes(obj.firmen_id)) {
        this.newCompaniesWithAccess.push(obj.firmen_id);
      } else {
        const index = this.newCompaniesWithAccess.indexOf(obj.firmen_id);
        if (index !== -1) {
          this.newCompaniesWithAccess.splice(index, 1);
        }
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { companiesWithAccess } = this.props;
    if (companiesWithAccess !== prevProps.companiesWithAccess)
      companiesWithAccess.map((elem) => this.idArray.push(elem));
  }

  checkPermission = (obj) => {
    const { companiesWithAccess } = this.props;

    if (companiesWithAccess) {
      return companiesWithAccess.includes(obj.firmen_id);
    }
  };

  render() {
    const { show, title, companies, authStore } = this.props;
    let companiesArray = [];
    if (companies)
      companiesArray = companies.filter((company) =>
        authStore ? authStore.companyDetails.firmen_bzg1 !== company.firmen_bzg1 : company
      );

    const body = (
      <div className='scroller'>
        <p>Zugriffsrechte definieren - Wer darf die Datei sehen? </p>
        <ListGroup className={style.listScroll}>
          {companiesArray &&
            companiesArray.map((obj, key) => (
              <div className={`${style.listview} list-group-item list-group-item-action`} key={obj.firmen_id}>
                <Form.Check
                  custom
                  type='checkbox'
                  id={`check-${key}`}
                  onClick={(e) => this.selectItem(e, obj)}
                  label={obj.firmen_bzg1}
                  className={style.background}
                  defaultChecked={this.checkPermission(obj)}
                  onChange={this.addPermission}
                />
              </div>
            ))}
        </ListGroup>
      </div>
    );
    const footer = (
      <Button variant='success' block onClick={this.saveAccessRights}>
        Speichern
      </Button>
    );

    return (
      <div>
        <Modal show={show} onHide={this.hide} title={title} body={body} footer={footer} />
      </div>
    );
  }
}

export default ManageCompanyDialog;
