/* eslint-disable jsx-a11y/interactive-supports-focus,react/destructuring-assignment */
import React, { Component } from "react";
import { inject, observer } from "mobx-react/index";
import { PropTypes } from "prop-types";
import BSNavbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { Container, Jumbotron, Modal, Nav, NavDropdown } from "react-bootstrap";
import NavLink from "react-router-dom/NavLink";
import withRouter from "react-router-dom/withRouter";
import Sticky from "react-stickynode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import { observable } from "mobx";
import styles from "../../app.module.css";
import FavoriteModal from "../Modal/FavoriteModal";
import theme from "../../theme";

library.add(faUser);

@inject("authStore")
@inject("dataStore")
@observer
class Navbar extends Component {
  static propTypes = {
    dataStore: PropTypes.any.isRequired,
    authStore: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  @observable files = [];

  @observable isIE = false;

  @observable showFavoriteDialog = false;

  constructor(props) {
    super(props);
    const {
      authStore,
      dataStore,
      history,
      match: {
        params: { objId },
      },
    } = props;

    const config = theme[process.env.REACT_APP_CLIENT];

    if (!authStore.companyDetails && authStore.firmId) {
      authStore.fetchCompanyDetails().catch(() => {
        authStore.accessToken = null;
        authStore.error = true;
        return <Redirect to='/login' />;
      });
    }
    if (objId) {
      const obj = dataStore.getObject(objId);
      if (obj) dataStore.getCompaniesPerObject(obj.man_id, obj.obj_nr);
    }
    if (authStore.accessToken && config.modules.planserver && history.location.pathname.includes("files")) {
      dataStore.getFileNotifications().then((res) => {
        this.files = res;
      });
    }
  }

  resetPassword = () => {
    const { history } = this.props;
    history.push("/login/change");
  };

  logout = async () => {
    const { authStore, history } = this.props;
    await authStore.logout();
    history.push("/login");
  };

  acknowledgeNotification = (notificationId) => {
    const { dataStore } = this.props;
    dataStore.acknowledgeFileNotification(notificationId).then(() => {
      dataStore.getFileNotifications().then((re1s) => {
        this.files = re1s;
      });
    });
  };

  componentDidMount() {
    const isInternetExplorer = false || !!document.documentMode;

    this.isIE = isInternetExplorer;
  }

  render() {
    const {
      authStore,
      history,
      dataStore,
      match: {
        params: { objId },
      },
    } = this.props;

    const newFiles = [];
    // eslint-disable-next-line array-callback-return
    this.files.map((file) => {
      if (file.acknowledgeTime === null) {
        newFiles.push(file);
      }
    });

    const config = theme[process.env.REACT_APP_CLIENT];

    return (
      <Sticky innerZ={1020}>
        <BSNavbar bg='dark' variant='dark' className={styles["nav-header"]}>
          <Container>
            <Nav className='navbar-nav'>
              {(authStore.companyBranche === "Noshare" ||
                authStore.companyBranche === "Bauherr" ||
                (authStore.companyBranchen && authStore.companyBranchen.includes("FileSharing-Aufträge")) ||
                authStore.companyBranche === "FileSharing-Aufträge") && (
                <div style={{ display: "inherit" }}>
                  <Nav.Item>
                    <NavLink id='orders' to='/orders' className='nav-link' activeClassName='active'>
                      Meine Aufträge
                    </NavLink>
                    <span className='sr-only'>(current)</span>
                  </Nav.Item>
                  {config.modules.planserver && (
                    <Nav.Item>
                      <NavLink id='objects' to='/objects' className='nav-link' activeClassName='active'>
                        Objekte
                      </NavLink>
                    </Nav.Item>
                  )}
                </div>
              )}
              {config.modules.filesharing &&
                authStore.companyBranchen &&
                authStore.companyBranchen.includes("FileSharing-Aufträge") && (
                  <Nav.Item>
                    <NavLink to='/fileupload' exact className='nav-link' activeClassName='active'>
                      Fileupload
                    </NavLink>
                  </Nav.Item>
                )}
              {config.modules.planserver && history.location.pathname.includes("files") && (
                <Nav.Item>
                  <NavLink to={`/objects/${objId}/files/history`} exact className='nav-link' activeClassName='active'>
                    History
                  </NavLink>
                </Nav.Item>
              )}
            </Nav>

            <Nav className='navbar-nav navbar-right'>
              {newFiles && newFiles.length > 0 && history.location.pathname.includes("files") && (
                <span className={styles.badge} data-badge='1'>
                  {newFiles.length}
                </span>
              )}
              {history.location.pathname.includes("files") && (
                <NavDropdown
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <FontAwesomeIcon icon='bell' color='white' />
                  }
                  alignRight
                  className='usermenu'
                >
                  <NavDropdown.Header>Benachrichtigungen</NavDropdown.Header>
                  {newFiles && newFiles.length > 0 ? (
                    newFiles.map((file, index) => (
                      <NavDropdown.Item key={index} as='div'>
                        {`Neue Email-Benachrichtigung von ${file.senderFirmInfo.firmen_bzg1} `}
                        {!file.acknowledgeTime && (
                          <Button
                            className='p-1 ml-1'
                            onClick={() => this.acknowledgeNotification(file.notificationId)}
                          >
                            Ok
                          </Button>
                        )}
                      </NavDropdown.Item>
                    ))
                  ) : (
                    <NavDropdown.Item as='div'>Derzeit keine Benachrichtigungen</NavDropdown.Item>
                  )}
                  <NavDropdown.Divider />
                </NavDropdown>
              )}
              <NavDropdown title={<FontAwesomeIcon icon='user' color='white' />} alignRight className='usermenu'>
                <NavDropdown.Header>Eingeloggt als</NavDropdown.Header>
                <NavDropdown.Item as='div'>
                  {authStore.companyDetails && (
                    <div>
                      {authStore.companyDetails.firmen_bzg1}
                      <br />
                      {authStore.companyDetails.adr && (
                        <div>
                          {authStore.companyDetails.adr.street}
                          <br />
                          {`${authStore.companyDetails.adr.plz}, ${authStore.companyDetails.adr.city}`}
                        </div>
                      )}
                    </div>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {history.location.pathname.includes("files") && (
                  <NavDropdown.Item onClick={() => (this.showFavoriteDialog = true)}>
                    <div>
                      {`Favoriten `}
                      <FontAwesomeIcon className={styles.icon} icon='user-friends' color='white' title='Favoriten' />
                    </div>
                  </NavDropdown.Item>
                )}
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.resetPassword}>Passwort ändern</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.logout} id='logout'>
                  Ausloggen
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Container>
          {dataStore.companies && (
            <FavoriteModal
              show={this.showFavoriteDialog}
              title='Favoritenliste'
              objId={objId}
              companies={dataStore.companies.toJS()}
              onHide={() => (this.showFavoriteDialog = false)}
              onSuccess={() => (this.showFavoriteDialog = false)}
            />
          )}
        </BSNavbar>
        <Modal show={this.isIE}>
          <Modal.Body>
            <h4>Achtung</h4>
            <Jumbotron>
              <p>Internet Explorer wird nicht mehr unterstüzt.</p>
              <p>Bitte wechseln Sie zu einem neueren Browser wie Google Chrome oder Microsoft Edge</p>
              Ihre Wohnbaugruppe
            </Jumbotron>
          </Modal.Body>
        </Modal>
      </Sticky>
    );
  }
}

export default withRouter(Navbar);
