/* eslint-disable no-debugger,no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { observer, inject } from "mobx-react";
import { observable, computed } from "mobx";
import Modal from "./Modal";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { toString, toISODate } from "../../helpers/DateHelpers";

registerLocale("de", de);

@inject("dataStore")
@observer
class EditStatusModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    orderDetails: PropTypes.object.isRequired,
    dataStore: PropTypes.any.isRequired,
    accepted: PropTypes.any.isRequired,
  };

  @observable modifiedStatus = {};

  @observable loading = false;

  @computed get canSave() {
    const { orderDetails } = this.props;

    return (
      (this.modifiedStatus.statuscode !== orderDetails.status.statuscode &&
        (this.modifiedStatus.statuscode !== 2 || this.modifiedStatus.ablehngrund)) ||
      this.hasPlannedBeginChanged ||
      this.hasPlannedCompleteChanged ||
      this.hasRefusalReasonChanged
    );
  }

  hasPlannedBeginChanged = () => {
    const { orderDetails } = this.props;
    return (
      this.modifiedStatus.beginn_plan &&
      this.modifiedStatus.beginn_plan.getTime() !== new Date(orderDetails.status.beginn_plan).getTime()
    );
  };

  hasPlannedCompleteChanged = () => {
    const { orderDetails } = this.props;
    return (
      this.modifiedStatus.fertig_plan &&
      this.modifiedStatus.fertig_plan.getTime() !== new Date(orderDetails.status.fertig_plan).getTime()
    );
  };

  hasRefusalReasonChanged = () => {
    const { orderDetails } = this.props;
    return this.modifiedStatus.ablehngrund && this.modifiedStatus.ablehngrund !== orderDetails.status.ablehngrund;
  };

  componentWillMount() {
    this.resetStatus();
  }

  componentWillReceiveProps(newProps) {
    this.resetStatus();
    if (this.modifiedStatus.statuscode === 0) {
      this.modifiedStatus.statuscode = newProps.accepted ? 1 : 2;
    }
  }

  resetStatus = () => {
    const { orderDetails } = this.props;
    this.modifiedStatus = observable.object({
      statuscode: orderDetails.status.statuscode,
      statustitle: orderDetails.status.statustitle,
      ablehngrund: orderDetails.status.ablehngrund,
      beginn_plan_grund: orderDetails.status.beginn_plan_grund,
      fertig_plan_grund: orderDetails.status.fertig_plan_grund,
      annahmedatum: orderDetails.status.annahmedatum ? new Date(orderDetails.status.annahmedatum) : new Date(),
      beginn_plan: orderDetails.status.beginn_plan ? new Date(orderDetails.status.beginn_plan) : null,
      beginn_real: orderDetails.status.beginn_real ? new Date(orderDetails.status.beginn_real) : null,
      fertig_plan: orderDetails.status.fertig_plan ? new Date(orderDetails.status.fertig_plan) : null,
    });
    this.loading = false;
  };

  saveChanges = () => {
    const { orderDetails, dataStore, onHide } = this.props;
    const {
      statuscode,
      annahmedatum,
      beginn_plan,
      beginn_real,
      beginn_plan_grund,
      fertig_plan_grund,
      fertig_plan,
      ablehngrund,
    } = this.modifiedStatus;

    const statusUpdate = {};

    if (statuscode === 1 || statuscode === 2) {
      statusUpdate.statuscode = statuscode;
    }
    if ((statuscode === 1 || statuscode === 2) && annahmedatum) {
      statusUpdate.annahmedatum = annahmedatum.toISOString();
    }
    if (statuscode === 1 && beginn_plan) {
      statusUpdate.beginn_plan = beginn_plan.toISOString();
    }
    if (statuscode === 1 && beginn_plan_grund) {
      statusUpdate.beginn_plan_grund = beginn_plan_grund;
    }
    if ((statuscode === 1 || statuscode === 3) && fertig_plan_grund) {
      statusUpdate.fertig_plan_grund = fertig_plan_grund;
    }
    if ((statuscode === 1 || statuscode === 3) && fertig_plan) {
      statusUpdate.fertig_plan = fertig_plan.toISOString();
    }
    if (statuscode === 2 && ablehngrund) {
      statusUpdate.ablehngrund = ablehngrund;
    }
    this.loading = true;
    dataStore.setOrderStatus(orderDetails.auftr_id, statusUpdate).then((res) => {
      if (res || res === "") {
        this.hide();
      } else {
        onHide(true);
      }
    });
  };

  hide = () => {
    const { onHide } = this.props;
    this.resetStatus();
    onHide();
  };

  render() {
    const { show, orderDetails } = this.props;

    const annahmedatumGroup = (
      <Form.Group>
        <Form.Label>Annahmedatum</Form.Label>
        <p>{toString(this.modifiedStatus.annahmedatum)}</p>
      </Form.Group>
    );

    const ablehndatumGroup = (
      <Form.Group>
        <Form.Label>Ablehndatum</Form.Label>
        <p>{toString(this.modifiedStatus.annahmedatum)}</p>
      </Form.Group>
    );

    const beginn_planGroup = (
      <Form.Group>
        <Form.Label>Geplanter Beginn</Form.Label>
        <div>
          <DatePicker
            weekLabel='KW'
            showWeekNumbers
            locale='de'
            selected={this.modifiedStatus.beginn_plan}
            onChange={(date) => (this.modifiedStatus.beginn_plan = date)}
            {...(this.modifiedStatus.fertig_plan && {
              maxDate: this.modifiedStatus.fertig_plan,
            })}
            showTimeSelect
            dateFormat='d. MMMM yyyy HH:mm'
            timeFormat='HH:mm'
            className='form-control text-center'
            disabled={this.modifiedStatus.beginn_real !== null}
          />
        </div>
      </Form.Group>
    );

    const beginn_realGroup = (
      <Form.Group>
        <Form.Label>Tatsächlicher Beginn</Form.Label>
        <p>{toString(this.modifiedStatus.beginn_real)}</p>
      </Form.Group>
    );

    const fertig_planGroup = (
      <Form.Group>
        <Form.Label>Geplante Fertigstellung</Form.Label>
        <div>
          <DatePicker
            weekLabel='KW'
            showWeekNumbers
            locale='de'
            selected={this.modifiedStatus.fertig_plan}
            onChange={(date) => (this.modifiedStatus.fertig_plan = date)}
            {...(this.modifiedStatus.beginn_plan && {
              minDate: this.modifiedStatus.beginn_plan,
            })}
            showTimeSelect
            dateFormat='d. MMMM yyyy HH:mm'
            timeFormat='HH:mm'
            className='form-control text-center'
          />
        </div>
      </Form.Group>
    );

    const ablehngrundGroup = (
      <Form.Group>
        <Form.Label>Ablehngrund</Form.Label>
        <Form.Control
          as='textarea'
          maxLength='100'
          aria-label='With textarea'
          value={this.modifiedStatus.ablehngrund}
          onChange={(e) => (this.modifiedStatus.ablehngrund = e.target.value)}
        />
      </Form.Group>
    );

    const begin_plan_grundGroup = (
      <InputGroup className='form-group'>
        <InputGroup.Prepend>
          <InputGroup.Text>Änderungsgrund</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          as='textarea'
          aria-label='With textarea'
          maxLength='100'
          value={this.modifiedStatus.beginn_plan_grund}
          onChange={(e) => (this.modifiedStatus.beginn_plan_grund = e.target.value)}
        />
      </InputGroup>
    );

    const fertig_plan_grundGroup = (
      <InputGroup className='form-group'>
        <InputGroup.Prepend>
          <InputGroup.Text>Änderungsgrund</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          as='textarea'
          aria-label='With textarea'
          maxLength='100'
          value={this.modifiedStatus.fertig_plan_grund}
          onChange={(e) => (this.modifiedStatus.fertig_plan_grund = e.target.value)}
        />
      </InputGroup>
    );

    const acceptedBody = (
      <div>
        {annahmedatumGroup}
        {beginn_planGroup}
        {orderDetails.status.beginn_plan && this.hasPlannedBeginChanged() && begin_plan_grundGroup}
        {fertig_planGroup}
        {orderDetails.status.fertig_plan && this.hasPlannedCompleteChanged() && fertig_plan_grundGroup}
      </div>
    );

    const refusedBody = (
      <div>
        {ablehndatumGroup}
        {ablehngrundGroup}
      </div>
    );

    const inProgressBody = (
      <div>
        {annahmedatumGroup}
        {beginn_realGroup}
        {fertig_planGroup}
        {orderDetails.status.fertig_plan && this.hasPlannedCompleteChanged() && fertig_plan_grundGroup}
      </div>
    );

    const { statuscode } = this.modifiedStatus;
    const body = this.loading ? (
      <LoadingSpinner />
    ) : statuscode === 1 ? (
      acceptedBody
    ) : statuscode === 2 ? (
      refusedBody
    ) : statuscode === 3 ? (
      inProgressBody
    ) : (
      <div />
    );

    const emptyFooter = null;
    const saveButtonFooter = (
      <Button variant='primary' block onClick={this.saveChanges} disabled={!this.canSave}>
        Speichern
      </Button>
    );

    const footer = this.loading ? emptyFooter : saveButtonFooter;

    return (
      <div>
        <Modal show={show} onHide={this.hide} title='Status Ändern' body={body} footer={footer} />
      </div>
    );
  }
}

export default EditStatusModal;
