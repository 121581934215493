/* eslint-disable no-debugger */
import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { observable } from "mobx";
import DatePicker from "react-datepicker";
import Modal from "./Modal";

@inject("fileStore")
@observer
class UploadFileModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    fileStore: PropTypes.any.isRequired,
    files: PropTypes.any.isRequired,
  };

  static defaultProps = {
    title: "Loading...",
  };

  @observable expiryDate = null;

  constructor(props) {
    super(props);
    const dt = new Date();
    dt.setMonth(dt.getMonth() + 1);
    this.expiryDate = dt;
  }

  componentDidUpdate(prevProps) {
    const { files } = this.props;

    if (files && files[0] !== prevProps.files[0]) {
      this.expiryDate = new Date(files[0].expiry);
    }
  }

  hide = () => {
    const { onHide } = this.props;
    onHide();
  };

  updateFileSettings = () => {
    const { onHide, fileStore, files } = this.props;

    Promise.all(files.map((file) => fileStore.setFileProperties(file.fileid, this.expiryDate))).then((res) => {
      onHide();
    });
  };

  render() {
    const { show, title } = this.props;

    const currentDate = new Date();

    const body = (
      <div>
        <Form.Group>
          <Form.Label>Ablaufdatum</Form.Label>
          <div>
            <DatePicker
              onChange={(date) => (this.expiryDate = date)}
              weekLabel='KW'
              showWeekNumbers
              locale='de'
              {...(currentDate && {
                minDate: currentDate,
              })}
              defaultValue={new Date()}
              selected={this.expiryDate}
              dateFormat='d. MMMM yyyy'
              timeFormat='HH:mm'
              className='form-control text-center'
            />
          </div>
        </Form.Group>
        {/*
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="Passwort geschützt?"
            onChange={e => (this.passwordProtected = e.target.checked)}
            value={this.passwordProtected}
          />
        </Form.Group>

        { this.passwordProtected && (
        <Form.Group controlId="Email">
          <Form.Control
            type="password"
            placeholder="Passwort angeben"
            value={this.password}
            onChange={e => (this.password = e.target.value)}
          />
        </Form.Group>
        )}
        */}
      </div>
    );
    const footer = (
      <Button variant='success' block onClick={this.updateFileSettings}>
        Ok
      </Button>
    );
    return (
      <div>
        <Modal show={show} onHide={this.hide} title={title} body={body} footer={footer} />
      </div>
    );
  }
}

export default UploadFileModal;
