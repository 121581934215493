import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import DataCard from "./DataCard";
import { toString } from "../../helpers/DateHelpers";

@observer
class StatusDataCard extends React.Component {
  static propTypes = {
    orderDetails: PropTypes.object.isRequired,
    collapsable: PropTypes.bool,
  };

  static defaultProps = {
    collapsable: false,
  };

  render() {
    const { orderDetails, collapsable } = this.props;

    const { statuscode, annahmedatum, beginn_plan, beginn_real, fertig_plan, fertig_real, ablehngrund } =
      orderDetails.status;

    const acceptedStatusData = {
      Annahmedatum: annahmedatum ? toString(new Date(annahmedatum)) : null,
      "Geplanter Beginn": beginn_plan ? toString(new Date(beginn_plan)) : null,
      "Tatsächlicher Beginn": beginn_real ? toString(new Date(beginn_real)) : null,
      "Geplante Fertigstellung": fertig_plan ? toString(new Date(fertig_plan)) : null,
    };

    const refusedStatusData = {
      Ablehndatum: annahmedatum ? toString(new Date(annahmedatum)) : null,
      Ablehngrund: ablehngrund,
    };

    const inProgressStatusData = {
      Annahmedatum: annahmedatum ? toString(new Date(annahmedatum)) : null,
      "Tatsächlicher Beginn": beginn_real ? toString(new Date(beginn_real)) : null,
      "Geplante Fertigstellung": fertig_plan ? toString(new Date(fertig_plan)) : null,
      "Tatsächl. Fertigstellung": fertig_real ? toString(new Date(fertig_real)) : null,
    };

    const completedStatusData = {
      Annahmedatum: annahmedatum ? toString(new Date(annahmedatum)) : null,
      "Tatsächlicher Beginn": beginn_real ? toString(new Date(beginn_real)) : null,
      "Geplante Fertigstellung": fertig_plan ? toString(new Date(fertig_plan)) : null,
      "Tatsächl. Fertigstellung": fertig_real ? toString(new Date(fertig_real)) : null,
    };

    const data =
      statuscode === 1
        ? acceptedStatusData
        : statuscode === 2
        ? refusedStatusData
        : statuscode === 3
        ? inProgressStatusData
        : statuscode === 4
        ? completedStatusData
        : null;

    return <DataCard title='Status Daten' data={data} collapsable={collapsable} />;
  }
}

export default StatusDataCard;
