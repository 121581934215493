import React from "react";
import PropTypes from "prop-types";
import BSModal from "react-bootstrap/Modal";
import { observer } from "mobx-react";
import style from "./Modal.module.scss";

@observer
class Modal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
    title: PropTypes.string.isRequired,
    body: PropTypes.any.isRequired,
    footer: PropTypes.any,
    widthClass: PropTypes.any,
  };

  static defaultProps = {
    footer: null,
    onHide: null,
    widthClass: "",
  };

  render() {
    const { show, onHide, title, body, footer, widthClass } = this.props;

    return (
      <BSModal
        className='text-center'
        show={show}
        {...(onHide ? { onHide } : { backdrop: "static" })}
        centered
        dialogClassName={widthClass}
      >
        <BSModal.Header className={style.header} {...(onHide && { closeButton: true })}>
          <BSModal.Title style={{ wordBreak: "break-word" }}>{title}</BSModal.Title>
        </BSModal.Header>
        <BSModal.Body className={style.body}>{body}</BSModal.Body>
        {footer && <BSModal.Footer className={style.footer}>{footer}</BSModal.Footer>}
      </BSModal>
    );
  }
}

export default Modal;
