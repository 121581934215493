import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataTable } from "./DataTable/DataTable";
import { truncateFileName, getFontAwesomeIconFromMIME } from "../helpers/StringHelpers";
import styles from "../app.module.css";
import LoadingModal from "./Modal/LoadingModal";
import Toast from "./Toast/Toast";
import ConfirmationModal from "./Modal/ConfirmationModal";
import UploadFileModal from "./Modal/UploadFileModal";

@inject("fileStore")
@observer
class FileUploadTable extends Component {
  @observable action = null;

  @observable actionRow = null;

  @observable actionProgress = 0;

  @observable toastShown = false;

  @observable showUploadFileModal = false;

  @observable files = [];

  @observable toastStatus = {};

  actionsFormatter = (cell, row) => (
    <div>
      <FontAwesomeIcon
        className={styles.icon}
        onClick={() => this.copyLink(row)}
        style={{ marginRight: "10px" }}
        color='#1f2427'
        icon='copy'
        title='Link Kopieren'
      />
      <FontAwesomeIcon
        className={styles.icon}
        onClick={() => this.showSettings(row)}
        style={{ marginRight: "10px" }}
        color='#1f2427'
        icon='edit'
        title='Editieren'
      />
      <FontAwesomeIcon
        className={styles.icon}
        onClick={() => this.sendNotifications(row)}
        style={{ marginRight: "10px" }}
        color='#1f2427'
        icon='envelope'
        title='Senden'
      />
      <FontAwesomeIcon
        className={styles.icon}
        onClick={() => this.downloadFile(row)}
        style={{ marginRight: "10px" }}
        color='#1f2427'
        icon='file-download'
        title='Downloaden'
      />
      <FontAwesomeIcon
        className={styles.icon}
        onClick={() => this.deleteFile(row)}
        color='#1f2427'
        icon='trash-alt'
        title='Löschen'
      />
      <FontAwesomeIcon
        className={styles.exclamation}
        color='red'
        icon='exclamation'
        title='Noch nicht versendet'
        style={{
          display: row && row.sharedWithEmails.length > 0 ? "none" : "inline-block",
        }}
      />
    </div>
  );

  copyLink = (row) => {
    navigator.clipboard.writeText(row.downloadLink);
  };

  showSettings = (row) => {
    this.files = row;
    this.showUploadFileModal = true;
  };

  sendNotifications = (row) => {
    const { openFilesDialog } = this.props;
    openFilesDialog(row);
  };

  downloadFile = (row) => {
    const { fileStore } = this.props;
    this.action = "download";
    this.actionRow = row;
    this.actionProgress = 0;
    fileStore
      .downloadUploadFile(row.fileid, (progress) => (this.actionProgress = progress))
      .then(() => (this.action = null))
      .catch((error) => {
        fileStore.loadUploadFiles().then(() => {
          this.action = null;
          this.toastShown = true;
          this.toastStatus = {
            type: "error",
            title: "Download Fehlgeschlagen!",
            body: error.message,
          };
        });
      });
  };

  dateFormatter = (row) => new Date(row).toLocaleDateString();

  deleteFile = (row) => {
    this.action = "delete";
    this.actionRow = row;
  };

  confirmDeleteFile = () => {
    const { fileStore } = this.props;

    fileStore
      .deleteUploadFile(this.actionRow.fileid)
      .then(() => {
        this.action = null;
      })
      .catch((error) => {
        fileStore.loadUploadFiles().then(() => {
          this.action = null;
          this.toastShown = true;
          this.toastStatus = {
            type: "error",
            title: "Löschen Fehlgeschlagen!",
            body: error.message,
          };
        });
      });
  };

  cancelDeleteFile = () => {
    this.action = null;
  };

  hideFirmModal = () => {
    const { fileStore } = this.props;
    this.showUploadFileModal = false;
    fileStore.loadUploadFiles().then(() => {
      this.showUploadFileModal = false;
    });
  };

  fileNameFormatter = (cell) => truncateFileName(cell, 40);

  convertMimeType = (cell) => {
    let mime = getFontAwesomeIconFromMIME(cell);
    if (!mime) {
      mime = "file-alt";
    }
    return <FontAwesomeIcon icon={mime} size='2x' className={mime} title={cell} />;
  };

  render() {
    const { data } = this.props;

    const defaultSorted = [
      {
        dataField: "updatedAt",
        order: "desc",
      },
    ];

    const columns = [
      {
        dataField: "filename",
        text: "Dateiname",
        headerAlign: "center",
        sort: true,
        align: "center",
        formatter: this.fileNameFormatter,
        headerClasses: styles.filename,
      },
      {
        dataField: "mime",
        text: "Typ",
        headerAlign: "center",
        sort: true,
        align: "center",
        formatter: this.convertMimeType,
      },
      {
        dataField: "updatedAt",
        text: "Uploaddatum",
        formatter: this.dateFormatter,
        headerAlign: "center",
        sort: true,
        align: "center",
      },
      /* {
        dataField: 'expiry',
        text: 'Expity',
        formatter: this.dateFormatter,
        headerAlign: 'center',
        sort: true,
        align: 'center',
      }, */
      {
        dataField: "actions",
        text: "Aktionen",
        formatter: this.actionsFormatter,
        headerAlign: "center",
        align: "center",
      },
    ];

    return (
      <div>
        {this.action && (
          <div>
            <LoadingModal
              show={this.action === "download"}
              title={`Download '${this.actionRow.filename}'...`}
              progress={this.actionProgress}
            />
            <ConfirmationModal
              show={this.action === "delete"}
              title={`Datei '${this.actionRow.filename}' löschen?`}
              onYes={this.confirmDeleteFile}
              onNo={this.cancelDeleteFile}
              onHide={this.cancelDeleteFile}
              yesText='Ja'
              noText='Nein'
            />
          </div>
        )}
        <UploadFileModal
          show={this.showUploadFileModal}
          title='File Transfer'
          onHide={() => this.hideFirmModal({ sendData: false })}
          onSuccess={() => this.hideFirmModal({ sendData: true })}
          files={[this.files]}
        />
        <DataTable data={data} columns={columns} keyField='fileid' defaultSorted={defaultSorted} />
        <Toast show={this.toastShown} onHide={() => (this.toastShown = false)} status={this.toastStatus} />
      </div>
    );
  }
}
FileUploadTable.propTypes = {
  data: PropTypes.array.isRequired,
  fileStore: PropTypes.any.isRequired,
  openFilesDialog: PropTypes.func.isRequired,
};
export default FileUploadTable;
