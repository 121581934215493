/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LinkList extends React.Component {
  static propTypes = {
    files: PropTypes.array,
    iconSize: PropTypes.string,
    fontSize: PropTypes.string,
    icon: PropTypes.string,
    updateOrder: PropTypes.func.isRequired,
  };

  static defaultProps = {
    files: null,
    iconSize: "1x",
    fontSize: "12x",
    icon: "",
  };

  openInNewTab = (url) => {
    const { updateOrder } = this.props;

    const myWindow = window.open(url, "MsgWindow", "toolbar=no,menubar=no,width=1200,height=900");

    const timer = setInterval(() => {
      if (myWindow.closed) {
        clearInterval(timer);
        updateOrder();
      }
    }, 1000);
  };

  render() {
    const { files, iconSize, fontSize, icon } = this.props;
    if (!files || files.length === 0) {
      return null;
    }
    return (
      <Row className='justify-content-center'>
        {files.map((v, i) => (
          <Col style={{ cursor: "pointer" }} xs='auto' key={i} className='text-center p-1'>
            <OverlayTrigger placement='top' overlay={<Tooltip>{v.name}</Tooltip>}>
              <div>
                <FontAwesomeIcon onClick={() => this.openInNewTab(v.link)} icon={icon} size={iconSize} />
                <div
                  role='presentation'
                  onClick={() => this.openInNewTab(v.link)}
                  className='text-truncate text-muted'
                  style={{ fontSize, maxInlineSize: icon ? "150px" : "50px" }}
                >
                  {v.name}
                </div>
              </div>
            </OverlayTrigger>
          </Col>
        ))}
      </Row>
    );
  }
}

export default LinkList;
