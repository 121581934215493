/* eslint-disable react/no-danger,no-debugger */
import React from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toString } from "../../helpers/DateHelpers";
import FileList from "../FileList/FileList";

@inject("dataStore")
@observer
class OrderComments extends React.Component {
  static propTypes = {
    dataStore: PropTypes.object.isRequired,
  };

  render() {
    const { dataStore } = this.props;

    if (!dataStore.orderDetails.kommentare) {
      return null;
    }

    return (
      <Container>
        {dataStore.orderDetails.kommentare.map((value, i) => (
          <Container key={i} className="border rounded shadow-sm mb-3">
            <Row className="border-bottom py-1 align-items-center">
              <Col>
                <strong>{value.verfasser}</strong>
              </Col>
              <Col xs="auto" className="text-center text-muted">
                {toString(new Date(value.datum))}
              </Col>
            </Row>
            <Row className="py-2 align-items-center border-bottom">
              <Col style={{ overflowWrap: "break-word" }}>{value.text}</Col>
              <Col xs={!value.dokumente || value.dokumente.length <= 3 ? "auto" : 6}>
                {value.dokumente && value.dokumente.length > 0 && (
                  <FileList
                    files={value.dokumente.map((v) => ({
                      name: v.name,
                      link: process.env.PUBLIC_URL + v.linkToArchive,
                    }))}
                    iconSize="2x"
                  />
                )}
              </Col>
            </Row>
          </Container>
        ))}
      </Container>
    );
  }
}

export default OrderComments;
