import React from 'react';
import { inject, observer } from 'mobx-react/index';
import Container from 'react-bootstrap/Container';
import Header from '../components/Header/Header';

@inject('dataStore')
@observer
class NotFoundPage extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <Container>
          <h1 className="text-center pt-5 pb-3">Diese Seite existiert nicht!</h1>
        </Container>
      </div>
    );
  }
}

export default NotFoundPage;
