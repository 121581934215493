import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import DataCard from "./DataCard";

@observer
class KeyDataCard extends React.Component {
  static propTypes = {
    orderDetails: PropTypes.object.isRequired,
    collapsable: PropTypes.bool,
  };

  static defaultProps = {
    collapsable: false,
  };

  render() {
    const { orderDetails, collapsable } = this.props;

    const data = {
      "Objekt-Nummer": orderDetails.obj_nr,
      Objekt: orderDetails.adr,
      Mitarbeiter: orderDetails.ansprechpartner,
      Tätigkeit: orderDetails.kurzbzg,
    };

    return <DataCard title='Eckdaten' data={data} collapsable={collapsable} />;
  }
}

export default KeyDataCard;
