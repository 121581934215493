import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { observer } from 'mobx-react';
import Modal from './Modal/Modal';

@observer
class FaultDetailsModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    // faultDetails: PropTypes.object.isRequired,
  };

  render() {
    const { show, onHide } = this.props;

    const body = (
      <div>
        <Form.Group>
          <Form.Label>Mangel</Form.Label>
          <Form.Text>65 Top 17: Heizkostenverteiler Bad und Küche kaputt</Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Beschreibung</Form.Label>
          <Form.Text>
            eaqui sunt. Lestiis ea a soloreium con rero isci cus et venduci
            aturibus eum sus, voloratur aut que optas inus inusdanda ventio. Ut
            que net quamet ut maionectat ut prehentiate sequod
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Behebungsfrist</Form.Label>
          <Form.Text>19.12.2019</Form.Text>
        </Form.Group>
      </div>
    );

    const footer = (
      <Button variant="success" block>
        Beheben
      </Button>
    );

    return (
      <Modal
        show={show}
        onHide={onHide}
        title="Mangeldetails"
        body={body}
        footer={footer}
      />
    );
  }
}

export default FaultDetailsModal;
