import React from "react";
import { observer, inject } from "mobx-react";
import { PropTypes } from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toJS } from "mobx";
import Header from "../components/Header/Header";
import ObjectsTable from "../components/ObjectsTable";
import LoadingSpinnerContainer from "../components/LoadingSpinner/LoadingSpinnerContainer";

@inject("dataStore", "authStore")
@observer
class ObjectsPage extends React.Component {
  constructor(props) {
    super(props);
    const { dataStore } = this.props;
    dataStore.loadObjects();
  }

  render() {
    const { dataStore } = this.props;
    return (
      <div>
        <Header />
        <LoadingSpinnerContainer loading={!dataStore.objects}>
          {() => (
            <Container>
              <Row className='py-3'>
                <Col>
                  <h1 className='text-center'>Objekte</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ObjectsTable data={toJS(dataStore.objects)} />
                </Col>
              </Row>
            </Container>
          )}
        </LoadingSpinnerContainer>
      </div>
    );
  }
}

ObjectsPage.propTypes = {
  dataStore: PropTypes.any.isRequired,
};

export default ObjectsPage;
