/* eslint-disable react/no-unused-class-component-methods */
import SignatureCanvas from "react-signature-canvas";
import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { observable } from "mobx";
import { observer } from "mobx-react/index";
import styles from "./SignaturePad.module.scss";

@observer
class SignaturePad extends React.Component {
  @observable sigPad = null;

  @observable signatureImage = null;

  componentDidUpdate(prevProps) {
    const { signatureImage } = this.props;
    if (prevProps.signatureImage !== signatureImage) {
      this.sigPad.fromDataURL(signatureImage, "image/png");
    }
  }

  clear = () => {
    this.signatureImage = null;
    this.sigPad.clear();
  };

  trim = () => {
    const { onSave } = this.props;

    const image = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
    this.signatureImage = image;

    onSave(image, this.firstname);
  };

  render() {
    const { signatureData, signatureImage } = this.props;
    if (this.sigPad) this.sigPad.fromDataURL(signatureImage, "image/png");
    return (
      <>
        <div className={styles.sigParent}>
          {!signatureData.linkToArchive ? (
            <SignatureCanvas
              penColor='green'
              canvasProps={{ width: 400, height: 200, className: styles.SignatureCanvas }}
              ref={(ref) => {
                this.sigPad = ref;
              }}
            />
          ) : (
            <img
              width='400'
              height='200'
              src={process.env.PUBLIC_URL + signatureData.linkToArchive}
              alt={signatureData.name}
            />
          )}
        </div>
        <br />
        {!signatureData.linkToArchive && (
          <>
            <Button style={{ marginRight: 10 }} onClick={() => this.trim()} className='btn btn-primary'>
              Speichern
            </Button>
            <Button onClick={() => this.clear()} className='btn btn-secondary'>
              Löschen
            </Button>
          </>
        )}
      </>
    );
  }
}

SignaturePad.propTypes = {
  onSave: PropTypes.func,
  signatureData: PropTypes.object,
  signatureImage: PropTypes.string,
};

SignaturePad.defaultProps = {
  onSave: null,
  signatureData: {},
  signatureImage: "",
};

export default SignaturePad;
